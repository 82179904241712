<template>
  <div>
    <div v-if="invoiceList.length >0">
      <a-card
        v-for="(item,idx) in invoiceList"
        :key="idx"
        :body-style="{ padding: '0px' }"
        :bordered="false"
        class="card"
        style="margin-bottom: 20px"
      >
        <div
          style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 5px"
        >
          <h3 style="color: #1890FF;margin: 0">产品编号：{{ item[0].invoiceNo }}</h3>
          <a-button key="submit" type="primary" @click="AddBtn(item[0].contractID)"
          >新增发票</a-button>
        </div>
        <InvoiceItem :invoiceItem="item"></InvoiceItem>
      </a-card>
    </div>
    <div
      v-else
      style="display: flex;justify-content: flex-end;align-items: center;margin-bottom: 5px"
    >
      <a-button key="submit" type="primary" @click="reAddBtn"
      >新增发票</a-button
      >
    </div>
    <a-modal
      title="新增发票"
      :visible="visible"
      :confirm-loading="false"
      width="60%"
      @cancel="handleCancel"
    >
      <a-form-model
        ref="ruleForm"
        :model="invoiceAdd"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="关联合同" prop="contractID">
              <a-select v-model="invoiceAdd.contractID"  placeholder="请选择关联合同">
                <a-select-option v-for="(item,idx) in contractList" :key="idx" :value="item">
                  {{item}}
                </a-select-option>
                <a-select-option :value="0">
                  暂无数据
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="关联产品" prop="projectID">
              <a-select v-model="invoiceAdd.projectID"  placeholder="请选择关联产品">
                <a-select-option v-for="(item,idx) in projectList" :key="idx">
                  {{item}}
                </a-select-option>
                <a-select-option :value="0">
                  暂无数据
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item ref="invoiceMoney" label="发票金额" prop="invoiceMoney">
              <a-input
                v-model.number="invoiceAdd.invoiceMoney"
                @blur="
          () => {
            $refs.invoiceMoney.onFieldBlur();
          }
        "
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              ref="attachment"
              label="上传发票附件"
              prop="attachment"
              :labelCol="{span: 4}"
              :wrapperCol="{span: 17}"
            >
              <SingleUploadFile
                :customerID="customerID"
                fileUploadPath="file-upload/invoice"
                fileRemovedPath="file-upload/invoice"
                allFileCancelPath="file-upload/attachment/cancel"
                ref="file-upload/invoice/cancel"
              ></SingleUploadFile>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <template slot="footer">
        <div>
          <a-button key="back" @click="handleCancel">
            取消
          </a-button>
          <a-button key="submit" type="primary" @click="handleOk">
            提交
          </a-button>
        </div>
      </template>
    </a-modal>
    <InvoiceTabPaneAdd ref="invoiceAdd" :customerID="customerID"></InvoiceTabPaneAdd>
  </div>
</template>

<script>
import { get } from "@/api/customer/contract"
import {get as getProjectList} from "@/api/customer/project"
import SingleUploadFile from "@/components/SingleUploadFile/index.vue"
import {GetInvoiceListByCustomerId} from "@/api/customer/invoice"
import InvoiceItem from "@/components/customerInfo/invoice-tab-pane-Item.vue"
import InvoiceTabPaneAdd from "@/components/customerInfo/invoice-tab-pane-add.vue"
import {arrGroup} from "@/utils/utils"
import {AddInvoiceByContractId} from "@/api/customer/invoice"
export default {
  name:"Invoice",
  props: {
    customerID: {
      type: Number
    }
  },
  data(){
    return {
      visible:false,
      contractList:[],
      invoiceAdd:{},
      invoiceList:[],
      contractObj:[],
      projectList:[],
      labelCol: { span: 8},
      wrapperCol: { span: 10 },
      rules: {
        projectID: [
          { required: true, message: '请选择关联产品', trigger: 'blur' },
        ],
        contractID: [
          { required: true, message: '请选择合同ID', trigger: 'blur' },
        ],
        invoiceMoney: [
          { required: true, message: '请输入发票金额', trigger: 'blur' },
        ]
      }
    }
  },
  created() {
  },
  components:{
    InvoiceItem,
    InvoiceTabPaneAdd,
    SingleUploadFile
  },
  methods:{
    AddBtn(contractId){
      this.$refs.invoiceAdd.toShowFlag = true
      this.$refs.invoiceAdd.contractId = contractId
      this.$refs.invoiceAdd.getInvoiceList(contractId)
    },
    async getInvoiceList(){
      const invoiceObj = await GetInvoiceListByCustomerId({ customerID: this.customerID })
      this.invoiceList = arrGroup(invoiceObj.result,item=>item.contractID)
    },
    async reAddBtn(){
      this.visible = true
      this.invoiceAdd.customerID = this.customerID
      const contractObj =  get({customerID: this.customerID })
      const projectObj = getProjectList({customerID: this.customerID })
      this.contractList = contractObj.result
      this.projectList = projectObj.result
    },
    handleCancel(){
      this.$refs.ruleForm.clearValidate()
      this.visible = false
    },
    handleOk(){
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.invoiceAdd.filePath = this.$refs.singleUploadFile.fileList[0].filePathUrl
          this.invoiceAdd.fileName = this.$refs.singleUploadFile.fileList[0].fileName
          const addInvoiceObj = await AddInvoiceByContractId(this.invoiceAdd)
          if(addInvoiceObj.code ===12){
            this.visible = false
            this.getInvoiceList()
          }
        } else {
          return false;
        }
      });
    }
  }
}
</script>
<style scoped>
</style>