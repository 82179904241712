<template>
  <div class="app-container">
    <el-collapse :value="showCollapse" class="my-collapse">
      <el-collapse-item
        :disabled="checkOperateDisable('获取客户数据分析')"
        :name="1"
        title="获取客户数据分析"
      >
        <div class="header-chart">
          <a-card class="card11">
            <div slot="title" style="display: flex; justify-content: space-between; line-height: 40px">
                <span style="display: inline-block">
                  关键词占比
                </span>
              <span slot="extra" style="font-size: 14px; color: rgba(0, 0, 0, 0.65)">截止{{ parseTime(new Date()) }}</span>
            </div>
            <div class="_box">
              <div class="_t"></div>
              <div ref="chart1" style="width:100%;height:760px;"></div>
            </div>
          </a-card>
          <a-card
            :body-style="{ padding: '0px' }"
            :bordered="false"
            class="card22"
          >
            <div slot="title">
                <span style="display: inline-block">
                  关键字排序
                </span>
              <el-radio-group
                v-model="trendType"
                style="margin-left: 20px"
                @change="changeTrendType"
              >
                <el-radio-button :label="ANALYSIS_TIME_UNIT.REDUCE"
                >降序
                </el-radio-button>
                <el-radio-button :label="ANALYSIS_TIME_UNIT.ADD"
                >升序
                </el-radio-button>
              </el-radio-group>
            </div>
            <span slot="extra">截止{{ parseTime(new Date()) }}</span>
            <div>
              <a-table
                :columns="tableColumns"
                :data-source="tableKeyWordList"
                :scroll="{ y: 600 }"
                @change="onSelectChange"
              >
                  <template slot="order" slot-scope="text, record, index">
                    {{(currentPage-1) * pageSize + index + 1}}
                  </template>
              </a-table>
            </div>
          </a-card>
        </div>
      </el-collapse-item>
    </el-collapse>
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="关键字">
              <a-input
                v-model="crud.query.value"
                :allowClear="true"
                placeholder="请输入关键字"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="创建者">
              <a-select
                v-model="crud.query.creatorID"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择客户分类"
                show-search
              >
                <a-select-option
                  v-for="item in [...allUserListMap]"
                  :key="Number(item.userID)"
                  :value="Number(item.userID)"
                  >{{ item.displayName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="创建时间">
              <a-range-picker
                v-model="crud.query.times"
                :allowClear="true"
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <SearchOptions
              :queryFuncName="queryFuncName"
              :toggleShowAdvance="false"
              style="width: 100%"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations :queryFuncName="queryFuncName" tableName="关键字" />
      <a-spin :spinning="crud.loading">
        <a-table
          :bordered="true"
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-class-name="
            (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
          "
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler
          }"
          class="ant-table-striped"
          empty-text="-"
          rowKey="keywordID"
          @resizeColumn="
            (w, col) => {
              col.width = w
            }
          "
        >
          <template slot="insertTime" slot-scope="record">
            {{ filterTime(record.insertTime) }}
          </template>

          <template slot="updateTime" slot-scope="record">
            {{ filterTime(record.updateTime) }}
          </template>

          <template slot="operation" slot-scope="record">
            <a-button
              :disabled="!$route.meta.code.includes('查看关键字详情')"
              type="link"
              @click="toInfo(record)"
            >
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <!-- 填写表单部分 -->
    <div class="draw-container">
      <a-modal
        :afterClose="
          () => {
            form = {}
            crud.refresh()
          }
        "
        :title="crud.status.title"
        :visible="crud.status.cu > 0 || crud.status.in > 0"
        wrapClassName="stationListDialog"
        @cancel="crud.cancelCU()"
      >
        <a-form-model
          ref="form"
          :class="
            crud.status.in > 0 && crud.status.cu === 0 ? 'disabled-form' : ''
          "
          :label-col="{ span: 6 }"
          :model="form"
          :rules="rules"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-model-item label="关键字" prop="value">
            <a-input
              v-model="form.value"
              :disabled="crud.status.in > 0 && crud.status.cu === 0"
            />
          </a-form-model-item>

          <a-form-model-item
            v-if="form.creatorID && form.creatorID > 0"
            label="创建者"
            prop="creator"
          >
            <a-input v-model="form.creator" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item
            v-if="form.creatorID && form.creatorID > 0"
            label="最近修改日期"
            prop="updateTime"
          >
            {{ filterTime(form.updateTime) }}
          </a-form-model-item>
          <a-form-model-item
            v-if="form.creatorID && form.creatorID > 0"
            label="创建日期"
            prop="insertTime"
          >
            {{ filterTime(form.insertTime) }}
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <div v-if="crud.status.cu > 0">
            <a-button
              v-if="crud.status.add > 0"
              key="back"
              @click="crud.cancelCU()"
            >
              取消
            </a-button>
            <a-button
              v-if="crud.status.edit > 0"
              key="back"
              @click="crud.cancelCU('uptoinfo')"
            >
              取消
            </a-button>
            <a-button
              v-if="crud.status.add > 0"
              key="submit"
              type="primary"
              @click="crud.submitCU()"
            >
              提交
            </a-button>
            <a-button
              v-if="crud.status.edit > 0"
              key="submit"
              type="primary"
              @click="editSaveKeyword"
            >
              保存
            </a-button>
          </div>
          <div v-if="crud.status.in > 0 && crud.status.cu === 0">
            <a-button
              key="back"
              :disabled="!crud.permission.edit"
              @click="crud.toEdit()"
            >
              修改
            </a-button>
            <a-popconfirm
              cancel-text="取消"
              ok-text="删除"
              placement="topRight"
              @confirm="crud.doDelete(null, 'close')"
            >
              <template slot="title">
                你确定要删除吗？
              </template>
              <a-button
                key="submit"
                :disabled="!crud.permission.del"
                type="danger"
              >
                删除
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { form, header, presenter } from "@crud/crud"
import Api, {getKeyWordAnalysis} from "@/api/ecommerce/keyword"
import { fullTimeFilter, shuffle } from "@/utils/utils";
import store from "@/store"
import { GetAllUserList } from "@/api/public-api"
import { KeywordListColumns } from "@/views/ecommerce/keyword-list"
import {
  checkOperateDisable
} from "@/router/permission"
import {
  ANALYSIS_TIME_UNIT, PieChartColor
} from "@/config";

// 表格的填充字段
const defaultForm = {}
export default {
  name: "Keyword",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "关键字",
      roleIDs: [],
      columns: KeywordListColumns,
      crudMethod: { ...Api },
      idField: "keywordID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      queryFuncName: "查询关键字列表",
      allUserListMap: [],
      rules: {
        value: [{ required: true, message: "请输入关键字", trigger: "blur" }]
      },

      showCollapse: [1],
      keyWordList: [],
      tableKeyWordList: [],
      keyWordAll:{},
      ANALYSIS_TIME_UNIT,
      trendType: ANALYSIS_TIME_UNIT.REDUCE,
      selectedRowKeys: [],
      currentPage: 1,
      pageSize: 10,
      tableColumns: [
        {
          // dataIndex: 'name',
          title: "序号",
          key: 'order',
          slots: { title: 'customTitle' },
          scopedSlots: { customRender: 'order' },
          align:"center"
        },
        {
          dataIndex: 'keyWordValue',
          title: "关键字",
          key: 'keywords',
          align:"center",
          // slots: { title: 'customTitle' },
          // scopedSlots: { customRender: 'amount' },
        },
        {
          dataIndex: 'count',
          title: "频率",
          key: 'frequency',
          align:"center",
          // slots: { title: 'customTitle' },
          // scopedSlots: { customRender: 'amount' },
        },
        {
          dataIndex: 'keywordID',
          title: "关键字ID",
          key: 'keywordsID',
          align:"center",
          // slots: { title: 'customTitle' },
          // scopedSlots: { customRender: 'amount' },
        }
      ]
    }
  },
  components: { SearchOptions, Operations, Pagination },
  async created() {
    /**
     * 设置按钮权限
     */
    let { code } = this.$route.meta
    // 关闭下载功能
    // this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: code.includes("新增关键字"),
      del: code.includes("删除关键字"),
      edit: code.includes("修改关键字")
      // download: true
    }

    this.addOk = false

    const [getAllUserListRes, getKeyWordAnalysisRes] = await Promise.all([GetAllUserList(), getKeyWordAnalysis({analysisType : 1})])

    if (getAllUserListRes.code === 0) {
      this.allUserListMap = [...getAllUserListRes.result]
    }
    console.log(getKeyWordAnalysisRes)
    if(getKeyWordAnalysisRes.code === 0){
      this.keyWordList = getKeyWordAnalysisRes.result.analysisRate
      this.tableKeyWordList = getKeyWordAnalysisRes.result.analysisRate
      this.keyWordAll = getKeyWordAnalysisRes.result
      console.log(this.tableKeyWordList)
      const dataMap = this.keyWordList
      this.drawChart(
        "chart1",
        dataMap.map(item => ({
          value: item.count,
          name: item.keyWordValue
        }))
      )
    }

    console.log(`$route.meta.code : ${this.$route.meta.code}`)

    this.roleIDs = store.getters.roleIDs
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    startTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.startTimes = [time1, time1]
          return
        }
      }
      this.crud.query.startTimes = dateStrings
    },
    toInfo(record) {
      this.crud.toInfo(record)
      if (record.users && record.users.length > 0) {
        record.userID = record.users[0].userID
        record.userType = record.users[0].userType
      }

      this.form = JSON.parse(JSON.stringify(record))
      this.currentInfo = JSON.parse(JSON.stringify(record))
      this.activeKey = 1
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    async editSaveKeyword() {
      const res = await Api.edit({
        keywordID: this.form.keywordID,
        value: this.form.value
      })
      if (res.code === 14) {
        this.$message.success("更改成功")
        this.crud.cancelCU("uptoinfo")
      }
    },

    checkOperateDisable,
    drawChart(ele, data) {
      const option = {
        color: shuffle(PieChartColor),
        tooltip: {
          trigger: "item"
        },
        grid: {
          height: '100%'
        },
        legend: {
          top: "1%",
          left: "center",
          bottom: "auto"
        },
        series: [
          {
            name: "数量占比",
            type: "pie",
            radius: ["35%", "65%"],
            center: ["50%", "55%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            },
            label: {
              show: false,
              position: "center"
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "30",
                fontWeight: "bold"
              }
            },
            labelLine: {
              show: false
            },
            data: data
          }
        ]
      }
      console.log(this)
      const myChart = this.$echarts.init(this.$refs[ele])
      myChart.setOption(option)
    },
    changeTrendType(val) {
      this.trendType = val
      console.log(this.trendType)
      if(this.trendType === 'reduce'){
        this.tableKeyWordList = this.keyWordAll.analysisRate
      }else {
        this.tableKeyWordList = this.keyWordAll.analysisRanking

      }
    },
    onSelectChange(selectedRowKeys,filters, sorter) {
      console.log(selectedRowKeys,filters, sorter)
      this.selectedRowKeys = selectedRowKeys;
      this.currentPage = selectedRowKeys.current
    },
  }
}
</script>

<style scoped lang="scss">
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}

.my-collapse {
  margin-bottom: 20px;

  .el-collapse-item {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;

    /deep/ .el-collapse-item__header {
      padding-left: 20px;
    }
  }

  .header-chart {
    width: 100%;
    margin-top: 10px;

    .card11 {
      float: left;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
      width: 39%;
      height: 760px;
    }

    .card22 {
      display: inline-block;
      float: left;
      width: 59%;
      height: 760px;
      margin-left: 1%;
      border: none;
      border-radius: 10px;
      box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
    }

  }


}
</style>
