<template>
  <div>
    <div v-if="receiptInfo.length > 0">
      <a-card v-for="(item,idx) in receiptInfo" :key="idx" :body-style="{ padding: '0px' }" :bordered="false" class="card">
        <div
          style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 5px"
        >
          <h3 style="color: #1890FF;margin: 0">合同编号：{{ item[0].contractNo }}</h3>
          <a-button key="submit" type="primary" @click="AddBtn(item[0])"
          >新增收款单</a-button
          >
        </div>

        <a-table
          :columns="receiptColumns"
          :data-source="item"
          :bordered="true"
          :pagination="false"
        >
          <template slot="receiptNo" slot-scope="record">
            <a @click="toInfo(record)">{{record.receiptNo}}</a>
          </template>
          <template slot="amount" slot-scope="record">
            {{record.receiptMoney }}
          </template>
          <template slot="time" slot-scope="record">
            {{fullTimeFilter(record.receiptTime)  }}
          </template>
          <template slot="footer" slot-scope="currentPageData">
            总额：{{ currentPageData[0].totalPrice}} ；<span style="display: inline-block; width:40px"></span>收款金额：{{receiptTatal}} ；
          </template>
          <template slot="operation" slot-scope="record" >
              <a-popconfirm
                title="确认删除吗?"
                @confirm="onDelete(record.contactReceiptID)"
              >
                <a href="javascript:;">删除</a>
              </a-popconfirm>
              <a href="javascript:;" style="margin-left: 10px" @click="toEdit(record)">编辑</a>
          </template>
        </a-table>
      </a-card>
      <a-modal width="80%" v-model="editFlag" title="编辑收款单" @ok="editSub" @cancel="cancelEdit">
        <CurrentReceiptInfo ref="currentReceiptInfoEdit"></CurrentReceiptInfo>
      </a-modal>
    </div>
    <div v-else>
      <div
        style="display: flex;justify-content: flex-end;align-items: center;margin-bottom: 5px"
      >
        <a-button key="submit" type="primary" @click="noneAddBtn"
        >新增收款单</a-button
        >
      </div>
      <a-table
        :columns="receiptColumns"
        :data-source="[]"
        :bordered="true"
      >
      </a-table>
    </div>
    <a-modal
      :visible="toFlagShow"
      title="收款单详情"
      width="50%"
      @cancel="noneHandleCancel"
    >
      <a-form-model
        ref="addReceiptForm"
        :model="receiptAdd"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="10">
            <a-form-model-item label="收款方式" prop="receiptType">
              <a-select v-model="receiptAdd.receiptType"  placeholder="请选择支付方式">
                <a-select-option v-for="item in contractReceiptType" :key="item.key" :value="Number(item.key)">
                  {{item.value}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item label="关联合同" prop="contractID">
              <a-select v-model="receiptAdd.contractID"  placeholder="请选择关联合同">
                <a-select-option v-for="(item,idx) in contractList" :key="idx" :value="item">
                  {{item}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>

          <a-col :span="10">
            <a-form-model-item ref="receiptMoney" label="收款单金额" prop="receiptMoney">
              <a-input
                v-model.number="receiptAdd.receiptMoney"
                @blur="
          () => {
            $refs.receiptMoney.onFieldBlur();
          }
        "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="10">
            <a-form-model-item label="收款时间"  prop="receiptTime">
              <a-date-picker
                v-model="receiptAdd.receiptTime"
                show-time
                type="date"
                placeholder="请选择开票时间"
                style="width: 100%;"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <template slot="footer">
        <div>
          <a-button key="back" @click="noneHandleCancel">
            取消
          </a-button>
          <a-button key="submit" type="primary" @click="noneHandleOk">
            提交
          </a-button>
        </div>
      </template>
    </a-modal>
    <a-modal
      :footer="null"
      :visible="visible"
      title="收款单详情"
      width="50%"
      @cancel="handleCancel"
    >
      <CurrentReceiptInfo ref="currentReceiptInfo"></CurrentReceiptInfo>
    </a-modal>
    <ReceiptTabPaneAdd ref="receiptAdd"></ReceiptTabPaneAdd>
  </div>
</template>

<script>
import {add, editReceipt} from "@/api/customer/receipt"
import { get } from "@/api/customer/contract"
import CurrentReceiptInfo from "@/components/customerInfo/current-receipt-Info.vue"
import { receiptColumns } from "@/components/customerInfo/contract-list"
import ReceiptTabPaneAdd from "@/components/customerInfo/receipt-tab-pane-add-info.vue"
// import { GetContractByCustomer } from "@/api/customer/contract"
import { GetContractReceiptByCustomerID,delReceipt } from "@/api/customer/receipt"
import {arrGroup, fullTimeFilterYYYYMMDD, fullTimeFilter} from "@/utils/utils"
import { GetDictionary } from "@/api/public-api";
export default {
  name: "Receipt",
  props: {
    customerID: {
      type: Number
    }
  },
  data() {
    return {
      editFlag:false,
      contractReceiptType:[],
      receiptAdd:{},
      contractList:[],
      receiptType:[],
      toFlagShow:false,
      receiptColumns,
      receiptInfo: [],
      visible:false,
      labelCol: { span: 8},
      wrapperCol: { span: 10 },
      rules: {
        contractID: [
          { required: true, message: '请选择关联合同', trigger: 'blur' },
        ],
        receiptTime: [
          { required: true, message: '请选择开票时间', trigger: 'blur' },
        ]
      }
    }
  },
  computed:{
    receiptTatal(){
      let tatal = 0
      this.receiptInfo[0].forEach(item=>{
        tatal += item.receiptMoney
      })
      return tatal
    }
  },
  components: {
    ReceiptTabPaneAdd,
    CurrentReceiptInfo
  },
  async created() {
    // this.getReceiptInfo()
    const resultObj = await GetDictionary({ typeName: "contractReceiptType" })
    this.contractReceiptType = resultObj.result
  },
  methods: {
    async onDelete(contactReceiptID) {
      const delAll = []
      delAll.push(contactReceiptID)
      const delResult = await delReceipt({contractReceiptIDs: delAll})
      if(delResult.code ===16){
        this.getReceiptInfo()
      }
    },
    AddBtn(item) {
      this.$refs.receiptAdd.toShowFlag = true
      this.$refs.receiptAdd.customerID = this.customerID
      this.$refs.receiptAdd.contractId = item.contractId
    },
    fullTimeFilterYYYYMMDD,
    fullTimeFilter,
    async getReceiptInfo(){
      const receiptObj = await GetContractReceiptByCustomerID({ customerID: this.customerID })
      this.receiptInfo =arrGroup(receiptObj.result,item=>item.contractId)
    },
    toInfo(record){
      this.visible = true
      this.$nextTick(()=>{
        this.$refs.currentReceiptInfo.currentReceipt = record
      })
    },
    handleCancel(){
      this.visible = false
    },
    noneAddBtn(){
      this.toFlagShow = true
      const contractObj =  get({customerID: this.customerID })
      this.contractList = contractObj.result
    },
    noneHandleCancel(){
      this.toFlagShow= false
      this.$refs.addReceiptForm.clearValidate()
    },
    noneHandleOk(){
      this.$refs.addReceiptForm.validate(async valid => {
        if (valid) {
          const addReceiptObj = await add(this.receiptAdd)
          if(addReceiptObj.code ===12){
            this.toFlagShow = false
            this.getReceiptInfo()
          }
        } else {
          return false;
        }
      });
    },
    editSub(){
      this.$refs.currentReceiptInfoEdit.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const submitInfo = this.$refs.currentReceiptInfoEdit.currentReceipt
          const editObj = await editReceipt({
            contractID: submitInfo.contractId,
            contractReceiptID:submitInfo.contactReceiptID,
            receiptMoney:submitInfo.receiptMoney,
            receiptTime:submitInfo.receiptTime,
            receiptType:submitInfo.receiptType
          })
          if(editObj.code===14){
            this.editFlag = false
          }
        } else {
          return false;
        }
      });

    },
    toEdit(record){
      this.editFlag=true
      this.$nextTick(()=>{
        this.$refs.currentReceiptInfoEdit.currentReceipt = record
        this.$refs.currentReceiptInfoEdit.disabled = false
      })
    },
    cancelEdit(){
      this.editFlag = false
    }
  },

}
</script>
<style scoped>
h3 {
  margin: 0;
}

a-table {
  margin-top: 20px;
}
</style>
