<template>
  <div class="app-container">
    <el-collapse
      :value="showCollapse"
      class="my-collapse"
      @change="changeCollapse($event)"
    >
      <el-collapse-item :name="1" title="统计分析">
        <div class="item-content">
          <el-radio-group
            v-model="analysisType"
            style="margin-bottom: 10px"
            @change="
              val => {
                analysisType = val
                getAnalysisData()
                getCustomerList()
              }
            "
          >
            <el-radio-button
              :disabled="checkOperateDisable('所有员工的跟进数据统计分析')"
              label="0"
              >全部
            </el-radio-button>
            <el-radio-button label="1">个人</el-radio-button>
          </el-radio-group>
          <div class="top-card">
            <a-card
              class="card card1"
              hoverable
              @click="
                () => {
                  selectFollowType = 0
                  getCustomerList()
                }
              "
            >
              <template slot="title">
                <div class="title">
                  {{ followUpTypeText[0] }}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span>
                      {{ currentDayFollowNum }}
                    </span>
                    <span></span>
                  </div>
                  <div class="_r">
                    <div class="HB">
                      <div class="_title">
                        环比昨日
                      </div>
                      <div class="_right">
                        <span :class="[dayFollowRate < 0 ? 'reduce' : 'add']">
                          <span></span>
                          <span>{{ dayFollowRate }}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_bottom"></div>
              </div>
            </a-card>
            <a-card
              class="card card1"
              hoverable
              @click="
                () => {
                  selectFollowType = 1
                  getCustomerList()
                }
              "
            >
              <template slot="title">
                <div class="title">
                  {{ followUpTypeText[1] }}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span>
                      {{ currentWeekFollowNum }}
                    </span>
                    <span></span>
                  </div>
                  <div class="_r">
                    <div class="HB">
                      <div class="_title">
                        环比上周
                      </div>
                      <div class="_right">
                        <span :class="[weekFollowRate < 0 ? 'reduce' : 'add']">
                          <span></span>
                          <span>{{ weekFollowRate }}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_bottom"></div>
              </div>
            </a-card>
            <a-card
              class="card card1"
              hoverable
              @click="
                () => {
                  selectFollowType = 2
                  getCustomerList()
                }
              "
            >
              <template slot="title">
                <div class="title">
                  {{ followUpTypeText[2] }}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span>
                      {{ currentMonthFollowNum }}
                    </span>
                    <span></span>
                  </div>
                  <div class="_r">
                    <div class="HB">
                      <div class="_title">
                        环比上月
                      </div>
                      <div class="_right">
                        <span :class="[monthFollowRate < 0 ? 'reduce' : 'add']">
                          <span></span>
                          <span>{{ monthFollowRate }}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_bottom"></div>
              </div>
            </a-card>
            <a-card
              class="card card1"
              hoverable
              @click="
                () => {
                  selectFollowType = 3
                  getCustomerList()
                }
              "
            >
              <template slot="title">
                <div class="title">
                  {{ followUpTypeText[3] }}
                </div>
              </template>
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_content">
                <div class="_top">
                  <div class="_l">
                    <span>
                      {{ currentSeasonFollowNum }}
                    </span>
                    <span></span>
                  </div>
                  <div class="_r">
                    <div class="HB">
                      <div class="_title">
                        环比上季度
                      </div>
                      <div class="_right">
                        <span
                          :class="[seasonFollowRate < 0 ? 'reduce' : 'add']"
                        >
                          <span></span>
                          <span>{{ seasonFollowRate }}%</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="_bottom"></div>
              </div>
            </a-card>
          </div>
          <div class="second-line-card">
            <a-card
              :body-style="{ padding: '0px' }"
              :bordered="false"
              class="card21"
            >
              <div class="customer-table">
                <span
                  style="font-size: 18px;margin-right: 20px;margin-left: 10px"
                >
                  {{ "跟进客户列表 - " + followUpTypeText[selectFollowType] }}
                </span>
              </div>
              <a-table
                :columns="customerListColumns"
                :dataSource="customerList"
                :pagination="false"
                :scroll="{
                  y: 240,
                  scrollToFirstRowOnChange: true
                }"
                rowKey="sourcePlatformID"
                style="width:99%;height:99%;margin:0 auto"
              >
                <template slot="customerName" slot-scope="record">
                  <a @click="showCustomer(record)">{{ record.customerName }}</a>
                </template>

                <template slot="assignType" slot-scope="record">
                  <el-tag
                    :type="
                      record.assignType === 1
                        ? ''
                        : record.assignType === 2
                        ? 'success'
                        : record.assignType === 3
                        ? 'danger'
                        : 'info'
                    "
                  >
                    {{
                      findCustomerValueByKey(
                        record.assignType,
                        allCustomerAssignTypeMap
                      )
                    }}
                  </el-tag>
                </template>

                <template slot="tag" slot-scope="record">
                  {{ findCustomerValueByKey(record.tag, allCustomerTagMap) }}
                </template>

                <template slot="followUpTime" slot-scope="record">
                  {{ filterTime(record.followUpTime) }}
                </template>

                <template slot="insertTime" slot-scope="record">
                  {{ filterTime(record.insertTime) }}
                </template>
              </a-table>
            </a-card>
            <a-card
              :body-style="{ padding: '0px' }"
              :bordered="false"
              :title="
                '跟进客户状态比例 - ' + followUpTypeText[selectFollowType]
              "
              class="card22"
            >
              <span slot="extra">截止{{ parseTime(new Date()) }}</span>
              <div class="_box">
                <div class="_t"></div>
                <div ref="chartTagRate" style="width:100%;height:350px"></div>
              </div>
            </a-card>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item
        :disabled="checkOperateDisable('所有员工跟进记录分析统计')"
        :name="2"
        title="销售人员跟进统计分析"
      >
        <div class="item-content">
          <a-card class="analysis-follow" hoverable>
            <span slot="extra">截止{{ parseTime(new Date()) }}</span>
            <div class="_box">
              <div class="_t"></div>
              <div
                ref="followUpRecordAnalysis"
                style="width:100%;height:320px"
              ></div>
            </div>
          </a-card>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="40">
          <a-col :md="3" :sm="24">
            <a-form-item label="员工姓名">
              <a-select
                v-model="crud.query.userID"
                :allowClear="true"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="请输入员工姓名"
                show-search
              >
                <a-select-option
                  v-for="item in allUserListMap"
                  :key="Number(item.userID)"
                  :value="Number(item.userID)"
                  >{{ item.displayName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="所属企业">
              <a-select
                v-model="crud.query.customerID"
                :filterOption="filterCategory"
                allow-clear
                placeholder="请选择所属企业"
                show-search
              >
                <a-select-option
                  v-for="item in allCustomerMap"
                  :key="item.customerID"
                  :value="item.customerID"
                  >{{ item.customerName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <a-form-item label="客户状态">
              <a-select
                v-model="crud.query.tag"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择客户状态"
                show-search
              >
                <a-select-option
                  v-for="item in [...allCustomerTagMap]"
                  :key="Number(item.key)"
                  :value="Number(item.key)"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <a-form-item label="客户联系人">
              <a-input
                v-model="crud.query.contactName"
                placeholder="请输入客户联系人"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="客户联系方式">
              <a-input
                v-model="crud.query.contactTel"
                placeholder="请输入客户联系方式"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="跟进时间">
              <a-range-picker
                v-model="crud.query.times"
                :allowClear="true"
                allow-clear
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <SearchOptions
              :queryFuncName="queryFuncName"
              :toggleShowAdvance="false"
              style="width: 100%"
              @toDiyQuery="toDiyQuery"
              @toDiyResetQuery="toDiyResetQuery"
            />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="跟进" />
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :scroll="{ x: 2000 }"
          rowKey="recordID"
        >
          <template slot="customerName" slot-scope="record">
            <a
              :disabled="
                checkMultiTypeOperateDisable(
                  [record.creatorId],
                  '查看跟进相关的客户详情'
                )
              "
              @click="showCustomer(record)"
              >{{ record.customerName }}</a
            >
          </template>
          <template slot="customers" slot-scope="record">
            {{ record.customers.map(item => item.customerName).join(",") }}
          </template>
          <template slot="insertTime" slot-scope="record">
            {{ filterTime(record.insertTime) }}
          </template>
          <template slot="returnVisitTime" slot-scope="record">
            {{ filterTime(record.returnVisitTime) }}
          </template>
          <template slot="operation" slot-scope="record">
            <a-button type="link" @click="toInfo(record)">
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <CustomerInfo ref="customerInfo" :addContract="false"></CustomerInfo>
    <FollowUpEditModal
      ref="followUpEditModal"
      @update-follow-up="updateFollowUp"
    ></FollowUpEditModal>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CustomerInfo from "@/components/customerInfo"
import CRUD, { header, presenter } from "@crud/crud"
import Api, {
  analysis,
  getCustomerListByFollowUpUserID,
  GetFollowUpRecordAnalysis
} from "@/api/customer/follow-up"
import { BASE_OPERATE_TYPE, FOLLOW_UP_ANALYSIS_TYPE_TEXT } from "@/config"
import { arrGroup, fullTimeFilter } from "@/utils/utils"
import store from "@/store"
import {
  GetAllContact,
  GetAllCustomer,
  GetAllUserList,
  GetDictionary
} from "@/api/public-api"
import FollowUpEditModal from "@/components/FollowUp/editModal"
import {
  followUpCustomerListColumns,
  followUpListColumns
} from "@/views/customer/follow-up-list"
import {
  drawColumnsChart,
  drawGeneralStatisticsChart
} from "@/views/customer/char"
import {
  checkMultiTypeOperateDisable,
  checkOperateDisable
} from "@/router/permission"

import { FileOperateAPI } from "@/components/UploadFile/index.js"

export default {
  name: "Goods",
  // presenter 生成crud对象
  mixins: [presenter(), header()],
  cruds() {
    return CRUD({
      title: "联系人",
      roleIDs: [],
      columns: followUpListColumns,
      crudMethod: { ...Api },
      idField: "contactID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      roleIDs: [],
      queryFuncName: "查询跟进列表",

      followUpListColumns,
      allContactMap: [],
      allCustomerMap: [],
      allCustomerTagMap: [],
      allUserListMap: [],

      analysisType: 1,
      currentDayFollowNum: 0,
      dayFollowRate: 0,
      currentWeekFollowNum: 0,
      weekFollowRate: 0,
      currentMonthFollowNum: 0,
      monthFollowRate: 0,
      currentSeasonFollowNum: 0,
      seasonFollowRate: 0,

      selectFollowType: 2,
      followUpTypeText: FOLLOW_UP_ANALYSIS_TYPE_TEXT,
      customerListColumns: followUpCustomerListColumns,
      customerList: [],

      allCustomerAssignTypeMap: [],
      showCustomerID: null,
      showCollapse: [1],

      FileOperateAPI,
      fileList: []
    }
  },
  components: {
    SearchOptions,
    Operations,
    Pagination,
    CustomerInfo,
    FollowUpEditModal
  },
  mounted() {
    const that = this
    that.getAnalysisData()
    that.getCustomerList()
  },
  async created() {
    this.crud.optShow.edit = false
    this.crud.permission = {
      add: false,
      del: false,
      edit: false
    }
    const [
      getAllContactRes,
      getAllCustomerRes,
      getCustomerTagRes,
      getCustomerAssignTypeRes,
      getAllUserListRes
    ] = await Promise.all([
      GetAllContact(),
      GetAllCustomer(),
      GetDictionary({ typeName: "customerTag" }),
      GetDictionary({ typeName: "customerAssignType" }),
      GetAllUserList()
    ])

    if (getAllContactRes.code === 0) {
      this.allContactMap = getAllContactRes.result
    }

    if (getAllCustomerRes.code === 0) {
      this.allCustomerMap = getAllCustomerRes.result
    }

    if (getCustomerTagRes.code === 0) {
      this.allCustomerTagMap = getCustomerTagRes.result
    }

    if (getCustomerAssignTypeRes.code === 0) {
      this.allCustomerAssignTypeMap = getCustomerAssignTypeRes.result
    }

    if (getAllUserListRes.code === 0) {
      this.allUserListMap = [
        { userID: 0, displayName: "暂不指定" },
        ...getAllUserListRes.result
      ]
    }

    this.roleIDs = store.getters.roleIDs
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    checkOperateDisable,
    checkMultiTypeOperateDisable,
    drawChart: drawGeneralStatisticsChart,
    async getAnalysisData() {
      const that = this
      const getAnalysisRes = await analysis({ analysisType: that.analysisType })
      if (getAnalysisRes.code === 0) {
        this.currentDayFollowNum = getAnalysisRes.result.currentDayFollowNum
        this.dayFollowRate = getAnalysisRes.result.dayFollowRate
        this.currentWeekFollowNum = getAnalysisRes.result.currentWeekFollowNum
        this.weekFollowRate = getAnalysisRes.result.weekFollowRate
        this.currentMonthFollowNum = getAnalysisRes.result.currentMonthFollowNum
        this.monthFollowRate = getAnalysisRes.result.monthFollowRate
        this.currentSeasonFollowNum =
          getAnalysisRes.result.currentSeasonFollowNum
        this.seasonFollowRate = getAnalysisRes.result.seasonFollowRate
      }
    },
    changeCollapse(value) {
      this.showCollapse = [...value]
    },
    async getCustomerList() {
      const that = this
      const getCustomerListRes = await getCustomerListByFollowUpUserID({
        analysisType: that.analysisType,
        selectFollowType: that.selectFollowType
      })
      that.customerList = getCustomerListRes.result
      const customerTagGroup = arrGroup(that.customerList, item => item.tag)
      this.drawChart(
        "chartTagRate",
        customerTagGroup.map(itemGroup => ({
          value: (itemGroup || []).length,
          name: that.findCustomerValueByKey(
            itemGroup[0].tag,
            that.allCustomerTagMap
          )
        }))
      )
    },
    showCustomer(record) {
      this.$refs.customerInfo.toCustomerInfo(record.customerID)
    },
    findCustomerValueByKey(filed, list) {
      const target = list.find(item => Number(item.key) === filed)
      if (target && target.value) {
        return target.value
      } else {
        return "-"
      }
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    drawColumnsChart,
    drawAnalysisColumnsChart(queryResult) {
      const data = {}
      data.xAxis = queryResult.map(item => item.userName)
      data.series = queryResult.map(item => item.followNum)

      const maxValue = queryResult.sort((a, b) => b.followNum - a.followNum)[0]
        .followNum
      const minValue = queryResult.sort((a, b) => a.followNum - b.followNum)[0]
        .followNum

      const maxIndex = data.series.findIndex(item => item == maxValue)
      const minIndex = data.series.findIndex(item => item == minValue)

      data.markPoint = [
        { name: "Max", value: maxValue, xAxis: maxIndex, yAxis: maxValue },
        { name: "Min", value: minValue, xAxis: minIndex, yAxis: minValue }
      ]

      this.drawColumnsChart(
        "followUpRecordAnalysis",
        data,
        ["跟进数据"],
        "跟进量"
      )
    },
    async toDiyQuery() {
      const that = this
      this.crud.toQuery()
      const queryRes = await GetFollowUpRecordAnalysis({ ...that.crud.query })
      if (
        queryRes.code === 0 &&
        queryRes.result &&
        queryRes.result.length > 0 &&
        that.showCollapse.includes(2)
      ) {
        that.drawAnalysisColumnsChart(queryRes.result)
      }
    },
    async toDiyResetQuery() {
      const that = this
      that.crud.resetQuery()
      const queryRes = await GetFollowUpRecordAnalysis({})
      if (
        queryRes.code === 0 &&
        queryRes.result &&
        queryRes.result.length > 0 &&
        that.showCollapse.includes(2)
      ) {
        that.drawAnalysisColumnsChart(queryRes.result)
      }
    },
    toInfo(record) {
      this.$refs.followUpEditModal.showData(
        record.recordID,
        BASE_OPERATE_TYPE.INFO
      )
    },
    updateFollowUp() {
      this.toDiyResetQuery()
    }
  }
}
</script>

<style lang="scss" scoped>
.my-collapse {
  margin-bottom: 20px;

  .el-collapse-item {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;

    /deep/ .el-collapse-item__content {
      padding-bottom: 0;
    }

    /deep/ .el-collapse-item__header {
      padding-left: 20px;
    }

    .item-content {
      background-color: #f0f2f5;
      padding: 10px;
    }
  }
}

.analysis-follow {
  display: inline-block;
  width: 98%;
  height: 380px;
  margin-bottom: 20px;
  margin-left: 1%;
  border: none;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
}

.top-card {
  min-width: 1156px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .card {
    margin-right: 18px;
    // height: 232px;
    &:last-child {
      margin-right: 0;
    }

    flex: 1;
    min-width: 275px;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);

    /deep/ .ant-card-head,
    /deep/ .ant-card-body {
      padding-left: 19px;
      padding-right: 19px;
    }

    /deep/ .ant-card-head {
      .ant-card-head-title {
        padding: 0;
      }

      .title {
        height: 30px;
        font-size: 22px;
        font-weight: 500;
        text-align: left;
        color: #3d3d3d;
        line-height: 30px;
      }

      .ant-card-extra {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #afafaf;
      }
    }

    /deep/ .ant-card-body {
      padding-top: 12px;
      padding-bottom: 16px;

      ._content {
        ._top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          // margin-bottom: 18px;
          ._l {
            display: flex;
            align-items: center;

            span {
              display: block;

              &:first-child {
                height: 47px;
                font-size: 40px;
                font-weight: 700;
                text-align: left;
                color: #3d3d3d;
                line-height: 47px;
                letter-spacing: 2px;
              }

              &:last-child {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #afafaf;
                line-height: 20px;
                margin-top: 10px;
                margin-left: 9px;
              }
            }
          }

          ._r {
            & > div {
              &.HB {
                margin-top: 9px;
              }

              display: flex;
              align-items: center;

              ._title {
                height: 20px;
                font-size: 14px;
                font-weight: 400;
                text-align: left;
                color: #afafaf;
                line-height: 20px;
                margin-right: 22px;
              }
            }
          }
        }
      }
    }

    &.card1 {
      .ant-card-body {
        ._content {
          ._top {
            ._r {
              & > div {
                &.HB {
                  ._right {
                    span {
                      display: flex;
                      align-items: center;

                      & > * {
                        display: block;
                      }

                      span {
                        &:last-child {
                          margin-left: -8px;
                        }
                      }

                      &.add {
                        span {
                          color: #ff4343;

                          &:first-child {
                            width: 0;
                            height: 0;
                            border-left: 9px solid transparent;
                            border-right: 9px solid transparent;
                            border-bottom: 10px solid red;
                          }
                        }
                      }

                      &.reduce {
                        span {
                          color: #05c065;

                          &:first-child {
                            width: 0;
                            height: 0;
                            border-left: 9px solid transparent;
                            border-right: 9px solid transparent;
                            border-top: 10px solid #05c065;
                          }
                        }
                      }
                    }
                  }
                }

                ._right {
                  span {
                    &:last-child {
                      color: #afafaf;
                    }
                  }
                }
              }

              ._right {
                display: flex;
                align-items: center;

                span {
                  display: block;
                  max-width: 58px;

                  &:first-child {
                    height: 22px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                    color: #3b3b3b;
                    line-height: 22px;
                    margin-right: 16px;
                  }

                  &:last-child {
                    height: 20px;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: left;
                    color: #6f7fff;
                    line-height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.second-line-card {
  width: 100%;
  height: 380px;
  margin-top: 10px;

  .card21 {
    display: inline-block;
    float: left;
    width: 62%;
    height: 360px;
    margin-bottom: 20px;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);

    .customer-table {
      justify-content: flex-start;
      align-items: center;
      margin-right: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .card22 {
    display: inline-block;
    float: left;
    width: 37%;
    height: 360px;
    margin-bottom: 20px;
    margin-left: 1%;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
  }
}
</style>
