<template>
  <div>
    <a-card :body-style="{ padding: '0px' }" :bordered="false" class="card">
      <div
        style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 10px"
      >
          <h3 style="color: #1890FF;margin: 0">产品编号：{{attachmentInfoFlat[0].projectNo ? attachmentInfoFlat[0].projectNo : '-'}}</h3>
<!--        <h3 style="color: #1890FF;margin: 0">{{attachmentInfoFlat[0].projectID || attachmentInfoFlat[0].contractID || attachmentInfoFlat[0].customerID}}</h3>-->
        <slot name="attachment"></slot>
      </div>
      <a-table
        :columns="columns"
        :data-source="attachmentInfoFlat"
        bordered
        :pagination="false"
      >
        <template slot="name" slot-scope="record">
          <a @click="toInfo(record)">{{ record.fileName }}</a>
        </template>
        <template slot="type" slot-scope="record">
          {{ attachmentTypeCum(record.attachmentType) && attachmentTypeCum(record.attachmentType).value }}
        </template>
        <template slot="address" slot-scope="record">
          {{ record.filePath }}
        </template>
        <template slot="time" slot-scope="record">
          {{ fullTimeFilter(record.insertTime) }}
        </template>
        <template slot="operation" slot-scope="record">
          <a href="javascript:;" @click="toEdit(record)">编辑</a>
        </template>
      </a-table>
    </a-card>
      <a-modal
        title="附件详情"
        :visible="visible"
        width="60%"
        @cancel="handleCancel"
        :footer="null"
      >
        <CurrentAttachmentInfoC ref="currentAttachmentInfoC"></CurrentAttachmentInfoC>
      </a-modal>
    <a-modal width="80%" v-model="editFlag" title="编辑附件相关信息" @ok="editSub" @cancel="cancelEdit">
      <CurrentAttachmentInfoC ref="currentAttachmentInfoEditC"></CurrentAttachmentInfoC>
    </a-modal>
  </div>
</template>

<script>
import {editAttachmentInfo} from "@/api/customer/attachment"
import CurrentAttachmentInfoC from "@/components/customerInfo/current-attachment-info.vue";
import { fullTimeFilter } from "@/utils/utils"
import { GetDictionary } from "@/api/public-api";
const columns = [
  {
    title: '名称',
    key: 'name',
    ellipsis: true,
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '类型',
    ellipsis: true,
    key: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '地址',
    key: 'address',
    ellipsis: true,
    scopedSlots: { customRender: 'address' },
  },
  {
    title: '上传时间',
    key: 'time',
    ellipsis: true,
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '操作',
    key:'operation',
    ellipsis: true,
    scopedSlots: {customRender: 'operation'}
  }
];

export default {
  name:"AttachmentItem",
  data(){
    return{
      editFlag:false,
      visible:false,
      columns,
      attachmentType:[]
    }
  },
  props:["attachmentInfo", ],
  computed:{
    attachmentInfoFlat(){
      return this.attachmentInfo.flat()
    },
    attachmentTypeCum(){
      this.getAttachmentType()
      return type =>{
        return  this.attachmentType.find(item=>item.key == type)
      }

    }
  },
  created() {
  },
  methods:{
    fullTimeFilter,
    GetDictionary,
    async getAttachmentType(){
      const TypeObj = await GetDictionary({ typeName: "attachmentType" })
      this.attachmentType = TypeObj.result
    },
    toInfo(record){
      this.visible = true
      this.$nextTick(()=>{
        this.$refs.currentAttachmentInfoC.currentAttachmentInfo = record
      })
    },
    handleCancel(){
      this.visible = false
    },
    toEdit(record){
      this.editFlag = true
      this.$nextTick(()=>{
        this.$refs.currentAttachmentInfoEditC.currentAttachmentInfo = record
        this.$refs.currentAttachmentInfoEditC.disabled = false
      })
    },
    editSub(){
      this.$refs.currentAttachmentInfoEditC.$refs.ruleForm.validate(async valid => {
        if (valid) {
          const currentEditObj = this.$refs.currentAttachmentInfoEditC.currentAttachmentInfo
          const editAttachmentInfoObj =  await editAttachmentInfo({
            AttachmentID: currentEditObj.attachmentID,
            attachmentType: currentEditObj.attachmentType,
            contractID:currentEditObj.contractID,
            customerID:currentEditObj.customerID,
            fileName:currentEditObj.fileName,
            filePath:currentEditObj.filePath,
            projectID:currentEditObj.projectID
          })
          if(editAttachmentInfoObj.code ===14){
            this.editFlag = false
            this.$parent.getAttachmentList()
          }
        } else {
          return false;
        }
      });
    },
    cancelEdit(){
      this.editFlag = false
    }
  },
  components:{
    CurrentAttachmentInfoC
  }
}
</script>