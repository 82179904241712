<template>
  <div class="role_container">
    <a-spin :spinning="false">
      <div class="_left">
        <div class="_top">
          <a-menu
            :selected-keys="roleSelect"
            mode="inline"
            style="width: 250px"
            @click="handleClick"
            @edit-success="editSuccess"
          >
            <a-menu-item v-for="item in roleList" :key="item.roleID">
              {{ item.roleName }}
            </a-menu-item>
          </a-menu>
        </div>
        <div class="_bottom">
          <a-button
            :disabled="!hasAdd"
            class="_create"
            type="primary"
            @click="visible = true"
          >
            创建
          </a-button>
          <a-button
            :disabled="!hasDel || roleSelect[0] == AdminRoleID"
            class="_del"
            type="danger"
            @click="DEL"
          >
            删除
          </a-button>
        </div>
      </div>
      <div class="_right">
        <RoleTree
          ref="roleTree"
          @update-selected-role="updateSelectedRole"
        ></RoleTree>
        <div class="_center">
          <div class="_label">功能权限：</div>
          <div class="_group function-check">
            <RoleFunctions
              ref="roleFunctions"
              :roleSelect="roleSelect"
            ></RoleFunctions>
          </div>
        </div>
      </div>
    </a-spin>

    <!-- 新增角色 -->
    <a-modal
      :afterClose="
        () => {
          form = {
            roleType: 1,
            roleName: '',
            parentID: 0
          }
        }
      "
      :confirm-loading="confirmLoading"
      :visible="visible"
      class="role_modal"
      title="新增角色"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <a-form-model
        ref="form"
        :label-col="{ span: 5 }"
        :model="form"
        :rules="rules"
        :wrapper-col="{ span: 19 }"
        labelAlign="left"
      >
        <a-form-model-item label="角色名称" prop="roleName">
          <a-input
            v-model="form.roleName"
            placeholder="请输入角色名称,仅限中文"
          />
        </a-form-model-item>
        <a-form-model-item label="父级角色" prop="parentRoleName">
          <a-select v-model="form.parentID" placeholder="请选择父级角色">
            <a-select-option
              v-for="item in [...roleList, { roleID: 0, roleName: '无' }]"
              :key="item.roleID"
              :value="item.roleID"
              >{{ item.roleName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="角色描述" prop="roleDescription">
          <a-textarea
            v-model="form.description"
            placeholder="请输入角色描述"
            type="text"
          />
        </a-form-model-item>
      </a-form-model>
      <div class="_content">
        <div class="_center">
          <div class="_label">功能权限：</div>
          <div class="_group function-check">
            <RoleFunctions ref="addRoleFunctions"></RoleFunctions>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
/**
 * 获取角色列表
 */
import { add, del, find } from "@/api/system/role"
/**
 * 获取权限列表
 */
import { ADMIN_ROLE_ID } from "@/config"
import RoleTree from "@/components/System/RoleTree"
import RoleFunctions from "@/components/System/RoleFunctions"

export default {
  data() {
    let validateRoleName = (rule, value, callback) => {
      let reg = /^[\u4e00-\u9fa5]+$/
      if (value.trim().length === 0) {
        callback(new Error("请输入角色名称"))
      } else if (!reg.test(value)) {
        callback(new Error("角色名称只能输入中文字符"))
      } else if (value.length < 2 || value.length > 7) {
        callback(new Error("角色名称2-7字符"))
      } else {
        callback()
      }
    }

    return {
      roleList: [], // 角色列表
      spinning: false, // 页面loading
      hasUpdate: false, // 是否拥有修改角色的权限
      hasAdd: false, // 是否拥有新增角色的权限
      hasDel: false, // 是否拥有删除角色的权限

      roleSelect: [], // 角色选择
      AdminRoleID: "", // 系统管理员ID

      visible: false,
      confirmLoading: false,

      form: {
        roleType: 1,
        roleName: "",
        parentID: 0
      },
      rules: {
        roleName: [
          { required: true, validator: validateRoleName, trigger: "blur" }
        ],
        parentID: [
          { required: true, message: "请选择父级角色", trigger: "blur" }
        ]
      }
    }
  },
  created() {
    if (this.$route.meta.code.includes("修改角色功能")) {
      this.hasUpdate = true
    }
    if (this.$route.meta.code.includes("新增角色")) {
      this.hasAdd = true
    }
    if (this.$route.meta.code.includes("删除角色")) {
      this.hasDel = true
    }
  },
  mounted() {
    this.initData()
  },
  watch: {
    visible: {
      //监听表单隐现
      handler(newVal) {
        const that = this
        if (newVal) {
          //显示表单
          that.$nextTick(() => {
            that.$refs.addRoleFunctions.toAddRole()
          })
        } else {
          //隐藏表单
          that.$nextTick(() => {
            that.form.roleName = ""
            that.$refs.addRoleFunctions.toCloseAddRole()
          })
        }
      }
    }
  },
  components: { RoleTree, RoleFunctions },
  methods: {
    async initData() {
      const getAllRoleListRes = await find({})
      if (getAllRoleListRes.code === 0) {
        this.roleList = getAllRoleListRes.result
        this.AdminRoleID = this.roleList.filter(item => {
          return item.roleID === ADMIN_ROLE_ID
        })[0].roleID
      }
      this.$refs.roleTree.initData(this.roleList)
    },
    updateSelectedRole(roleSelect) {
      this.roleSelect = [...roleSelect]
    },

    //表单确认
    handleOk() {
      this.confirmLoading = true
      const that = this
      this.$refs.form.validate(async valid => {
        if (valid) {
          const addRes = await that.ADD()
          if (addRes.code === 12) {
            await that.$refs.addRoleFunctions.toSavePermission(
              addRes.result.roleID,
              "add"
            )
            that.visible = false
            that.confirmLoading = false
          } else {
            that.confirmLoading = false
          }
        } else {
          that.confirmLoading = false
        }
      })
    },

    //取消便捷表格
    handleCancel() {
      this.visible = false
    },

    //添加权限
    ADD() {
      return new Promise((resolve, reject) => {
        add(this.form)
          .then(res => {
            resolve(res)
          })
          .catch(() => {
            reject()
          })
      })
    },
    DEL() {
      let _this = this
      this.$confirm({
        title: "确认删除该角色?",
        content: `请确认目前没有用户正在使用该角色，否则无法删除`,
        onOk() {
          del({ roleID: _this.roleSelect[0] }).then(res => {
            if (res.code === 16) {
              _this.$message.success("删除成功！该角色已从系统中删除。")
              _this.initData()
            }
          })
        },
        onCancel() {}
      })
    },

    handleClick(e) {
      this.roleSelect = [e.key]
    },

    addFunctionSelectCheck() {
      this.$refs.roleFunctions.addFunctionSelectCheck()
    },
    editSuccess() {
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
.role_container {
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;
  padding: 16px 0;
  display: flex;
  align-items: flex-start;

  /deep/ .ant-spin-nested-loading,
  /deep/ .ant-spin-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
  }

  ._left,
  ._right {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  ._left {
    width: 250px;
    border-right: 1px solid #e8e8e8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ._bottom {
      display: flex;
      align-items: center;
      justify-content: center;

      ._del {
        margin-left: 20px;
      }
    }
  }

  ._right {
    flex: 1;
    box-sizing: border-box;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;

    ._center {
      flex: 1;
      display: flex;
      align-items: flex-start;
      padding: 12px 20px;
      margin-top: 20px;
      width: 600px;
      height: 700px;

      ._label {
        width: 100px;
        line-height: 30px;
      }

      .function-check {
        /deep/ .ant-tree > li:first-child {
          padding-top: 4px;
        }
      }
    }

    ._top,
    ._bottom {
      box-sizing: border-box;
      padding: 0 20px;
    }

    ._top {
      display: flex;
      align-items: center;
      padding: 12px 20px;
      border-bottom: 1px solid #e8e8e8;

      ._label {
        width: 100px;
      }

      .login-check {
        flex: 1;
        display: flex;
        align-items: center;
      }
    }
  }
}
</style>
