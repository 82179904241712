<template>
  <div>
    <a-modal
      :title="
        toShowFlag === CONTRACT_SHOW_TYPE.ADD_CONTRACT ? '新增合同' : '合同详情'
      "
      :visible="toShowFlag != CONTRACT_SHOW_TYPE.NOT_SHOW"
      width="80%"
      wrapClassName="stationListDialog"
      @cancel="cancelContract"
    >
      <a-form-model
        ref="addContractForm"
        :label-col="{ span: 6 }"
        :model="currentContract"
        :rules="currentContractRules"
        :wrapper-col="{ span: 18 }"
        labelAlign="left"
      >
        <a-row :gutter="48">
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #0b64f4;">合同基本信息</h3>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="40">
          <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
            <a-form-model-item label="客户名称" prop="customerName">
              <span>{{ currentContract.customerName }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
            <a-form-model-item
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              label="客户联系人"
              prop="contactId"
            >
              <a-select
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.contactId"
                :allowClear="true"
                :filterOption="filterSearch"
                :show-arrow="false"
                not-found-content="未找到客户联系人"
                placeholder="选择客户联系人"
                show-search
              >
                <a-select-option
                  v-for="item in [...customerContactList]"
                  :key="Number(item.contactID)"
                  :value="Number(item.contactID)"
                  >{{ item.contactName }}
                </a-select-option>
              </a-select>
              <span v-else>{{
                [...customerContactList].find(
                  item => item.contactID == currentContract.contactId
                )
                  ? [...customerContactList].find(
                      item => item.contactID == currentContract.contactId
                    ).contactName
                  : "-"
              }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
            <a-form-model-item
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              label="销售负责人"
              prop="userID"
            >
              <a-select
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.userID"
                :allowClear="true"
                :filterOption="filterSearch"
                :show-arrow="false"
                not-found-content="未找到销售负责人"
                placeholder="选择销售负责人"
                show-search
              >
                <a-select-option
                  v-for="item in [...allUserListMap]"
                  :key="Number(item.userID)"
                  :value="Number(item.userID)"
                  >{{ item.displayName }}
                </a-select-option>
              </a-select>
              <span v-else>{{
                [...allUserListMap].find(
                  item => item.userID == currentContract.userID
                )
                  ? [...allUserListMap].find(
                      item => item.userID == currentContract.userID
                    ).displayName
                  : "-"
              }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
            <a-form-model-item label="合同编号" prop="contractNo">
              <a-input
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.contractNo"
                placeholder="请输入合同编号"
              />
              <span v-else>{{ currentContract.contractNo }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
            <a-form-model-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 14 }"
              label="商务对接方式"
              prop="businessConnectType"
            >
              <a-select
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.businessConnectType"
                :allowClear="true"
                :filterOption="filterSearch"
                :show-arrow="false"
                not-found-content="未找到商务对接方式"
                placeholder="选择商务对接方式"
                show-search
              >
                <a-select-option
                  v-for="item in [...allBusinessConnectType]"
                  :key="Number(item.key)"
                  :value="Number(item.key)"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
              <span v-else>{{
                [...allBusinessConnectType].find(
                  item =>
                    Number(item.key) == currentContract.businessConnectType
                )
                  ? [...allBusinessConnectType].find(
                      item =>
                        Number(item.key) == currentContract.businessConnectType
                    ).value
                  : "-"
              }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="40">
          <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
            <a-form-model-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 14 }"
              label="商务成交方式"
              prop="businessCooperationType"
            >
              <a-select
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.businessCooperationType"
                :allowClear="true"
                :filterOption="filterSearch"
                :show-arrow="false"
                not-found-content="未找到商务成交方式"
                placeholder="选择商务成交方式"
                show-search
              >
                <a-select-option
                  v-for="item in [...allBusinessCooperationType]"
                  :key="Number(item.key)"
                  :value="Number(item.key)"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
              <span v-else>{{
                [...allBusinessCooperationType].find(
                  item =>
                    Number(item.key) == currentContract.businessConnectType
                )
                  ? [...allBusinessCooperationType].find(
                      item =>
                        Number(item.key) == currentContract.businessConnectType
                    ).value
                  : "-"
              }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
            <a-form-model-item
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 14 }"
              label="质保金额"
              prop="qualityGuaranteeMoney"
            >
              <a-input
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.qualityGuaranteeMoney"
                placeholder="请输入质保金额"
                suffix="￥"
                type="number"
              />
              <span v-else>{{ currentContract.contractNo }}</span>
            </a-form-model-item>
          </a-col>

          <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
            <a-form-model-item
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              label="质保到期时间"
              labelAlign="left"
              prop="qualityGuaranteeTime"
            >
              <a-date-picker
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.qualityGuaranteeTime"
                :allowClear="true"
              />
              <span v-else>{{ filterTime(currentContract.signTime) }}</span>
            </a-form-model-item>
          </a-col>

          <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
            <a-form-model-item
              label="签约日期"
              labelAlign="left"
              prop="signTime"
            >
              <a-date-picker
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.signTime"
                :allowClear="true"
              />
              <span v-else>{{ filterTime(currentContract.signTime) }}</span>
            </a-form-model-item>
          </a-col>

          <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
            <a-form-model-item label="交付日期:" prop="releasedTime">
              <a-date-picker
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.releasedTime"
                :allowClear="true"
              />
              <span v-else>{{ filterTime(currentContract.signTime) }}</span>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="48">
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #0b64f4;">备注与附件信息</h3>
            </div>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <a-form-model-item
              prop="note"
              :labelCol="{ span: 2 }"
              :wrapperCol="{ span: 24 }"
            >
              <a-textarea
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                v-model="currentContract.note"
                :allowClear="true"
                :autosize="{ minRows: 3 }"
                :showCount="true"
                placeholder="请输入合同备注信息"
              >
              </a-textarea>
              <span v-else style="margin-left: 30px">{{
                currentContract.note
              }}</span>
            </a-form-model-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <a-form-model-item
              :labelCol="{ span: 2 }"
              :wrapperCol="{ span: 22 }"
              label="上传文件附件"
              prop="files"
            >
              <UploadFile
                :disabled="true"
                v-if="toShowFlag == CONTRACT_SHOW_TYPE.ADD_CONTRACT"
                ref="uploadFile"
                :allFileCancelPath="FileOperateAPI.ContractAllFileCancel"
                :customerID="customerID"
                :fileRemovedPath="FileOperateAPI.ContractFileRemoved"
                :fileUploadPath="FileOperateAPI.ContractFileUpload"
              ></UploadFile>
              <div v-else>
                <a
                  v-for="(fileItem, index) in uploadFilePath"
                  :key="index"
                  :href="fileItem.filePath"
                  style="display: inline-block;margin-right: 30px"
                  target="_blank"
                  >{{ fileItem.fileName }}</a
                >
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="48" style="margin-top: 20px">
          <a-col :lg="20" :md="24" :sm="24" :xl="20" :xs="24" :xxl="20">
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #0b64f4;">产品信息</h3>
            </div>
          </a-col>
          <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
            <div
              style="display: flex;justify-content: flex-end;vertical-align: middle;align-items: center;margin-bottom: 20px"
            >
              <a-button type="primary" @click="toAddProductGoods()">
                新增产品
              </a-button>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <a-form-model-item :wrapperCol="{ span: 24 }" prop="projectGoods">
              <a-table
                :columns="projectGoodsColumns"
                :dataSource="projectGoodsData"
                :pagination="false"
                :scroll="{
                  y: 300,
                  scrollToFirstRowOnChange: true
                }"
              >
                <template slot="projectNo" slot-scope="text, record">
                  <a-input
                    v-if="toShowFlag == CONTRACT_SHOW_TYPE.SHOW_CONTRACT"
                    v-model="record.projectNo"
                    :disabled="toShowFlag == CONTRACT_SHOW_TYPE.SHOW_CONTRACT"
                    placeholder="请输入产品编号"
                  ></a-input>
                  <span v-else>自动生成</span>
                </template>

                <template slot="goodsID" slot-scope="text, record, index">
                  <a-select
                    v-model="record.goodsID"
                    :allowClear="true"
                    :disabled="toShowFlag == CONTRACT_SHOW_TYPE.SHOW_CONTRACT"
                    :filterOption="filterSearch"
                    :show-arrow="false"
                    not-found-content="未找到货品"
                    placeholder="请选择货品"
                    show-search
                    @change="changeProjectGood($event, index)"
                    @select="selectProjectGood($event, index)"
                  >
                    <a-select-option
                      v-for="item in [...allGoodsMap]"
                      :key="Number(item.goodsID)"
                      :value="Number(item.goodsID)"
                      >{{ item.goodsName + " ( " + item.goodsSpec + " ) " }}
                    </a-select-option>
                  </a-select>
                </template>
                <template slot="goodsSpec" slot-scope="text, record">
                  <a-input
                    v-model="record.goodsSpec"
                    :disabled="true"
                    placeholder="请选择货品"
                  ></a-input>
                </template>
                <template slot="categoryName" slot-scope="text, record">
                  <a-input
                    v-model="record.categoryName"
                    :disabled="true"
                    placeholder="请选择货品"
                  ></a-input>
                </template>
                <template slot="unitPrice" slot-scope="text, record, index">
                  <a-input
                    v-model="record.unitPrice"
                    :disabled="toShowFlag == CONTRACT_SHOW_TYPE.SHOW_CONTRACT"
                    placeholder="输入单价"
                    suffix="￥"
                    type="number"
                    @change="changeUnitPrice(record.unitPrice, index)"
                  ></a-input>
                </template>
                <template slot="number" slot-scope="text, record, index">
                  <a-input
                    v-model="record.number"
                    :disabled="toShowFlag == CONTRACT_SHOW_TYPE.SHOW_CONTRACT"
                    placeholder="数量"
                    type="number"
                    @change="changeNumber(record.number, index)"
                  ></a-input>
                </template>
                <template slot="totalPrice" slot-scope="text, record">
                  <a-input
                    v-model="record.totalPrice"
                    :disabled="true"
                  ></a-input>
                </template>
                <template slot="releasedTime" slot-scope="text, record">
                  <a-date-picker
                    v-model="record.releasedTime"
                    :allowClear="true"
                    :disabled="toShowFlag == CONTRACT_SHOW_TYPE.SHOW_CONTRACT"
                  />
                </template>
                <template slot="paymentStatus" slot-scope="text, record">
                  <a-select
                    v-model="record.paymentStatus"
                    :allowClear="true"
                    :disabled="toShowFlag == CONTRACT_SHOW_TYPE.SHOW_CONTRACT"
                    :filterOption="filterSearch"
                    :show-arrow="false"
                    not-found-content="未找到状态"
                    placeholder="选择支付状态"
                    show-search
                  >
                    <a-select-option
                      v-for="item in [...allContractPaymentStatusMap]"
                      :key="Number(item.key)"
                      :value="Number(item.key)"
                      >{{ item.value }}
                    </a-select-option>
                  </a-select>
                </template>

                <template slot="operation" slot-scope="text, record, index">
                  <a-button
                    type="link"
                    @click="toDeleteProjectGoods(record, index)"
                  >
                    删除
                  </a-button>
                </template>
              </a-table>
            </a-form-model-item>
          </a-col>
        </a-row>

        <a-row :gutter="48" style="margin-top: 20px">
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #575555;">
                <a-form-model-item
                  :label-col="{ span: 10 }"
                  :wrapper-col="{ span: 14 }"
                  label="产品总金额"
                  prop="totalPrice"
                >
                  <a-input
                    v-model="currentContract.totalPrice"
                    :disabled="true"
                  />
                </a-form-model-item>
              </h3>
            </div>
          </a-col>
        </a-row>
      </a-form-model>
      <template slot="footer">
        <div>
          <a-button key="back" @click="cancelContract">
            取消
          </a-button>
          <a-button key="submit" type="primary" @click="submitContract">
            提交
          </a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { CONTRACT_SHOW_TYPE } from "@/config"
import UploadFile from "@/components/UploadFile/index.vue"
import { FileOperateAPI } from "@/components/UploadFile/index.js"
import { fullTimeFilter } from "@/utils/utils"
import { GetAllUserList, GetDictionary } from "@/api/public-api"
import api from "@/api/customer/contract"
import { projectGoodsColumns } from "@/components/customerInfo/contract-list"
import { getAll as getAllGoodsList } from "@/api/goods/index"
import { GetCustomerContact } from "@/api/customer/customer"

export default {
  name: "ContractInfo",
  data() {
    const that = this
    const checkProjectGoods = (rule, value, callback) => {
      if (
        that.projectGoodsData.find(
          item =>
            !item.goodsID ||
            !item.totalPrice ||
            !item.releasedTime ||
            !item.paymentStatus
        )
      ) {
        return callback(new Error("请核对产品信息"))
      }
      callback()
    }
    return {
      CONTRACT_SHOW_TYPE,
      toShowFlag: CONTRACT_SHOW_TYPE.NOT_SHOW,
      currentContract: {},
      customerID: 0,

      FileOperateAPI,
      allUserListMap: [],
      allGoodsMap: [],
      customerContactList: [],
      allContractPaymentStatusMap: [],
      allBusinessConnectType: [],
      allBusinessCooperationType: [],

      projectGoodsColumns,
      uploadFilePath: [],
      projectGoodsData: [
        {
          projectID: 0,
          projectNo: null,
          goodsID: null,

          classes: 0,
          goodsSpec: null,
          categoryName: null,
          unitPrice: null,
          number: null,
          totalPrice: null,
          releasedTime: null,
          paymentStatus: 1
        }
      ],
      currentContractRules: {
        // customerName
        contactId: [
          { required: true, message: "请选择客户联系人", trigger: "blur" }
        ],
        userID: [
          { required: true, message: "请选择销售人员", trigger: "blur" }
        ],
        contractNo: [
          { required: true, message: "请填写合同编号", trigger: "blur" }
        ],
        signTime: [
          { required: true, message: "请选择签约日期", trigger: "change" }
        ],
        releasedTime: [
          { required: true, message: "请选择交付日期", trigger: "change" }
        ],
        businessConnectType: [
          { required: true, message: "请选择商务对接方式", trigger: "blur" }
        ],
        businessCooperationType: [
          { required: true, message: "请选择商务成交方式", trigger: "blur" }
        ],
        qualityGuaranteeMoney: [
          { required: true, message: "请选择质保金额", trigger: "blur" }
        ],
        projectGoods: [
          { required: true, validator: checkProjectGoods, trigger: "blur" }
        ]
      }
    }
  },
  components: {
    UploadFile
  },
  props: {},
  watch: {
    customerID: {
      async handler(value) {
        const that = this
        if (value > 0) {
          const getContractByCustomerRes = await GetCustomerContact({
            customerID: that.customerID
          })
          if (getContractByCustomerRes.code === 0) {
            this.customerContactList = getContractByCustomerRes.result
          }
        }
      },
      deep: true
    }
  },
  async created() {
    const that = this
    const [
      getContractPaymentStatusRes,
      getAllUserListRes,
      getAllGoodsListRes,
      getBusinessConnectTypeRes,
      getBusinessCooperationTypeRes
    ] = await Promise.all([
      GetDictionary({ typeName: "contractPaymentStatus" }),
      GetAllUserList(),
      getAllGoodsList(),
      GetDictionary({ typeName: "businessConnectType" }),
      GetDictionary({ typeName: "businessCooperationType" })
    ])

    if (getContractPaymentStatusRes.code === 0) {
      that.allContractPaymentStatusMap = [...getContractPaymentStatusRes.result]
    }
    if (getAllUserListRes.code === 0) {
      that.allUserListMap = [
        { userID: 0, displayName: "暂不指定" },
        ...getAllUserListRes.result
      ]
    }
    if (getAllGoodsListRes.code === 0) {
      that.allGoodsMap = [...getAllGoodsListRes.result]
    }
    if (getBusinessConnectTypeRes.code === 0) {
      this.allBusinessConnectType = getBusinessConnectTypeRes.result
    }
    if (getBusinessCooperationTypeRes.code === 0) {
      this.allBusinessCooperationType = getBusinessCooperationTypeRes.result
    }
  },
  methods: {
    filterSearch: (value, option) =>
      option.componentOptions.children[0].text.indexOf(value) >= 0,
    filterTime: timeStr => fullTimeFilter(timeStr),
    closeContract() {
      const that = this
      that.toShowFlag = CONTRACT_SHOW_TYPE.NOT_SHOW
      that.$refs["addContractForm"].clearValidate()
      that.$parent.showData(
        that.currentContract.customerID,
        that.currentContract.customerName
      )
      that.currentContract = {}
      that.uploadFilePath = []
      that.projectGoodsData = [
        {
          projectID: 0,
          projectNo: null,
          goodsID: null,
          classes: 0,
          goodsSpec: null,
          categoryName: null,
          unitPrice: null,
          number: null,
          totalPrice: null,
          releasedTime: null,
          paymentStatus: 1
        }
      ]
    },
    cancelContract() {
      const that = this
      that.closeContract()
      if (that.toShowFlag === CONTRACT_SHOW_TYPE.ADD_CONTRACT) {
        that.$refs.uploadFile.AllFileCancel()
      }
    },
    toAddProductGoods() {
      const that = this
      const product = {
        projectID: 0,
        projectNo: null,
        goodsID: null,
        goodsName: null,
        classes: 0,
        goodsSpec: null,
        categoryName: null,
        unitPrice: null,
        number: null,
        totalPrice: null,
        releasedTime: null,
        paymentStatus: 1
      }
      if (that.currentContract.releasedTime) {
        product.releasedTime = that.currentContract.releasedTime
      }
      that.projectGoodsData.push({ ...product })
    },
    toDeleteProjectGoods(record, index) {
      const that = this
      that.projectGoodsData.splice(index, 1)
      this.$forceUpdate()
    },
    selectProjectGood(value, index) {
      const goods = this.allGoodsMap.find(item => item.goodsID === value)
      this.projectGoodsData[index].goodsName = goods.goodsName
      this.projectGoodsData[index].classes = goods.classes
      this.projectGoodsData[index].goodsSpec = goods.goodsSpec
      this.projectGoodsData[index].categoryName = goods.categoryName
    },
    changeProjectGood(value, index) {
      if (value === 0) {
        this.projectGoodsData[index].goodsName = null
        this.projectGoodsData[index].classes = null
        this.projectGoodsData[index].goodsSpec = null
        this.projectGoodsData[index].categoryName = null
      }
    },
    changeUnitPrice(value, index) {
      const that = this
      if (!isNaN(Number(value))) {
        const unitprice = Number(value)
        that.projectGoodsData[index].unitPrice = unitprice
        const numberStr = that.projectGoodsData[index].number
        if (!isNaN(Number(numberStr))) {
          const number = Number(numberStr)
          that.projectGoodsData[index].totalPrice = Math.round(
            unitprice * number
          )
        } else {
          that.projectGoodsData[index].totalPrice = 0
        }
      } else {
        that.projectGoodsData[index].totalPrice = 0
      }
      that.calculateTotalAmount()
    },
    changeNumber(value, index) {
      const that = this
      if (!isNaN(Number(value))) {
        const number = Number(value)
        that.projectGoodsData[index].number = number
        const unitPriceStr = that.projectGoodsData[index].unitPrice
        if (!isNaN(Number(unitPriceStr))) {
          const unitprice = Number(unitPriceStr)
          that.projectGoodsData[index].totalPrice = Math.round(
            unitprice * number
          )
        } else {
          that.projectGoodsData[index].totalPrice = 0
        }
      } else {
        that.projectGoodsData[index].totalPrice = 0
      }
      that.calculateTotalAmount()
    },
    calculateTotalAmount() {
      const that = this
      const totalPrice = that.projectGoodsData.reduce((total, currentGood) => {
        return total + currentGood.totalPrice
      }, 0)
      that.currentContract.totalPrice = totalPrice
      that.$forceUpdate()
    },
    submitContract() {
      const that = this
      this.$refs["addContractForm"].validate(async valid => {
        if (!valid) {
          return
        }
        that.currentContract.qualityGuaranteeMoney = Number(
          that.currentContract.qualityGuaranteeMoney
        )
        that.currentContract["files"] = that.$refs["uploadFile"].fileList
        that.currentContract["projectGoods"] = that.projectGoodsData
        const result = await api.add({ ...that.currentContract })
        if (result.code === 0) {
          that.closeContract()
        } else {
          alert("提交合同失败，请联系管理员")
        }
      })
    },
  }
}
</script>

<style scoped>
.card1 {
  margin-top: 20px;
}

.card2 {
  margin-top: 20px;
}

.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}
</style>
