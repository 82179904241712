<template>
  <div>
    <a-modal
      title="新增发票"
      :visible="toShowFlag"
      :confirm-loading="false"
      width="80%"
      @cancel="handleCancel"
    >
      <a-row :gutter="48">
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <div
            style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
          >
            <h3 style="color: #0b64f4;">发票列表</h3>
          </div>
        </a-col>
      </a-row>
      <a-table :columns="invoiceColumns" :data-source="currentInvoice" bordered>
        <template slot="invoiceNum" slot-scope="record">
          {{record.invoiceNo}}
        </template>
        <template slot="amount" slot-scope="record">
          {{record.invoiceMoney}}
        </template>
        <template slot="time" slot-scope="record">
          {{fullTimeFilter(record.invoiceTime)}}
        </template>
        <template slot="uploadFile" slot-scope="record">
          {{record.fileName}}
        </template>

<!--                <template slot="operation" slot-scope="record">-->
<!--                  <a-popconfirm-->
<!--                    title="确认删除吗?"-->
<!--                    @confirm="() => onDelete(record)"-->
<!--                  >-->
<!--                    <a href="javascript:;">删除</a>-->
<!--                  </a-popconfirm>-->
<!--                </template>-->
      </a-table>
      <a-row :gutter="48">
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <div
            style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
          >
            <h3 style="color: #0b64f4;">合同详情</h3>
          </div>
        </a-col>
      </a-row>
      <a-collapse v-model="activeKey">
        <a-collapse-panel key="1" :header="currentContractDet.contractNo || '暂无数据'">
          <a-form-model
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            labelAlign="left"
          >
            <a-row :gutter="40">
              <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
                <a-form-model-item label="客户名称">
                  <span>{{currentContractDet.customerName}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
                <a-form-model-item
                  :label-col="{ span: 8 }"
                  :wrapper-col="{ span: 16 }"
                  label="客户联系人"
                >

                  <span>{{currentContractDet.contactName}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
                <a-form-model-item
                  :label-col="{ span: 8 }"
                  :wrapper-col="{ span: 16 }"
                  label="销售负责人"
                >
                  <span >{{currentContractDet.operator}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
                <a-form-model-item label="合同编号">

                  <span>{{currentContractDet.contractNo}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
                <a-form-model-item
                  :label-col="{ span: 10 }"
                  :wrapper-col="{ span: 14 }"
                  label="商务对接方式"
                >
                  <span>{{contractReceiptTypeCum(currentContractDet.businessConnectType)}}</span>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row :gutter="40">
              <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
                <a-form-model-item
                  :label-col="{ span: 10 }"
                  :wrapper-col="{ span: 14 }"
                  label="商务成交方式"
                >
                  <span>{{businessCooperationTypeCum(currentContractDet.businessCooperationType)}}</span>
                </a-form-model-item>
              </a-col>
              <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
                <a-form-model-item
                  :label-col="{ span: 10 }"
                  :wrapper-col="{ span: 14 }"
                  label="质保金额"
                >
                  <span >{{currentContractDet.qualityGuaranteeMoney}}</span>
                </a-form-model-item>
              </a-col>

              <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
                <a-form-model-item
                  :label-col="{ span: 8 }"
                  :wrapper-col="{ span: 16 }"
                  label="质保到期时间"
                  labelAlign="left"
                >

                  <span>{{fullTimeFilter(currentContractDet.QualityGuaranteeTime)}}</span>
                </a-form-model-item>
              </a-col>

              <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
                <a-form-model-item
                  label="签约日期"
                  labelAlign="left"
                  prop="signTime"
                >
                  <span>{{fullTimeFilter(currentContractDet.signTime)}}</span>
                </a-form-model-item>
              </a-col>

              <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
                <a-form-model-item label="交付日期:" prop="releasedTime">
                  <span>{{fullTimeFilter(currentContractDet.insertTime)}}</span>
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-collapse-panel>
      </a-collapse>
      <a-row :gutter="48" style="margin-top: 20px">
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <div
            style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
          >
            <h3 style="color: #0b64f4;">填写表单信息</h3>
          </div>
        </a-col>
      </a-row>
      <a-form-model
        ref="ruleForm"
        :model="invoiceAdd"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-row>
          <a-col :span="12">
            <a-form-model-item ref="invoiceNo" label="发票编号" prop="invoiceNo">
              <a-input
                placeholder="请输入发票编号"
                v-model="invoiceAdd.invoiceNo"
                @blur="
          () => {
            $refs.invoiceNo.onFieldBlur();
          }
        "
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item ref="invoiceMoney" label="发票金额" prop="invoiceMoney">
              <a-input
                type="number"
                placeholder="请输入发票金额"
                v-model.number="invoiceAdd.invoiceMoney"
                @blur="
          () => {
            $refs.invoiceMoney.onFieldBlur();
          }
        "
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="12">
            <a-form-model-item label="相关产品" prop="projectID">
              <a-select v-model="invoiceAdd.projectID"  placeholder="请选择产品">
                <a-select-option v-for="item in contractProjects" :key="item.projectID" :value="item.projectID">
                  {{item.goodsName}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="收款时间" required prop="invoiceTime">
              <a-date-picker
                v-model="invoiceAdd.invoiceTime"
                show-time
                type="date"
                placeholder="请选择开票时间"
                style="width: 100%;"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="24">
            <a-form-model-item
              label="上传文件"
              prop="uploadFile"
              :labelCol="{span: 4}"
              :wrapperCol="{span: 17}"
            >
              <SingleUploadFile
                :customerID="customerID"
                fileUploadPath="file-upload/invoice"
                fileRemovedPath="file-upload/invoice"
                allFileCancelPath="file-upload/attachment/cancel"
                ref="singleUploadFile"
              ></SingleUploadFile>
            </a-form-model-item>
          </a-col>

        </a-row>
      </a-form-model>
      <template slot="footer">
        <div>
          <a-button key="back" @click="handleCancel">
            取消
          </a-button>
          <a-button key="submit" type="primary" @click="handleOk">
            提交
          </a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import SingleUploadFile from "@/components/SingleUploadFile/index.vue"
import {fullTimeFilter} from "@/utils/utils"
import { AddInvoiceByContractId, GetInvoiceListByContractId } from "@/api/customer/invoice";
import { invoiceColumns } from "@/components/customerInfo/contract-list"
import {getGetContractDetail} from "@/api/customer/contract"
import { GetDictionary } from "@/api/public-api";
import {GetContractProjects} from "@/api/project"
// import { delInvoice } from "@/api/customer/invoice"
export  default {
  name:"InvoiceAdd",
  props: {
    customerID: {
      type: Number
    }
  },
  data() {
    return {
      contractProjects:[],
      businessCooperationType:[],
      businessConnectType:[],
      currentContractDet:[],
      currentInvoice:[],
      contractId:'',
      toShowFlag: false,
      activeKey: ["1"],
      labelCol: { span: 8},
      wrapperCol: { span: 10 },
      invoiceAdd:{
        invoiceNo:'',
        invoiceTime:'',
        projectID:'',
        projectNum:1,
        invoiceMoney:'',
        filePath:'',
        fileName:''
      },
      goods:[],
      headers: {
        authorization: 'authorization-text',
      },
      rules: {
        invoiceNo: [
          { required: true, message: '请输入合同id', trigger: 'blur' },
        ],
        invoiceMoney: [
          { required: true, message: '请输入收款金额', trigger: 'blur' },
        ],
        region: [
          { required: true, message: '请选择支付方式', trigger: 'change' }
        ],
        invoiceTime: [
          { required: true, message: '请选择一个时间', trigger: 'change' }
        ],
      },
      invoiceColumns
    }
  },
  async created() {
    const resultObj = await GetDictionary({ typeName: "businessConnectType" })
    const CooperationTypeResultObj = await GetDictionary({ typeName: "businessCooperationType" })
    this.businessConnectType = resultObj.result
    this.businessCooperationType = CooperationTypeResultObj.result

  },
  components:{
    SingleUploadFile
  },
  methods:{
    async getInvoiceList(contractId){
      const invoiceListObj = await GetInvoiceListByContractId({contractID :contractId})
      const contractDetObj = await getGetContractDetail({contractID :contractId})
      const contractProjectsObj = await GetContractProjects({contractID :contractId})
      this.contractProjects = contractProjectsObj.result
      this.currentContractDet = contractDetObj.result
      this.currentInvoice = invoiceListObj.result
    },
    handleCancel() {
      this.toShowFlag = false
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    fullTimeFilter,
    contractReceiptTypeCum(businessConnectType){
        const result =this.businessConnectType.filter(item=>{
           return item.key==businessConnectType
        })
      return result[0]?.value
    },
    businessCooperationTypeCum(businessCooperationType){
      const result = this.businessCooperationType.filter(item=>{
        return item.key==businessCooperationType
      })
      return result[0]?.value
    },
    handleOk(){
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          this.invoiceAdd.filePath = this.$refs.singleUploadFile.fileList[0].filePathUrl
          this.invoiceAdd.fileName = this.$refs.singleUploadFile.fileList[0].fileName
          this.invoiceAdd.contractID = this.contractId
          const addInvoiceObj = await AddInvoiceByContractId(this.invoiceAdd)
          if(addInvoiceObj.code ===12){
            this.toShowFlag = false
            this.$parent.getInvoiceList()
          }
        } else {
          return false;
        }
      });
    },
    // onDelete(record){
    //   console.log(record)
    //   const delData = [];
    //     delData.push(record.contractInvoiceID)
    //   delInvoice({contractReceiptIDs: delData})
    // }
  },
}
</script>