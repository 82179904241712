<template>
  <div class="app-container">
    <div class="head-container">
      <div ref="chinaMap" style="width:100%;height:1000px"></div>
    </div>
  </div>
</template>

<script src="https://api.map.baidu.com/api?v=2.0&ak=ASDmkiDYUvxTHnXRGpsf1YhWEl5QMvPW"></script>
<script>
import "echarts/extension/bmap/bmap"
import { customerMap } from "@/api/customer/customerMap"

export default {
  name: "customerMap",
  async created() {},
  async mounted() {
    const that = this
    this.loadBMap(that.ASK).then(async () => {
      const cityCountRes = await customerMap()
      if (
        cityCountRes &&
        cityCountRes.result &&
        cityCountRes.result &&
        cityCountRes.result.length > 0
      ) {
        that.customerCityCount = cityCountRes.result.map(item => ({
          name: item.city,
          value: Number(item.count)
        }))
      }

      ;[
        ...require("@/config/city.json"),
        ...require("@/config/province.json")
      ].forEach(item => {
        if (item.lng && item.lat && item.name) {
          that.cityLocation[item.name] = [item.lng, item.lat]
        }
      })

      that.initMap()
    })
  },
  data() {
    return {
      ASK: "ASDmkiDYUvxTHnXRGpsf1YhWEl5QMvPW",
      customerCityCount: [],
      cityLocation: {}
    }
  },
  methods: {
    loadBMap: function(ak) {
      return new Promise(function(resolve, reject) {
        if (typeof BMap !== "undefined") {
          resolve(BMap)
          return true
        }
        window.onBMapCallback = function() {
          resolve(BMap)
        }
        let script = document.createElement("script")
        script.type = "text/javascript"
        script.src =
          "http://api.map.baidu.com/api?v=2.0&ak=" +
          ak +
          "&__ec_v__=20190126&callback=onBMapCallback"
        script.onerror = reject
        document.head.appendChild(script)
      })
      require("echarts/extension/bmap/bmap")
    },
    initMap: function() {
      const that = this
      const data = that.customerCityCount
      const geoCoordMap = that.cityLocation
      const convertData = function(data) {
        const res = []
        for (var i = 0; i < data.length; i++) {
          const geoCoord = geoCoordMap[data[i].name]
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value)
            })
          }
        }
        return res
      }
      const option = {
        title: {
          text: "全国客户城市分布图",
          subtext: "National customer distribution map",
          left: "center"
        },
        tooltip: {
          trigger: "item"
        },
        bmap: {
          center: [104.114129, 37.550339],
          zoom: 5,
          roam: true,
          mapStyle: {
            styleJson: [
              {
                featureType: "water",
                elementType: "all",
                stylers: {
                  color: "#d1d1d1"
                }
              },
              {
                featureType: "land",
                elementType: "all",
                stylers: {
                  color: "#f3f3f3"
                }
              },
              {
                featureType: "railway",
                elementType: "all",
                stylers: {
                  visibility: "off"
                }
              },
              {
                featureType: "highway",
                elementType: "all",
                stylers: {
                  color: "#fdfdfd"
                }
              },
              {
                featureType: "highway",
                elementType: "labels",
                stylers: {
                  visibility: "off"
                }
              },
              {
                featureType: "arterial",
                elementType: "geometry",
                stylers: {
                  color: "#fefefe"
                }
              },
              {
                featureType: "arterial",
                elementType: "geometry.fill",
                stylers: {
                  color: "#fefefe"
                }
              },
              {
                featureType: "poi",
                elementType: "all",
                stylers: {
                  visibility: "off"
                }
              },
              {
                featureType: "green",
                elementType: "all",
                stylers: {
                  visibility: "off"
                }
              },
              {
                featureType: "subway",
                elementType: "all",
                stylers: {
                  visibility: "off"
                }
              },
              {
                featureType: "manmade",
                elementType: "all",
                stylers: {
                  color: "#d1d1d1"
                }
              },
              {
                featureType: "local",
                elementType: "all",
                stylers: {
                  color: "#d1d1d1"
                }
              },
              {
                featureType: "arterial",
                elementType: "labels",
                stylers: {
                  visibility: "off"
                }
              },
              {
                featureType: "boundary",
                elementType: "all",
                stylers: {
                  color: "#fefefe"
                }
              },
              {
                featureType: "building",
                elementType: "all",
                stylers: {
                  color: "#d1d1d1"
                }
              },
              {
                featureType: "label",
                elementType: "labels.text.fill",
                stylers: {
                  color: "#999999"
                }
              }
            ]
          }
        },
        series: [
          {
            type: "effectScatter",
            coordinateSystem: "bmap",
            data: convertData(
              data.sort(function(a, b) {
                return b.value - a.value
              })
            ),
            symbolSize: 20,
            encode: {
              value: 2
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke"
            },
            label: {
              formatter: "{b}",
              position: "right",
              show: true
            },
            itemStyle: {
              shadowBlur: 20,
              shadowColor: "#6A5ACD"
            },
            emphasis: {
              scale: true
            },
            zlevel: 2
          }
        ]
      }

      const chartEle = this.$refs["chinaMap"]
      const myChart = this.$echarts.init(chartEle)
      myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}
</style>
