<template>
  <div>
    <map-drag
      :lat.sync="lat"
      :lng.sync="lng"
      :mapVisible.sync="mapVisible"
      :type="showType"
      @getLocation="getLocation"
    ></map-drag>
    <a-card :body-style="{ padding: '0px' }" :bordered="false">
      <a-form-model
        ref="form"
        :labelCol="{ span: 6 }"
        :model="currentCustomerInfo"
        :rules="customerInfoRules"
        :wrapperCol="{ span: 18 }"
        class="disabled-form"
        labelAlign="right"
        style="height: 800px"
        @submit.native.prevent
      >
        <a-row>
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #0b64f4;">客户基本信息</h3>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="客户名称" prop="customerName">
              <el-autocomplete
                v-model="currentCustomerInfo.customerName"
                :disabled="disableFormItem()"
                :fetch-suggestions="changeFilterCustomerName"
                :trigger-on-focus="false"
                placeholder="请输入客户名称"
                style="width: 100%"
              ></el-autocomplete>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="关键字" prop="keywordID">
              <a-select
                v-model="currentCustomerInfo.keywordID"
                :allowClear="true"
                :disabled="disableFormItem()"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="客户来源关键字"
                show-search
              >
                <a-select-option
                  v-for="item in allKeywordMap"
                  :key="item.keywordID"
                  :value="item.keywordID"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="来源平台" prop="sourcePlatformID">
              <a-select
                v-model="currentCustomerInfo.sourcePlatformID"
                :allowClear="true"
                :disabled="disableFormItem()"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="输入选择来源平台"
                show-search
              >
                <a-select-option
                  v-for="item in allSourcePlatformMap"
                  :key="item.sourcePlatformID"
                  :value="item.sourcePlatformID"
                  >{{ item.platformName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="客户状态" prop="tag">
              <a-select
                v-model="currentCustomerInfo.tag"
                :allowClear="true"
                :disabled="disableFormItem()"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="输入客户状态"
                show-search
              >
                <a-select-option
                  v-for="item in allCustomerTagMap"
                  :key="Number(item.key)"
                  :value="Number(item.key)"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="所属行业" prop="business">
              <a-select
                v-model="currentCustomerInfo.business"
                :allowClear="true"
                :disabled="disableFormItem()"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="输入客户所属行业"
                show-search
              >
                <a-select-option
                  v-for="item in allCustomerBusinessMap"
                  :key="Number(item.key)"
                  :value="Number(item.key)"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8"></a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="客户性质" prop="nature">
              <a-select
                v-model="currentCustomerInfo.nature"
                :allowClear="true"
                :disabled="disableFormItem()"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="输入客户公司性质"
                show-search
              >
                <a-select-option
                  v-for="item in allCustomerNatureMap"
                  :key="Number(item.key)"
                  :value="Number(item.key)"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
            <a-form-model-item label="访问方式" prop="accessType">
              <a-select
                v-model="currentCustomerInfo.accessType"
                :allowClear="true"
                :disabled="disableFormItem()"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="选择客户访问方式"
                show-search
              >
                <a-select-option
                  v-for="item in allCustomerAccessTypeMap"
                  :key="Number(item.key)"
                  :value="Number(item.key)"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #0b64f4;">客户地址信息</h3>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="10">
          <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
            <a-form-model-item label="省" prop="province">
              <a-select
                v-model="currentCustomerInfo.province"
                :allowClear="true"
                :disabled="disableFormItem()"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="请输入省名称"
                show-search
              >
                <a-select-option
                  v-for="item in allProvinceList"
                  :key="item"
                  :value="item"
                  >{{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
            <a-form-model-item label="市" prop="city">
              <a-select
                v-model="currentCustomerInfo.city"
                :allowClear="true"
                :disabled="disableFormItem()"
                :filterOption="filterCategory"
                :not-found-content="null"
                :show-arrow="false"
                placeholder="请输入城市名称"
                show-search
              >
                <a-select-option
                  v-for="item in allCityList"
                  :key="item"
                  :value="item"
                  >{{ item }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :lg="4" :md="24" :sm="24" :xl="4" :xs="24" :xxl="4">
            <a-form-model-item label="区" prop="area">
              <a-input
                v-model="currentCustomerInfo.area"
                :disabled="disableFormItem()"
                placeholder="请输入区名"
              />
            </a-form-model-item>
          </a-col>
          <a-col :lg="10" :md="24" :sm="24" :xl="10" :xs="24" :xxl="10">
            <a-form-model-item
              class="address-form-model-item"
              label="详细地址"
              prop="address"
            >
              <a-input
                v-model="currentCustomerInfo.address"
                :disabled="true"
                placeholder="请选择地图坐标"
              />
            </a-form-model-item>
          </a-col>

          <a-col :lg="2" :md="24" :sm="24" :xl="2" :xs="24" :xxl="2">
            <a-button
              v-if="!disableFormItem()"
              type="primary"
              @click="showMap({}, 'choose')"
              >选择地址
            </a-button>
          </a-col>
        </a-row>

        <a-row>
          <template v-if="BASE_OPERATE_TYPE.ADD === operateType">
            <a-row :gutter="10">
              <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
                <div
                  style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
                >
                  <h3 style="color: #0b64f4;">给客户指派员工</h3>
                </div>
              </a-col>
              <a-col :lg="9" :md="24" :sm="24" :xl="9" :xs="24" :xxl="9">
                <a-form-model-item label="指派员工" prop="userID">
                  <a-select
                    v-model="currentCustomerInfo.userID"
                    :allowClear="true"
                    :disabled="disableFormItem()"
                    :filterOption="filterCategory"
                    :not-found-content="null"
                    :show-arrow="false"
                    placeholder="指派客户给对应员工"
                    show-search
                  >
                    <a-select-option
                      v-for="item in allUserListMap"
                      :key="Number(item.userID)"
                      :value="Number(item.userID)"
                      >{{ item.displayName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :lg="9" :md="24" :sm="24" :xl="9" :xs="24" :xxl="9">
                <a-form-model-item label="员工类型" prop="userType">
                  <a-select
                    v-model="currentCustomerInfo.userType"
                    :allowClear="true"
                    :disabled="disableFormItem()"
                    :filterOption="filterCategory"
                    :not-found-content="null"
                    :show-arrow="false"
                    placeholder="选择跟进员工类型"
                    show-search
                  >
                    <a-select-option
                      v-for="item in allCustomerUserTypeMap"
                      :key="Number(item.key)"
                      :value="Number(item.key)"
                      >{{ item.value }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </a-row>
          </template>
          <!--          <template-->
          <!--            v-if="-->
          <!--              [BASE_OPERATE_TYPE.ADD, BASE_OPERATE_TYPE.EDIT].includes(-->
          <!--                operateType-->
          <!--              )-->
          <!--            "-->
          <!--          >-->
          <!--            <a-row :gutter="10">-->
          <!--              <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">-->
          <!--                <div-->
          <!--                  style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"-->
          <!--                >-->
          <!--                  <h3 style="color: #0b64f4;">给客户指派员工</h3>-->
          <!--                </div>-->
          <!--              </a-col>-->
          <!--              <a-col :lg="9" :md="24" :sm="24" :xl="9" :xs="24" :xxl="9">-->
          <!--                <a-form-model-item label="指派员工" prop="userID">-->
          <!--                  <a-select-->
          <!--                    v-model="currentCustomerInfo.userID"-->
          <!--                    :allowClear="true"-->
          <!--                    :disabled="disableFormItem()"-->
          <!--                    :not-found-content="null"-->
          <!--                    :show-arrow="false"-->
          <!--                    placeholder="指派客户给对应员工"-->
          <!--                    show-search-->
          <!--                  >-->
          <!--                    <a-select-option-->
          <!--                      v-for="item in allUserListMap"-->
          <!--                      :key="Number(item.userID)"-->
          <!--                      :value="Number(item.userID)"-->
          <!--                      >{{ item.displayName }}-->
          <!--                    </a-select-option>-->
          <!--                  </a-select>-->
          <!--                </a-form-model-item>-->
          <!--              </a-col>-->
          <!--              <a-col :lg="9" :md="24" :sm="24" :xl="9" :xs="24" :xxl="9">-->
          <!--                <a-form-model-item label="员工类型" prop="userType">-->
          <!--                  <a-select-->
          <!--                    v-model="currentCustomerInfo.userType"-->
          <!--                    :allowClear="true"-->
          <!--                    :disabled="disableFormItem()"-->
          <!--                    :not-found-content="null"-->
          <!--                    :show-arrow="false"-->
          <!--                    placeholder="选择跟进员工类型"-->
          <!--                    show-search-->
          <!--                  >-->
          <!--                    <a-select-option-->
          <!--                      v-for="item in allCustomerUserTypeMap"-->
          <!--                      :key="Number(item.key)"-->
          <!--                      :value="Number(item.key)"-->
          <!--                      >{{ item.value }}-->
          <!--                    </a-select-option>-->
          <!--                  </a-select>-->
          <!--                </a-form-model-item>-->
          <!--              </a-col>-->
          <!--            </a-row>-->
          <!--          </template>-->
        </a-row>
        <a-row :gutter="10">
          <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
            <div
              style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
            >
              <h3 style="color: #0b64f4;">客户首次接洽</h3>
            </div>
          </a-col>
          <a-col :lg="6" :md="24" :sm="24" :xl="8" :xs="24" :xxl="6">
            <a-form-model-item label="需求评级" prop="approachRate">
              <a-rate
                v-model="currentCustomerInfo.approachRate"
                :disabled="disableFormItem()"
                allow-half
              >
              </a-rate>
            </a-form-model-item>
          </a-col>
          <a-col :lg="16" :md="24" :sm="24" :xl="16" :xs="24" :xxl="16">
            <a-form-model-item label="首次接洽记录" prop="approachNote">
              <a-textarea
                v-model="currentCustomerInfo.approachNote"
                :disabled="disableFormItem()"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="bottom-button-area">
        <a-button
          v-if="operateType == BASE_OPERATE_TYPE.ADD"
          key="submit"
          class="bottom-button"
          type="primary"
          @click="addCustomer"
        >
          提交
        </a-button>
      </div>
      <div class="bottom-button-area">
        <a-button
          v-if="operateType == BASE_OPERATE_TYPE.INFO"
          key="submit"
          class="bottom-button"
          type="primary"
          @click="toEditCustomer"
        >
          编辑
        </a-button>

        <div class="edit">
          <a-button
            v-if="operateType == BASE_OPERATE_TYPE.EDIT"
            key="submit"
            class="bottom-button1"
            @click="toCancelEditCustomer"
          >
            取消
          </a-button>

          <a-button
            v-if="operateType == BASE_OPERATE_TYPE.EDIT"
            key="submit"
            class="bottom-button2"
            type="primary"
            @click="toSubmitEditCustomer"
          >
            提交
          </a-button>
        </div>
      </div>
    </a-card>
  </div>
</template>

<script>
import { fullTimeFilter } from "@/utils/utils"
import { BASE_OPERATE_TYPE } from "@/config"
import {
  GetAllCustomerName,
  GetAllKeyWord,
  GetAllSourcePlatform,
  GetAllUserList,
  GetDictionary
} from "@/api/public-api"
import {
  add,
  edit,
  GetCustomerDetailByCustomerID
} from "@/api/customer/customer"
import mapDrag from "@/components/MapDrag"
import { checkOperateDisable } from "@/router/permission"

export default {
  name: "CustomerInfoTabPane",
  data() {
    const that = this
    const validateCustomer = (rule, value, callback) => {
      if (value.trim() === "") {
        callback(new Error("请输入客户名称"))
      } else if (value.includes(" ")) {
        callback(new Error("客户名称不能包含空格"))
      } else if (
        that.operateType === BASE_OPERATE_TYPE.ADD &&
        that.allCustomerNames.map(item => item.value).includes(value.trim())
      ) {
        callback(new Error("客户名称重复"))
      } else {
        callback()
      }
    }
    return {
      operateType: "",
      customerInfoRules: {
        customerName: [
          { required: true, validator: validateCustomer, trigger: "blur" }
        ],
        keywordID: [
          {
            required: true,
            message: "请选择客户企业搜索关键字",
            trigger: "change"
          }
        ],
        sourcePlatformID: [
          {
            required: true,
            message: "请选择客户企业来源平台",
            trigger: "change"
          }
        ],
        tag: [{ required: true, message: "请选择客户标志", trigger: "change" }],
        business: [
          {
            required: true,
            message: "请选择企业客户所属行业",
            trigger: "change"
          }
        ],
        nature: [
          { required: true, message: "请选择企业客户性质", trigger: "change" }
        ],
        accessType: [
          {
            required: true,
            message: "请选择企业客户访问方式",
            trigger: "change"
          }
        ],
        province: [
          {
            required: true,
            message: "企业客户所属省",
            trigger: "change"
          }
        ],
        city: [
          {
            required: true,
            message: "企业客户所属市",
            trigger: "change"
          }
        ],
        approachRate: [
          { required: true, message: "评估客户企业需求评级", trigger: "change" }
        ],
        approachNote: [
          { required: true, message: "首次接洽客户记录", trigger: "change" }
        ]
      },
      allCustomerNames: [],
      allUserListMap: [],
      allCustomerUserTypeMap: [],
      allProvinceList: require("@/config/province.json").map(item => item.name),
      allCityList: require("@/config/city.json").map(item => item.name),
      allCountyList: require("@/config/county.json").map(item => item.name),
      BASE_OPERATE_TYPE,
      allKeywordMap: [],
      allSourcePlatformMap: [],
      allCustomerBusinessMap: [],
      allCustomerNatureMap: [],
      allCustomerAccessTypeMap: [],
      allCustomerTagMap: [],
      currentCustomerInfo: {},
      currentCustomerID: 0,
      mapVisible: false,
      lng: "",
      lat: "",
      showType: "info"
    }
  },
  props: {},
  components: { mapDrag },
  async created() {
    const [
      getAllUserListRes,
      allCustomerNameRes,
      allKeywordRes,
      allSourcePlatformRes,
      getCustomerUserTypeRes,
      getAllCustomerBusinessRes,
      getAllCustomerNatureRes,
      getAllCustomerAccessTypeRes,
      getCustomerTagRes
    ] = await Promise.all([
      GetAllUserList(),
      GetAllCustomerName(),
      GetAllKeyWord(),
      GetAllSourcePlatform(),
      GetDictionary({ typeName: "customerUserType" }),
      GetDictionary({ typeName: "customerBusiness" }),
      GetDictionary({ typeName: "customerNature" }),
      GetDictionary({ typeName: "customerAccessType" }),
      GetDictionary({ typeName: "customerTag" }),
      GetDictionary({ typeName: "contactType" })
    ])

    if (getAllUserListRes.code === 0) {
      this.allUserListMap = [
        { userID: 0, displayName: "暂不指定" },
        ...getAllUserListRes.result
      ]
    }

    if (getCustomerUserTypeRes.code === 0) {
      this.allCustomerUserTypeMap = [
        { key: 0, value: "无" },
        ...getCustomerUserTypeRes.result
      ]
    }

    if (allCustomerNameRes.code === 0) {
      this.allCustomerNames = [
        ...allCustomerNameRes.result.map(item => ({ value: item }))
      ]
    }

    if (allKeywordRes.code === 0) {
      this.allKeywordMap = allKeywordRes.result
    }
    if (allSourcePlatformRes.code === 0) {
      this.allSourcePlatformMap = allSourcePlatformRes.result
    }
    if (getAllCustomerBusinessRes.code === 0) {
      this.allCustomerBusinessMap = getAllCustomerBusinessRes.result
    }
    if (getAllCustomerNatureRes.code === 0) {
      this.allCustomerNatureMap = getAllCustomerNatureRes.result
    }
    if (getAllCustomerAccessTypeRes.code === 0) {
      this.allCustomerAccessTypeMap = getAllCustomerAccessTypeRes.result
    }
    if (getCustomerTagRes.code === 0) {
      this.allCustomerTagMap = getCustomerTagRes.result
    }
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    checkOperateDisable,
    changeFilterCustomerName(input, cb) {
      const that = this

      const result = [...that.allCustomerNames].filter(
        item =>
          input === "" || item.value.toUpperCase().includes(input.toUpperCase())
      )
      cb(result)
    },
    disableFormItem() {
      const that = this
      return that.operateType == BASE_OPERATE_TYPE.INFO
    },
    async showData(customerID, operateType = BASE_OPERATE_TYPE.INFO) {
      const that = this
      if (that.operateType !== BASE_OPERATE_TYPE.EDIT) {
        that.operateType = operateType
        if (BASE_OPERATE_TYPE.INFO === operateType && customerID) {
          const customerDetailRes = await GetCustomerDetailByCustomerID(
            customerID
          )
          if (customerDetailRes.code === 0) {
            that.currentCustomerInfo = { ...customerDetailRes.result }
            that.currentCustomerID = customerID
            that.$emit("update-customer-info", { ...customerDetailRes.result })
          }
        } else if (BASE_OPERATE_TYPE.ADD == operateType) {
          that.currentCustomerInfo = {}
          that.$parent.currentCustomerInfo = {}
          that.currentCustomerID = 0
        }
      }
    },

    async addCustomer() {
      const that = this
      that.$refs["form"].validate(async valid => {
        if (!valid) {
          return
        }
        const addRes = await add(this.currentCustomerInfo)
        if (addRes.code === 12 && addRes.result.customerID) {
          that.currentCustomerInfo.customerID = addRes.result.customerID
          that.$emit("update-customer-info", { ...that.currentCustomerInfo })
          that.$emit("update-customer-operate-type", BASE_OPERATE_TYPE.INFO)
          that.operateType = BASE_OPERATE_TYPE.INFO
          that.$forceUpdate()

          if (checkOperateDisable("查看客户详情")) {
            that.$emit("close-drawer")
          }
        }
      })
    },
    toSubmitEditCustomer() {
      const that = this
      that.$refs["form"].validate(async valid => {
        if (!valid) {
          return
        }
        const updateRes = await edit({ ...that.currentCustomerInfo })
        if (updateRes.code === 14) {
          that.operateType = BASE_OPERATE_TYPE.INFO
          that.showData(that.currentCustomerID)
          that.$emit("update-customer-info", { ...that.currentCustomerInfo })
          that.$emit("update-customer-operate-type", BASE_OPERATE_TYPE.INFO)
        } else {
          that.$message.error("更新数据失败")
        }
      })
    },
    toEditCustomer() {
      this.operateType = BASE_OPERATE_TYPE.EDIT
    },
    toCancelEditCustomer() {
      this.operateType = BASE_OPERATE_TYPE.INFO
      this.showData(this.currentCustomerID)
      this.$emit("update-customer-operate-type", BASE_OPERATE_TYPE.INFO)
    },

    onCloseCustomerModal() {
      this.$refs["form"].clearValidate()
      this.operateType = ""
      this.currentCustomerID = 0
      this.currentCustomerInfo = {}
    },
    getLocation(res) {
      if (this.showType !== "info") {
        if (res.status === "confirm") {
          this.currentCustomerInfo.longitude = res.data.lng
          this.currentCustomerInfo.latitude = res.data.lat
          this.currentCustomerInfo.address = res.data.address
          this.currentCustomerInfo.province = res.data.position.provinceName
          this.currentCustomerInfo.city = res.data.position.cityName
          this.currentCustomerInfo.area = res.data.position.areaName
          this.$forceUpdate()
        }
      }
    },
    showMap(it, type = "info") {
      if (type === "info") {
        this.lat = String(it.latitude)
        this.lng = String(it.longitude)
      }
      this.mapVisible = true
      this.showType = type
    }
  }
}
</script>

<style lang="scss" scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}

.bottom-button-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* 水平居中 */

  .bottom-button {
    width: 200px;
    line-height: 30px;
    height: 30px;
  }

  .edit {
    width: 500px;
    height: 40px;

    .bottom-button1 {
      display: inline-block;
      float: left;
      width: 200px;
      line-height: 30px;
      height: 30px;
      margin-right: 100px;
    }

    .bottom-button2 {
      display: inline-block;
      float: left;
      width: 200px;
      line-height: 30px;
      height: 30px;
    }
  }
}
</style>
