<template>
  <el-dialog
    :visible.sync="visibleDialog"
    append-to-body
    class="promoter_person"
    title="选择成员"
    width="600px"
  >
    <div class="person_body clear">
      <div class="person_tree l">
        <!--        <input-->
        <!--          v-model="searchVal"-->
        <!--          placeholder="搜索成员"-->
        <!--          type="text"-->
        <!--          @change="getAllUserList($event)"-->
        <!--        />-->
        <el-select
          v-model="searchVal"
          :allowClear="true"
          filterable
          placeholder="搜索员工"
          style="width: 100%"
          @change="selectUser(searchVal)"
        >
          <el-option
            v-for="item in allUserList"
            :key="item.userID"
            :label="item.displayName"
            :value="item.userID"
          >
          </el-option>
        </el-select>
        <p class="ellipsis tree_nav" style="margin-top: 10px">
          <span class="ellipsis" @click="getDepartmentList(0)">首页</span>
          <img
            v-if="roleIDArr.length > 1"
            src="@/assets/image/pre_level_active.png"
            style="float: right"
            @click="getLastDeparmentList()"
          />
        </p>
        <selectBox :currentRoleID="currentRoleID" :list="list()" />
      </div>
      <selectResult :list="resList()" :total="total()" @del="delList" />
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="$emit('update:visible', false)">取 消</el-button>
      <el-button type="primary" @click="saveDialog">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import selectBox from "../selectBox.vue"
import selectResult from "../selectResult.vue"
import mixins from "./mixins"
import { GetAllUserList } from "@/api/public-api"
import { getDepartments } from "@/api/approval/api"

export default {
  components: { selectBox, selectResult },
  mixins: [mixins],
  props: ["visible", "data", "isDepartment"],
  watch: {
    async visible(val) {
      this.visibleDialog = this.visible
      // if (val) {
      //   this.searchVal = ""
      // this.departments.employees = await this.getAllUserList()
      // console.log(
      //   `[watch visible] this.departments.employees : ${JSON.stringify(
      //     this.departments.employees
      //   )}`
      // )
      // console.log(`[watch visible] data : ${JSON.stringify(this.data)}`)
      // this.checkedEmployessList = this.data.map(({ name, targetId }) => ({
      //   employeeName: name,
      //   id: targetId
      // }))
      // this.$forceUpdate()
      // }
      if (val) {
        await this.getDepartmentList()
        this.searchVal = ""
        this.checkedEmployessList = this.data
          .filter(item => item.type === 1)
          .map(({ name, targetId }) => ({
            employeeName: name,
            id: targetId
          }))
        this.checkedDepartmentList = this.data
          .filter(item => item.type === 3)
          .map(({ name, targetId }) => ({
            departmentName: name,
            id: targetId
          }))
      }
    },
    visibleDialog(val) {
      this.$emit("update:visible", val)
    }
  },
  data() {
    return {
      checkedEmployessList: [],
      checkedDepartmentList: [],
      allUserList: [],
      currentRoleID: 0,
      roleIDArr: []
    }
  },
  async created() {
    let res = await GetAllUserList()
    if (res.code === 0) {
      this.allUserList = res.result
    }
  },
  methods: {
    filterItem: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    saveDialog() {
      let checkedList = [
        ...this.checkedDepartmentList,
        ...this.checkedEmployessList
      ].map(item => ({
        type: item.employeeName ? 1 : 3,
        targetId: item.id,
        name: item.employeeName || item.departmentName
      }))
      this.$emit("change", checkedList)
    },
    delList() {
      this.checkedDepartmentList = []
      this.checkedEmployessList = []
    },
    async getAllUserList(event) {
      const searchName = event ? (event.target ? event.target.value : "") : ""
      this.departments.childDepartments = []
      this.departments.employees = this.allUserList
        .filter(item => {
          if (!searchName) {
            return true
          }
          return item.displayName
            .toUpperCase()
            .includes(searchName.toUpperCase())
        })
        .map(item => ({
          employeeName: item.displayName,
          id: item.userID
        }))
      return this.departments.employees
    },
    async selectUser(searchVal) {
      if (searchVal) {
        this.checkedEmployessList = this.allUserList
          .filter(item => item.userID == searchVal)
          .map(item => ({
            employeeName: item.displayName,
            id: item.userID
          }))
        this.$forceUpdate()
      }
    },
    total() {
      return this.checkedEmployessList.length
    },
    list() {
      const that = this
      return [
        {
          isDepartment: that.isDepartment,
          type: "department",
          data: that.departments.childDepartments,
          isActive: item =>
            that.$func.toggleClass(that.checkedDepartmentList, item),
          // change: item =>
          //   this.$func.toChecked(this.checkedDepartmentList, item),
          next: id => that.getDepartmentList(id)
        },
        {
          type: "employee",
          data: that.departments.employees,
          isActive: item =>
            that.$func.toggleClass(that.checkedEmployessList, item),
          change: item => that.$func.toChecked(that.checkedEmployessList, item)
        }
      ]
    },
    resList() {
      let data = [
        {
          type: "employee",
          data: this.checkedEmployessList,
          cancel: item => this.$func.removeEle(this.checkedEmployessList, item)
        }
      ]
      if (this.isDepartment) {
        data.unshift({
          type: "department",
          data: this.checkedDepartmentList,
          cancel: item => this.$func.removeEle(this.checkedDepartmentList, item)
        })
      }
      return data
    },
    async getDepartmentList(parentId = 0) {
      console.log(`----  getDepartmentList   parentID:${parentId}`)
      if (parentId == 0) {
        this.roleIDArr = [0]
      } else {
        this.roleIDArr.push(parentId)
      }
      this.currentRoleID = parentId
      let { result } = await getDepartments({ parentId })
      this.departments = { ...result }
      this.$forceUpdate()
    },
    async getLastDeparmentList() {
      const lastParentID = this.roleIDArr[this.roleIDArr.length - 2]
      this.roleIDArr = this.roleIDArr.slice(0, -1)
      console.log(`[getLastDeparmentList] lastParentID : ${lastParentID}`)
      let { result } = await getDepartments({ parentId: lastParentID })
      console.log(`[getLastDeparmentList] result : ${JSON.stringify(result)}`)
      this.departments = result
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
.person_body {
  border: 1px solid #f5f5f5;
  height: 500px;
}

.tree_nav span {
  display: inline-block;
  padding-right: 10px;
  margin-right: 5px;
  max-width: 6em;
  color: #38adff;
  font-size: 12px;
  cursor: pointer;
  background: url(~@/assets/image/jiaojiao.png) no-repeat right center;
}

.tree_nav span:last-of-type {
  background: none;
}

.person_tree {
  float: left;
  padding: 10px 12px 0 8px;
  width: 280px;
  height: 100%;
  border-right: 1px solid #f5f5f5;
}

.person_tree input {
  padding-left: 22px;
  width: 210px;
  height: 30px;
  font-size: 12px;
  border-radius: 2px;
  border: 1px solid #d5dadf;
  background: url(~@/assets/image/list_search.png) no-repeat 10px center;
  background-size: 14px 14px;
  margin-bottom: 14px;
}

.person_tree img {
  width: 14px;
  vertical-align: middle;
  margin-right: 5px;
}
</style>
