export const addApprovalConfig = {
  tableId: 1,
  workFlowDef: {
    name: ""
  },
  directorMaxLevel: 4,
  flowPermission: [],
  nodeConfig: {
    nodeName: "发起人",
    type: 0,
    priorityLevel: "",
    settype: "",
    selectMode: "",
    selectRange: "",
    directorLevel: "",
    examineMode: "",
    noHanderAction: "",
    examineEndDirectorLevel: "",
    ccSelfSelectFlag: "",
    conditionList: [],
    nodeUserList: [],
    childNode: {
      nodeName: "审核人",
      error: false,
      type: 1,
      settype: 1,
      selectMode: 0,
      selectRange: 0,
      directorLevel: 1,
      examineMode: 1,
      noHanderAction: 2,
      examineEndDirectorLevel: 0,
      childNode: {},
      nodeUserList: []
    },
    conditionNodes: []
  }
}

export const demoConfig = {
  tableId: 1,
  workFlowDef: {
    name: "合同审批"
  },
  directorMaxLevel: 4,
  flowPermission: [],
  nodeConfig: {
    nodeName: "发起人",
    type: 0,
    priorityLevel: "",
    settype: "",
    selectMode: "",
    selectRange: "",
    directorLevel: "",
    examineMode: "",
    noHanderAction: "",
    examineEndDirectorLevel: "",
    ccSelfSelectFlag: "",
    conditionList: [],
    nodeUserList: [],
    childNode: {
      nodeName: "审核人",
      error: false,
      type: 1,
      settype: 1,
      selectMode: 0,
      selectRange: 0,
      directorLevel: 1,
      examineMode: 1,
      noHanderAction: 2,
      examineEndDirectorLevel: 0,
      childNode: {
        nodeName: "路由",
        type: 4,
        priorityLevel: 1,
        settype: 1,
        selectMode: 0,
        selectRange: 0,
        directorLevel: 1,
        examineMode: 1,
        noHanderAction: 2,
        examineEndDirectorLevel: 1,
        ccSelfSelectFlag: 1,
        conditionList: [],
        nodeUserList: [],
        childNode: {
          nodeName: "抄送人",
          type: 2,
          ccSelfSelectFlag: 1,
          childNode: null,
          nodeUserList: [],
          error: false
        },
        conditionNodes: [
          {
            nodeName: "条件1",
            type: 3,
            priorityLevel: 1,
            settype: 1,
            selectMode: 0,
            selectRange: 0,
            directorLevel: 1,
            examineMode: 1,
            noHanderAction: 2,
            examineEndDirectorLevel: 1,
            ccSelfSelectFlag: 1,
            conditionList: [
              {
                columnId: 0,
                type: 1,
                conditionEn: "",
                conditionCn: "",
                optType: "",
                zdy1: "",
                zdy2: "",
                opt1: "",
                opt2: "",
                columnDbname: "",
                columnType: "",
                showType: "",
                showName: "",
                fixedDownBoxValue: ""
              }
            ],
            nodeUserList: [
              {
                targetId: 85,
                type: 1,
                name: "天旭"
              }
            ],
            childNode: {
              nodeName: "审核人",
              type: 1,
              priorityLevel: 1,
              settype: 1,
              selectMode: 0,
              selectRange: 0,
              directorLevel: 1,
              examineMode: 1,
              noHanderAction: 2,
              examineEndDirectorLevel: 1,
              ccSelfSelectFlag: 1,
              conditionList: [],
              nodeUserList: [
                {
                  targetId: 2515744,
                  type: 1,
                  name: "哈哈哈哈"
                }
              ],
              childNode: null,
              conditionNodes: [],
              error: false
            },
            conditionNodes: [],
            error: false
          },
          {
            nodeName: "条件2",
            type: 3,
            priorityLevel: 2,
            settype: 1,
            selectMode: 0,
            selectRange: 0,
            directorLevel: 1,
            examineMode: 1,
            noHanderAction: 2,
            examineEndDirectorLevel: 1,
            ccSelfSelectFlag: 1,
            conditionList: [],
            nodeUserList: [],
            childNode: null,
            conditionNodes: [],
            error: false
          }
        ]
      },
      nodeUserList: []
    },
    conditionNodes: []
  }
}
