import echarts from "echarts"
import "echarts/extension/bmap/bmap"
import { shuffle } from "@/utils/utils"
import { PieChartColor } from "@/config"

export function drawGeneralStatisticsChart(ele, data) {
  const option = {
    color: shuffle(PieChartColor),
    tooltip: {
      trigger: "item"
    },
    grid: {
      height: '100%'
    },
    legend: {
      top: "1%",
      left: "center",
    },
    series: [
      {
        name: "数量占比",
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "45%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)"
        },
        label: {
          show: false,
          position: "center"
        },
        emphasis: {
          label: {
            show: true,
            fontSize: "30",
            fontWeight: "bold"
          }
        },
        labelLine: {
          show: false
        },
        data: data
      }
    ]
  }
  console.log(this)
  const myChart = this.$echarts.init(this.$refs[ele])
  myChart.setOption(option)
}

export function drawColumnsAndTrendsChart(ele, data) {
  const that = this
  const option = {
    tooltip: {
      trigger: "axis"
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    backgroundColor: "#fff",
    grid: {
      top: "10%",
      right: "10%",
      left: "10%",
      bottom: "10%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        color: "#AFAFAF",
        data: [],
        axisLabel: {
          // formatter: "{value}月",
          margin: 20,
          color: "#DADADA",
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          lineStyle: {
            color: "rgba(173,183,255,1)"
          }
        },
        axisTick: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLabel: {
          // formatter: "{value}",
          color: "#AFAFAF",
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          lineStyle: {
            color: "rgba(107,107,107,0.37)"
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          lineStyle: {
            color: "rgba(131,101,101,0.2)",
            type: "dashed"
          }
        }
      }
    ],
    series: [
      {
        type: "bar",
        data: [],
        barWidth: "20px",
        itemStyle: {
          normal: {
            color: function() {
              //展示正值的柱子，负数设为透明
              let colorArr = ["#C3E8FF", "#112AF8"]
              return new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: colorArr[0] // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: colorArr[1] // 100% 处的颜色
                  }
                ],
                false
              )
            },
            barBorderRadius: [30, 30, 0, 0]
          }
        },
        label: {
          normal: {
            show: true,
            fontSize: 12,
            color: "#AFAFAF",
            position: "top"
          }
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "",
        symbol: "none",
        lineStyle: {
          color: "#ADB7FF",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      }
    ]
  }

  option.xAxis[0].data = data.map(item => item.dateNo)
  option.series[0].data = data.map(item => item.count)
  option.series[1].data = data.map(item => item.count)
  const myChart = that.$echarts.init(this.$refs[ele])
  myChart.setOption(option)
}

// export function drawDoubleColumnsAndTrendsChart(ele, data) {
//   const that = this
//   const option = {
//     tooltip: {
//       trigger: "axis"
//     },
//     toolbox: {
//       feature: {
//         saveAsImage: {}
//       }
//     },
//     backgroundColor: "#fff",
//     grid: {
//       top: "10%",
//       right: "10%",
//       left: "10%",
//       bottom: "10%",
//       containLabel: true
//     },
//     xAxis: [
//       {
//         type: "category",
//         color: "#AFAFAF",
//         data: [],
//         axisLabel: {
//           // formatter: "{value}月",
//           margin: 20,
//           color: "#DADADA",
//           textStyle: {
//             fontSize: 12
//           }
//         },
//         axisLine: {
//           lineStyle: {
//             color: "rgba(173,183,255,1)"
//           }
//         },
//         axisTick: {
//           show: false
//         }
//       }
//     ],
//     yAxis: [
//       {
//         axisLabel: {
//           // formatter: "{value}",
//           color: "#AFAFAF",
//           textStyle: {
//             fontSize: 12
//           }
//         },
//         axisLine: {
//           lineStyle: {
//             color: "rgba(107,107,107,0.37)"
//           }
//         },
//         axisTick: {
//           show: false
//         },
//         splitLine: {
//           lineStyle: {
//             color: "rgba(131,101,101,0.2)",
//             type: "dashed"
//           }
//         }
//       }
//     ],
//     series: [
//       {
//         type: "bar",
//         data: [],
//         barWidth: "20px",
//         itemStyle: {
//           normal: {
//             color: function() {
//               //展示正值的柱子，负数设为透明
//               let colorArr = ["#C3E8FF", "#112AF8"]
//               return new echarts.graphic.LinearGradient(
//                 0,
//                 0,
//                 0,
//                 1,
//                 [
//                   {
//                     offset: 0,
//                     color: colorArr[0] // 0% 处的颜色
//                   },
//                   {
//                     offset: 1,
//                     color: colorArr[1] // 100% 处的颜色
//                   }
//                 ],
//                 false
//               )
//             },
//             barBorderRadius: [30, 30, 0, 0]
//           }
//         },
//         label: {
//           normal: {
//             show: true,
//             fontSize: 12,
//             color: "#AFAFAF",
//             position: "top"
//           }
//         }
//       },
//       {
//         data: [],
//         type: "line",
//         smooth: true,
//         name: "合同数",
//         symbol: "none",
//         lineStyle: {
//           color: "#ADB7FF",
//           width: 4,
//           shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
//           shadowBlur: 15,
//           shadowOffsetY: 20
//         }
//       },
//       {
//         type: "bar",
//         data: [],
//         barWidth: "20px",
//         itemStyle: {
//           normal: {
//             color: function() {
//               //展示正值的柱子，负数设为透明
//               let colorArr = ["#e1630a", "#ef9e08"]
//               return new echarts.graphic.LinearGradient(
//                 0,
//                 0,
//                 0,
//                 1,
//                 [
//                   {
//                     offset: 0,
//                     color: colorArr[0] // 0% 处的颜色
//                   },
//                   {
//                     offset: 1,
//                     color: colorArr[1] // 100% 处的颜色
//                   }
//                 ],
//                 false
//               )
//             },
//             barBorderRadius: [30, 30, 0, 0]
//           }
//         },
//         label: {
//           normal: {
//             show: true,
//             fontSize: 12,
//             color: "#AFAFAF",
//             position: "top"
//           }
//         }
//       },
//       {
//         data: [],
//         type: "line",
//         smooth: true,
//         name: "合同金额",
//         symbol: "none",
//         lineStyle: {
//           color: "#e87808",
//           width: 4,
//           shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
//           shadowBlur: 15,
//           shadowOffsetY: 20
//         }
//       }
//     ]
//   }
//
//   option.xAxis[0].data = data.map(item => item.dateNo)
//   option.series[0].data = data.map(item => item.count)
//   option.series[1].data = data.map(item => item.count)
//   option.series[2].data = data.map(item => item.totalPrice)
//   option.series[3].data = data.map(item => item.totalPrice)
//   const myChart = that.$echarts.init(this.$refs[ele])
//   myChart.setOption(option)
// }

export function drawColumnsChart(ele, data, legendData, seriesName) {
  const that = this
  const option = {
    tooltip: {
      trigger: "axis"
    },
    grid: {
      top: 30,
      right: 5,
      left: 5,
      bottom: 40,
      containLabel: true
    },
    legend: {
      data: [...legendData]
    },
    toolbox: {
      show: false
    },
    calculable: true,
    xAxis: [
      {
        type: "category",
        axisLabel: {
          rotate: 45
        },
        data: data.xAxis
      }
    ],
    yAxis: [
      {
        type: "value"
      }
    ],
    series: [
      {
        name: seriesName,
        type: "bar",
        data: data.series,
        markPoint: {
          data: data.markPoint
        },
        markLine: {
          data: [{ type: "average", name: "平均值" }]
        },
        barWidth: 30,
        itemStyle: {
          normal: {
            color: function() {
              //展示正值的柱子，负数设为透明
              let colorArr = ["#C3E8FF", "#112AF8"]
              return new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: colorArr[0] // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: colorArr[1] // 100% 处的颜色
                  }
                ],
                false
              )
            },
            barBorderRadius: [30, 30, 0, 0]
          }
        }
      }
    ],
    axisLabel: {
      interval: Math.ceil((data.xAxis || []).length / 24),
      show: true,
      textStyle: {
        color: "#a9a9a9", //更改坐标轴文字颜色
        fontSize: 10 //更改坐标轴文字大小
      },
      rotate: 40,
      formatter: function(v) {
        return v
      },
      showMinLabel: true, //显示最小值
      showMaxLabel: true //显示最大值
    }
  }
  const myChart = that.$echarts.init(this.$refs[ele])
  myChart.setOption(option)
}

export function drawFunnelChartTree(ele, data) {
  const that = this
  const option = {
    tooltip: {},
    toolbox: {
      feature: {
        dataView: { readOnly: false },
        restore: {},
        saveAsImage: {}
      }
    },
    legend: {},
    series: [
      {
        type: "funnel",
        left: "10%",
        top: 60,
        bottom: 60,
        width: "80%",
        min: 0,
        max: 100,
        minSize: "0%",
        maxSize: "100%",
        sort: "ascending",
        gap: 2,
        label: {
          show: true,
          position: "inside"
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: "solid"
          }
        },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 1
        },
        emphasis: {
          label: {
            fontSize: 20
          }
        },
        data
      }
    ]
  }
  const myChart = that.$echarts.init(this.$refs[ele])
  myChart.setOption(option)
}
