export const InvoiceListColumns = [
  {
    title: "公司名称",
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "customerName",
    // key: "customerName"
    // scopedSlots: { customRender: "customerName" }
  },
  {
    title: "联系人",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contactName",
    // scopedSlots: { customRender: "contactName" },
    key: "contactName"
  },
  {
    title: "联系人电话",
    align: "center",
    dataIndex:"contactTel",
  },
  {
    title: "创建人",
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creatorName",
  },
  {
    title: "发票金额",
    align: "center",
    key: "invoiceMoney",
    // dataIndex: "invoiceMoney",
    scopedSlots: { customRender: "invoiceMoney" },
  },
  {
    title: "发票时间",
    align: "center",
    key: "invoiceTime",
    scopedSlots: { customRender: "invoiceTime" },
  },
  {
    title: "产品编号",
    align: "center",
    dataIndex: "projectNo",
    key: "projectNo"
  },
  {
    title: "合同编号",
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "contractNo",
    key: "contractNo"
  },
  {
    title: "操作",
    align: "center",
    key: "operation",
    scopedSlots: { customRender: "operation"}
  }
]