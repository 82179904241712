<template>
  <div class="app-container">
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="3" :sm="24">
            <a-form-item label="操作模块">
              <a-select
                v-model="crud.query.domainName"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择分类"
                show-search
              >
                <a-select-option
                  v-for="item in [...domainNames]"
                  :key="item"
                  :value="item"
                  >{{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="功能模块">
              <a-select
                v-model="crud.query.functionName"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择分类"
                show-search
              >
                <a-select-option
                  v-for="item in [...functionNames]"
                  :key="item"
                  :value="item"
                  >{{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="操作类型">
              <a-select
                v-model="crud.query.operateType"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择分类"
                show-search
              >
                <a-select-option
                  v-for="item in [...operateTypeNames]"
                  :key="item"
                  :value="item"
                  >{{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="3" :sm="24">
            <a-form-item label="操作人">
              <a-select
                v-model="crud.query.userID"
                :allowClear="true"
                :filterOption="filterCategory"
                placeholder="请选择分类"
                show-search
              >
                <a-select-option
                  v-for="item in [...allUserListMap]"
                  :key="item.userID"
                  :value="item.userID"
                  >{{ item.displayName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-form-item label="操作时间">
              <a-range-picker
                v-model="crud.query.operateTime"
                :allowClear="true"
                :show-time="showTime"
                allow-clear
                format="YYYY-MM-DD HH:mm:ss"
              />
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <SearchOptions :queryFuncName="queryFuncName" style="width: 100%" />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="日志" />
      <a-spin :spinning="crud.loading">
        <a-table
          :bordered="true"
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-class-name="
            (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
          "
          :scroll="{ x: 3000 }"
          class="ant-table-striped"
          empty-text="-"
          rowKey="logID"
          @resizeColumn="
            (w, col) => {
              col.width = w
            }
          "
        >
          <template slot="operateTime" slot-scope="record">
            {{ filterTime(record.operateTime) }}
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <!-- 填写表单部分 -->
    <div class="draw-container">
      <a-modal
        :afterClose="
          () => {
            form = {}
            crud.refresh()
          }
        "
        :title="crud.status.title"
        :visible="crud.status.cu > 0 || crud.status.in > 0"
        wrapClassName="stationListDialog"
        @cancel="crud.cancelCU()"
      >
        <a-form-model
          ref="form"
          :class="
            crud.status.in > 0 && crud.status.cu === 0 ? 'disabled-form' : ''
          "
          :label-col="{ span: 6 }"
          :model="form"
          :rules="rules"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-model-item label="关键字" prop="value">
            <a-input
              v-model="form.value"
              :disabled="crud.status.in > 0 && crud.status.cu === 0"
            />
          </a-form-model-item>

          <a-form-model-item
            v-if="form.creatorID && form.creatorID > 0"
            label="创建者"
            prop="creator"
          >
            <a-input v-model="form.creator" :disabled="true" />
          </a-form-model-item>
          <a-form-model-item
            v-if="form.creatorID && form.creatorID > 0"
            label="最近修改日期"
            prop="updateTime"
          >
            {{ filterTime(form.updateTime) }}
          </a-form-model-item>
          <a-form-model-item
            v-if="form.creatorID && form.creatorID > 0"
            label="创建日期"
            prop="insertTime"
          >
            {{ filterTime(form.insertTime) }}
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <div v-if="crud.status.cu > 0">
            <a-button
              v-if="crud.status.add > 0"
              key="back"
              @click="crud.cancelCU()"
            >
              取消
            </a-button>
            <a-button
              v-if="crud.status.edit > 0"
              key="back"
              @click="crud.cancelCU('uptoinfo')"
            >
              取消
            </a-button>
            <a-button
              v-if="crud.status.add > 0"
              key="submit"
              type="primary"
              @click="crud.submitCU()"
            >
              提交
            </a-button>
            <a-button
              v-if="crud.status.edit > 0"
              key="submit"
              type="primary"
              @click="editSaveKeyword"
            >
              保存
            </a-button>
          </div>
          <div v-if="crud.status.in > 0 && crud.status.cu === 0">
            <a-button
              key="back"
              :disabled="!crud.permission.edit"
              @click="crud.toEdit()"
            >
              修改
            </a-button>
            <a-popconfirm
              cancel-text="取消"
              ok-text="删除"
              placement="topRight"
              @confirm="crud.doDelete(null, 'close')"
            >
              <template slot="title">
                你确定要删除吗？
              </template>
              <a-button
                key="submit"
                :disabled="!crud.permission.del"
                type="danger"
              >
                删除
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { form, header, presenter } from "@crud/crud"
import Api, { GetFilterPattern } from "@/api/log/index"
import { fullTimeFilter } from "@/utils/utils"
import store from "@/store"
import { GetAllUserList } from "@/api/public-api"
import { LogListColumns } from "@/views/log/list"
import { LogDomainArr, LogFunctionArr, LogOperateType } from "@/config"
import { checkOperateDisable } from "@/router/permission"
import dayjs from "dayjs"

// 表格的填充字段
const defaultForm = {}
export default {
  name: "log",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "日志",
      roleIDs: [],
      columns: LogListColumns,
      crudMethod: { ...Api },
      idField: "keywordID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      allUserListMap: [],
      LogDomainArr,
      LogFunctionArr,
      LogOperateType,
      queryFuncName: "查询日志列表",
      domainNames: [],
      functionNames: [],
      operateTypeNames: [],
      rules: {
        value: [{ required: true, message: "请输入关键字", trigger: "blur" }]
      }
    }
  },
  components: { SearchOptions, Operations, Pagination },
  async created() {
    const that = this
    /**
     * 设置按钮权限
     */
    // 关闭下载功能
    // this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: false,
      del: false,
      edit: false
      // download: true
    }

    this.addOk = false

    const [getAllUserListRes, getFilterPatternRes] = await Promise.all([
      GetAllUserList(),
      GetFilterPattern()
    ])

    if (getAllUserListRes.code === 0) {
      this.allUserListMap = [...getAllUserListRes.result]
    }

    if (getFilterPatternRes.code === 0) {
      that.domainNames = getFilterPatternRes.result.domainName
      that.functionNames = getFilterPatternRes.result.functionName
      that.operateTypeNames = getFilterPatternRes.result.operateTypeName
    }

    console.log(`$route.meta.code : ${this.$route.meta.code}`)

    this.roleIDs = store.getters.roleIDs
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    checkOperateDisable,
    showTime() {
      return { defaultValue: dayjs("00:00:00", "HH:mm:ss") }
    },
    startTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.startTimes = [time1, time1]
          return
        }
      }
      this.crud.query.startTimes = dateStrings
    },
    toInfo(record) {
      this.crud.toInfo(record)
      if (record.users && record.users.length > 0) {
        record.userID = record.users[0].userID
        record.userType = record.users[0].userType
      }

      this.form = JSON.parse(JSON.stringify(record))
      this.currentInfo = JSON.parse(JSON.stringify(record))
      this.activeKey = 1
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    async editSaveKeyword() {
      const res = await Api.edit({
        keywordID: this.form.keywordID,
        value: this.form.value
      })
      if (res.code === 14) {
        this.$message.success("更改成功")
        this.crud.cancelCU("uptoinfo")
      }
    }
  }
}
</script>

<style scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}
</style>
