import message from "ant-design-vue/es/message"
import themeColor from "./themeColor.js"

const colorList = [
  {
    key: "薄暮",
    color: "#F5222D"
  },
  {
    key: "火山(默认)",
    color: "#FA541C"
  },
  {
    key: "日暮",
    color: "#FAAD14"
  },
  {
    key: "明青",
    color: "#13C2C2"
  },
  {
    key: "极光绿",
    color: "#52C41A"
  },
  {
    key: "拂晓蓝",
    color: "#1890FF"
  },
  {
    key: "极客蓝",
    color: "#2F54EB"
  },
  {
    key: "酱紫",
    color: "#722ED1"
  }
]

const updateTheme = newPrimaryColor => {
  const hideMessage = message.loading("正在切换主题！", 0)
  themeColor.changeColor(newPrimaryColor).finally(() => {
    setTimeout(() => {
      hideMessage()
    }, 10)
  })
}

const PageSetting = {
  headerHeight: 64, // Header高度
  collapsedWidth: 80, // Slider折叠后宽度
  sliderWidth: 200, // Slider展开后宽度
  primaryColor: "#1890FF", // 默认主题色
  pageStyle: "light", // 页面风格
  NavigationMode: "fixed" // Header、Slider Fixed
}

const defaultPassWord = "123456"

const GET_ROLE_PROMISSION_FUN = Object.freeze({
  ADD: "add",
  LIST: "list"
})

const GET_ROLE_PROMISSION_TYPE = Object.freeze({
  ALL: "all",
  SINGLE: "single"
})

const ADMIN_ROLE_ID = 1
const DRIVER_ROLE_ID = 2
const DEFAULT_USER_TYPE = 1
const DEFAULT_GENDER = 3

const GRENDER_MAP = Object.freeze({
  男: 1,
  女: 2,
  未知: 3
})

const CHECK_TYPE = [
  { key: 1, value: "通过" },
  { key: 2, value: "驳回" }
]

const UNIT_TYPE = Object.freeze({
  1: "内部",
  2: "外部"
})

const UNIT_TYPE_MAP = [
  { key: 0, value: "全部" },
  {
    key: 1,
    value: "内部"
  },
  {
    key: 2,
    value: "外部"
  }
]

const ORDER_TYPE = Object.freeze({
  1: "采购订单",
  2: "销售订单",
  3: "直发/倒短"
})

const TRUCK_ORDER_TYPE = Object.freeze({
  1: "采购订单",
  2: "销售订单",
  3: "直发/倒短"
})

const ORDER_TYPE_MAP = [
  { key: 1, value: "采购订单" },
  { key: 2, value: "销售订单" },
  { key: 3, value: "直发/倒短" }
]

const TRUCK_ORDER_TYPE_MAP = [
  { key: 1, value: "采购订单" },
  { key: 2, value: "销售订单" },
  { key: 3, value: "直发/倒短" }
]

const ORDER_STATUS = Object.freeze({
  1: "等待审核",
  2: "审核通过",
  6: "已派单",
  3: "审核驳回",
  4: "失效",
  5: "结束"
})

const ORDER_STATUS_MAP = [
  { key: 1, value: "等待审核" },
  { key: 2, value: "审核通过" },
  { key: 6, value: "已派单" },
  { key: 3, value: "审核驳回" },
  { key: 4, value: "失效" },
  { key: 5, value: "结束" }
]

const TRUCK_ORDER_STATUS = Object.freeze({
  1: "等待审核",
  2: "审核通过",
  3: "审核驳回",
  4: "失效",
  5: "结束"
})

const TRUCK_ORDER_STATUS_MAP = [
  { key: 1, value: "等待审核" },
  { key: 2, value: "审核通过" },
  { key: 3, value: "审核驳回" },
  { key: 4, value: "失效" },
  { key: 5, value: "结束" }
]

const WEIGH_ORDER_STATUS = Object.freeze({
  // 1: "未完成",
  1: "运输中",
  2: "作废",
  3: "仓库确认",
  4: "等待完成",
  5: "已完成"
})

const WEIGH_ORDER_STATUS_MAP = [
  // { key: 1, value: "未完成" },
  { key: 1, value: "运输中" },
  { key: 2, value: "作废" },
  { key: 3, value: "仓库确认" },
  { key: 4, value: "等待完成" },
  { key: 5, value: "已完成" }
]

const WEIGHT_LIMIT_MAP = [
  {
    key: 1,
    value: "不限重"
  },
  {
    key: 2,
    value: "限重"
  }
]

//1-供方结算 2-客户结算  3-无需结算
const PAYMENT_METHOD_MAP = [
  { key: 1, value: "供方结算" },
  { key: 2, value: "客户结算" },
  { key: 3, value: "无需结算" }
]

const WEIGHT_METHOD_MAP = [
  { key: 1, value: "一次过磅" },
  { key: 2, value: "二次过磅" }
]

const WEIGHT_TYPE_MAP = [
  { key: 1, value: "毛重" },
  { key: 2, value: "皮重" }
]

const DEDUCT_TYPE_MAP = [
  { key: 1, value: "百分比扣重" },
  { key: 2, value: "KG扣重" }
]

const WEIGH_NUM_MAP = [
  { key: 1, value: "单磅" },
  { key: 2, value: "二磅" },
  { key: 3, value: "三磅" }
]

const WEIGH_MAP = [
  { key: 1, value: "1磅" },
  { key: 2, value: "2磅" },
  { key: 3, value: "3磅" }
]

const TargetBelongTypeMap = [
  { key: 1, value: "部门" },
  { key: 2, value: "个人" }
]

const LogDomainArr = ["用户模块", "客户模块", "电商模块"]
const LogFunctionArr = [
  "用户账号管理",
  "客户联系人管理",
  "客户基本信息管理",
  "推广数据管理",
  "推广关键字管理"
]
const LogOperateType = ["登录", "更改", "新增", "删除"]

const PieChartColor = [
  "#3B7AFC",
  "#FF6730",
  "#F2B101",
  "#6DD400",
  "#47F4CD",
  "#3DC8FF",
  "#ae210e",
  "#236a86",
  "#8cb11c",
  "#a213ca",
  "#d00b94",
  "#ff613d",
  "#e68e7b",
  "#eadb98",
  "#c7a943",
  "#3476ee",
  "#6b88ee",
  "#7a3dff",
  "#8a60e7",
  "#f016b5",
  "#ca5ae9",
  "#eb1692",
  "#eb4feb"
]

const APPROVAL_TEMPLATE_ID_MAP = Object.freeze({
  intoOpenSea: 1002
})

const APPROVAL_TYPE_MAP = Object.freeze({
  APPROVAL: 1,
  Cc: 2
})

const ANALYSIS_TIME_UNIT = Object.freeze({
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  SEASON: "season",
  HALF_YEAR: "half_year",
  YEAR: "year",
  ADD: "add",
  REDUCE: "reduce"
})

const FOLLOW_UP_ANALYSIS_TYPE_TEXT = [
  "今日跟进次数",
  "本周跟进次数",
  "本月跟进次数",
  "本季度跟进次数"
]

const CONTRACT_ANALYSIS_TYPE_TEXT = [
  "今日签约数",
  "本周签约数",
  "本月签约数",
  "本季度签约数"
]

const ACTIVITY_KEY_MAP = Object.freeze({
  CUSTOMER: "customer",
  CONTACT: "contact",
  FOLLOWUP: "followUp",
  CONTRACT: "contract",
  RECEIPT: "receipt",
  ATTACHMENT: "attachment",
  INVOICE: "invoice"
})

const CONTRACT_SHOW_TYPE = Object.freeze({
  ADD_CONTRACT: "addContract",
  SHOW_CONTRACT: "showContract",
  NOT_SHOW: ""
})

const PROJECT_SHOW_TYPE = Object.freeze({
  SHOW_CONTRACT: "showProject",
  NOT_SHOW: ""
})

const AES_KEY = "wtws-erp-aes"

const BASE_OPERATE_TYPE = Object.freeze({
  ADD: "add",
  EDIT: "edit",
  INFO: "info"
})

export {
  updateTheme,
  colorList,
  PageSetting,
  defaultPassWord,
  GET_ROLE_PROMISSION_FUN,
  GET_ROLE_PROMISSION_TYPE,
  ADMIN_ROLE_ID,
  DEFAULT_USER_TYPE,
  DEFAULT_GENDER,
  GRENDER_MAP,
  UNIT_TYPE,
  UNIT_TYPE_MAP,
  DRIVER_ROLE_ID,
  ORDER_STATUS,
  ORDER_STATUS_MAP,
  ORDER_TYPE,
  ORDER_TYPE_MAP,
  CHECK_TYPE,
  TRUCK_ORDER_TYPE,
  TRUCK_ORDER_TYPE_MAP,
  WEIGHT_LIMIT_MAP,
  PAYMENT_METHOD_MAP,
  WEIGH_ORDER_STATUS,
  WEIGH_ORDER_STATUS_MAP,
  TRUCK_ORDER_STATUS,
  TRUCK_ORDER_STATUS_MAP,
  DEDUCT_TYPE_MAP,
  WEIGHT_METHOD_MAP,
  WEIGH_NUM_MAP,
  WEIGH_MAP,
  WEIGHT_TYPE_MAP,
  TargetBelongTypeMap,
  PieChartColor,
  LogDomainArr,
  LogFunctionArr,
  LogOperateType,
  APPROVAL_TEMPLATE_ID_MAP,
  APPROVAL_TYPE_MAP,
  ANALYSIS_TIME_UNIT,
  FOLLOW_UP_ANALYSIS_TYPE_TEXT,
  ACTIVITY_KEY_MAP,
  CONTRACT_SHOW_TYPE,
  PROJECT_SHOW_TYPE,
  CONTRACT_ANALYSIS_TYPE_TEXT,
  AES_KEY,
  BASE_OPERATE_TYPE
}
