<template>
  <div>
    <a-form-model
      ref="ruleForm"
      :model="currentInvoiceInfo"
      :rules="rules"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
    >
      <a-row :gutter="20">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="公司名称">
            <a-input v-model="currentInvoiceInfo.customerName" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="联系人姓名">
            <a-input v-model="currentInvoiceInfo.contactName" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="联系人电话">
            <a-input v-model="currentInvoiceInfo.contactTel" disabled/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="发票ID">
            <a-input v-model="currentInvoiceInfo.contractInvoiceID" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="产品ID">
            <a-input v-model="currentInvoiceInfo.projectID" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="发票编号">
            <a-input v-model="currentInvoiceInfo.invoiceNo" disabled/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="发票金额" prop="invoiceMoney">
            <a-input v-model="currentInvoiceInfo.invoiceMoney" :disabled="disabled"/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="创建人ID">
            <a-input v-model="currentInvoiceInfo.creatorId" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="创建人姓名">
            <a-input v-model="currentInvoiceInfo.creatorName" disabled/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="产品编号">
            <a-input v-model="currentInvoiceInfo.projectNo" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="合同编号">
            <a-input v-model="currentInvoiceInfo.contractNo" disabled/>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="发票时间" prop="invoiceTime">
            <a-date-picker
              v-model="currentInvoiceInfo.invoiceTime"
              show-time
              type="date"
              placeholder="选择时间"
              style="width: 100%;"
              :disabled="disabled"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="20">
        <a-col :span="24">
          <a-form-model-item
            label="上传文件附件"
            :labelCol="{ span: 2 }"
            :wrapperCol="{ span: 22 }"
          >
            <SingleUploadFileAfter
              :invoiceID="currentInvoiceInfo.invoiceID"
              ref="singleUploadFileAfter"
              :allFileCancelPath="InvoiceAllFileCancel"
              :fileList="fileList"
              :disabled="disabled"
              :fileRemovedPath="InvoiceFileRemoved"
              :fileUploadPath="InvoiceFileUpload"
              :originFileDeletePath="InvoiceOriginFileDelete"
              @update-file-list="updateFileList"
            ></SingleUploadFileAfter>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import { fullTimeFilter} from "@/utils/utils"
import SingleUploadFileAfter from "@/components/SingleUploadFileAfter/index.vue";
import { FileOperateAPI } from "@/api/upload-file-after"
import { v4 as uuidv4 } from "uuid"
import { editInvoiceInfo, GetInvoiceDetailById } from "@/api/customer/invoice";
import { getDifferencesList } from "@/utils/array_util";
export default {
  name: "CurrentInvoiceInfoC",
  data(){
    return {
      disabled:true,
      currentInvoiceInfo:{},
      rules:{
        invoiceMoney:[
          {required:true, message:'请输入金额',trigger:'blur'}
        ],
        fileName:[
          {required:true, message:'请输入附件名称',trigger:'blur'}
        ],
        filePath:[
          {required:true, message:'请输入附件地址',trigger:'blur'}
        ]
      },
      InvoiceAllFileCancel: FileOperateAPI.InvoiceAllFileCancel,
      InvoiceFileRemoved: FileOperateAPI.InvoiceFileRemoved,
      InvoiceFileUpload: FileOperateAPI.InvoiceFileUpload,
      InvoiceOriginFileDelete: FileOperateAPI.InvoiceOriginFileDelete,
      fileList:[],
      originFileList: [],
      isSuccess: 1
    }
  },
  methods:{
    fullTimeFilter,
    parseFileList(filePath) {
      if (typeof filePath === "object") {
        return filePath.map(item => {
          const data = { ...item }
          if (!item.uid) {
            data.uid = uuidv4()
          }
          return {
            ...data,
            oldFile: true,
            url: item.filePath,
            status: "done",
            name: item.fileName
          }
        })
      }
      return [
        ...(filePath
          ? JSON.parse(filePath).map(item => {
            const data = { ...item }
            if (!item.uid) {
              data.uid = uuidv4()
            }
            return {
              ...data,
              oldFile: true,
              url: item.filePath,
              status: "done",
              name: item.fileName
            }
          })
          : [])
      ]
    },
    async showData(){
      const InvoiceDetailResult = await GetInvoiceDetailById({invoiceID:this.currentInvoiceInfo.contractInvoiceID})
      if(InvoiceDetailResult.code === 0) {
        const data = []
        if(InvoiceDetailResult.result.fileName !== ''){
          data.push({
            ...InvoiceDetailResult.result,
            filePath: InvoiceDetailResult.result.filePath,
            fileName: InvoiceDetailResult.result.fileName
          })
          this.fileList = this.parseFileList(data)
          this.originFileList = this.parseFileList(data)
        }
      }
    },
    updateFileList(fileList) {
      this.fileList = [...fileList]
    },
    async subEdit(){
      // const that = this.$refs.currentInvoiceInfoC
      const updateData = {
        ...this.currentInvoiceInfo,
        files: JSON.stringify(
          this.fileList.map(item => ({
            uid: item.uid,
            fileName: item.name || item.fileName,
            filePath: item.url || item.filePath,
            invoiceID: item.contractInvoiceID || 0
          }))
        ),
        changeTag: this.currentInfo?.newTag == this.currentInfo?.oldTag ? 1 : 2,
        hasUploadFile: (this.fileList || []).length > 0 ? 2 : 1,
        changeFile:
          (getDifferencesList(this.fileList, this.originFileList, "uid") || [])
            .length > 0
      }
      updateData["fileName"] = JSON.parse(updateData.files)[0]?.fileName
      updateData["filePath"] = JSON.parse(updateData.files)[0]?.filePath
      updateData["invoiceID"] = this.currentInvoiceInfo.contractInvoiceID
      const [delResCode, ] = await Promise.all([
        this.$refs.singleUploadFileAfter.deleteOriginFile(), //删除原来的附件
        editInvoiceInfo(updateData)
      ])
      if(delResCode === 0){
        this.isSuccess = 0
        this.disabled = true
      }else {
        this.isSuccess = 1
      }
    }
  },
  components: {
    SingleUploadFileAfter
  }
};
</script>

<style scoped>

</style>