<template>
  <el-drawer
    :append-to-body="true"
    :before-close="savePromoter"
    :visible.sync="promoterDrawer"
    class="set_promoter"
    direction="rtl"
    size="550px"
    title="发起人"
  >
    <div class="demo-drawer__content">
      <div class="promoter_content drawer_content">
        <p>
          {{
            $func.arrToStr(flowPermission)
              ? $func.arrToStr(flowPermission)
              : "所有人"
          }}
        </p>
        <!--        <el-button type="primary" @click="addPromoter"-->
        <!--          >添加/修改发起人-->
        <!--        </el-button>-->
      </div>
      <div class="demo-drawer__footer clear">
        <el-button type="primary" @click="savePromoter">确 定</el-button>
        <el-button @click="closeDrawer">取 消</el-button>
      </div>
      <employees-dialog
        :data.sync="checkedList"
        :isDepartment="true"
        :visible.sync="promoterVisible"
        @change="surePromoter"
      />
    </div>
  </el-drawer>
</template>
<script>
import employeesDialog from "../dialog/employeesDialog.vue"
import { mapMutations, mapState } from "vuex"

export default {
  components: { employeesDialog },
  data() {
    return {
      flowPermission: [],
      promoterVisible: false,
      checkedList: []
    }
  },
  computed: {
    ...mapState(["promoterDrawer", "flowPermission1"])
  },
  watch: {
    flowPermission1(val) {
      this.flowPermission = val.value
    }
  },
  methods: {
    ...mapMutations(["setPromoter", "setFlowPermission"]),
    addPromoter() {
      this.checkedList = this.flowPermission
      this.promoterVisible = true
    },
    surePromoter(data) {
      this.flowPermission = data
      this.promoterVisible = false
    },
    savePromoter() {
      this.setFlowPermission({
        value: this.flowPermission,
        flag: true,
        id: this.flowPermission1.id
      })
      this.closeDrawer()
    },
    closeDrawer() {
      this.setPromoter(false)
    }
  }
}
</script>
<style lang="less" scoped>
.set_promoter {
  .promoter_content {
    padding: 0 20px;

    .el-button {
      margin-bottom: 20px;
    }

    p {
      padding: 18px 0;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }
}
</style>
