<template>
  <div class="app-container">
    <div class="head-container">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="6" :sm="24">
            <a-form-item label="目标所属">
              <a-select
                v-model="crud.query.belongType"
                :filterOption="filterCategory"
                placeholder="请选择目标分类"
                show-search
              >
                <a-select-option
                  v-for="item in targetBelongTypeMap"
                  :key="item.key"
                  :value="item.key"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-item label="目标类型">
              <a-select
                v-model="crud.query.type"
                :filterOption="filterCategory"
                placeholder="请选择目标分类"
                show-search
              >
                <a-select-option
                  v-for="item in targetTypeMap"
                  :key="item.key"
                  :value="item.key"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :md="6" :sm="24">
            <a-form-item label="目标部门">
              <a-select
                v-model="crud.query.roleID"
                :filterOption="filterCategory"
                placeholder="请选择目标分类"
                show-search
              >
                <a-select-option
                  v-for="item in [...allRoleListMap]"
                  :key="item.roleID"
                  :value="item.roleID"
                  >{{ item.roleName }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <template v-if="crud.advanced">
            <a-col :md="6" :sm="24">
              <a-form-item label="目标员工">
                <a-input
                  v-model="crud.query.userName"
                  placeholder="请输入目标员工姓名"
                />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="开始时间">
                <a-range-picker
                  v-model="crud.query.startTimes"
                  :allowClear="true"
                  format="YYYY-MM-DD"
                  @change="startTimeChange"
                />
              </a-form-item>
            </a-col>

            <a-col :md="6" :sm="24">
              <a-form-item label="截止时间">
                <a-range-picker
                  v-model="crud.query.deadlineTimes"
                  :allowClear="true"
                  format="YYYY-MM-DD"
                  @change="deadlineTimeChange"
                />
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24"></a-col>
          </template>

          <a-col :md="6" :sm="24">
            <SearchOptions style="width: 100%" />
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-container">
      <Operations tableName="目标列表" />
      <a-spin :spinning="crud.loading">
        <a-table
          :columns="crud.columns"
          :data-source="crud.data"
          :pagination="false"
          :row-selection="{
            selectedRowKeys: crud.selections,
            onChange: crud.selectionChangeHandler,
            getCheckboxProps: getCheckboxProps
          }"
          :scroll="{ x: 2000 }"
          rowKey="targetID"
        >
          <template slot="type" slot-scope="record">
            {{
              targetTypeMap.find(item => Number(item.key) === record.type).value
            }}
          </template>

          <template slot="residualValue" slot-scope="record">
            {{ Number(record.totalValue - record.completeValue) }}
          </template>

          <template slot="belongType" slot-scope="record">
            {{
              targetBelongTypeMap.find(
                item => Number(item.key) === record.belongType
              ).value
            }}
          </template>
          <template slot="roleID" slot-scope="record">
            {{
              allRoleListMap.find(item => item.roleID === record.roleID)
                .roleName
            }}
          </template>
          <template slot="userID" slot-scope="record">
            {{
              allUserListMap.find(item => item.userID === record.userID)
                .displayName
            }}
          </template>
          <template slot="startTime" slot-scope="record">
            {{ filterTime(record.startTime) }}
          </template>
          <template slot="deadlineTime" slot-scope="record">
            {{ filterTime(record.deadlineTime) }}
          </template>

          <template slot="insertTime" slot-scope="record">
            {{ filterTime(record.insertTime) }}
          </template>

          <template slot="updateTime" slot-scope="record">
            {{ filterTime(record.updateTime) }}
          </template>

          <template slot="operation" slot-scope="record">
            <a-button
              :disabled="!$route.meta.code.includes('查看目标详情')"
              type="link"
              @click="toInfo(record)"
            >
              详情
            </a-button>
          </template>
        </a-table>
      </a-spin>
      <Pagination />
    </div>
    <!-- 填写表单部分 -->
    <div class="form-container">
      <a-drawer
        :title="crud.status.title"
        :visible="crud.status.cu > 0 || crud.status.in > 0"
        :width="800"
        placement="right"
        @close="crud.cancelCU()"
      >
        <a-tabs
          :activeKey="activeKey"
          type="card"
          @change="
            key => {
              activeKey = key
            }
          "
        >
          <a-tab-pane :key="1" tab="目标信息">
            <a-form-model
              ref="form"
              :class="
                crud.status.in > 0 && crud.status.cu === 0
                  ? 'disabled-form'
                  : ''
              "
              :label-col="{ span: 6 }"
              :model="form"
              :rules="rules"
              :wrapper-col="{ span: 18 }"
              labelAlign="left"
            >
              <a-form-model-item label="目标名称" prop="name">
                <a-input
                  v-model="form.name"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                  placeholder="请输入目标名称"
                />
              </a-form-model-item>
              <a-form-model-item label="目标类型" prop="type">
                <a-select
                  v-model="form.type"
                  :allowClear="true"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                  :not-found-content="null"
                  :show-arrow="false"
                  placeholder="输入选择目标类型"
                  show-search
                >
                  <a-select-option
                    v-for="item in targetTypeMap"
                    :key="item.key"
                    :value="item.key"
                    >{{ item.value }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="目标所属" prop="belongType">
                <a-radio-group v-model="form.belongType">
                  <a-radio
                    v-for="item in targetBelongTypeMap"
                    :key="item.key"
                    :value="item.key"
                    >{{ item.value }}
                  </a-radio>
                </a-radio-group>
              </a-form-model-item>
              <a-form-model-item label="归属部门" prop="roleID">
                <a-select
                  v-model="form.roleID"
                  :allowClear="true"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                  :not-found-content="null"
                  :show-arrow="false"
                  placeholder="输入选择目标归属的部门"
                  show-search
                >
                  <a-select-option
                    v-for="item in allRoleListMap"
                    :key="item.roleID"
                    :value="item.roleID"
                    >{{ item.roleName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="归属用户" prop="userID">
                <a-select
                  v-model="form.userID"
                  :allowClear="true"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                  :not-found-content="null"
                  :show-arrow="false"
                  placeholder="输入选择目标归属的用户"
                  show-search
                >
                  <a-select-option
                    v-for="item in allUserListMap"
                    :key="item.userID"
                    :value="item.userID"
                    >{{ item.displayName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="起始日期" prop="startTime">
                <a-date-picker
                  v-model="form.startTime"
                  :allowClear="true"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                  format="YYYY-MM-DD"
                />
              </a-form-model-item>
              <a-form-model-item label="截止日期" prop="deadlineTime">
                <a-date-picker
                  v-model="form.deadlineTime"
                  :allowClear="true"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                  format="YYYY-MM-DD"
                />
              </a-form-model-item>
              <a-form-model-item label="目标总值" prop="totalValue">
                <a-input
                  v-model="form.totalValue"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                  placeholder="请输入目标总值"
                  type="number"
                  @change="
                    () => {
                      form.totalValue = Number(form.totalValue)
                      if (isNaN(form.totalValue)) {
                        form.totalValue = 0
                      }
                      if (form.totalValue < 0) {
                        form.totalValue = 0
                      }
                      if (
                        form.completeValue &&
                        form.totalValue < form.completeValue
                      ) {
                        form.totalValue = form.completeValue
                      }
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item label="目标已完成" prop="completeValue">
                <a-input
                  v-model="form.completeValue"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                  :min="0"
                  placeholder="请输入目标已完成"
                  type="number"
                  @change="
                    () => {
                      form.completeValue = Number(form.completeValue)
                      if (isNaN(form.completeValue)) {
                        form.completeValue = 0
                      }
                      if (form.completeValue < 0) {
                        form.completeValue = 0
                      }
                      if (
                        form.totalValue &&
                        form.totalValue < form.completeValue
                      ) {
                        form.completeValue = form.totalValue
                      }
                    }
                  "
                />
              </a-form-model-item>
              <a-form-model-item label="目标单位" prop="unit">
                <a-input
                  v-model="form.unit"
                  :disabled="crud.status.in > 0 && crud.status.cu === 0"
                  placeholder="请输入目标单位"
                />
              </a-form-model-item>

              <div
                style="width: 100%;height: 100px;text-align: center;margin-top: 50px"
              >
                <div v-if="crud.status.cu > 0">
                  <a-button
                    v-if="crud.status.add > 0"
                    key="back"
                    @click="crud.cancelCU()"
                  >
                    取消
                  </a-button>
                  <a-button
                    v-if="crud.status.edit > 0"
                    key="back"
                    @click="crud.cancelCU('uptoinfo')"
                  >
                    取消
                  </a-button>
                  <a-button
                    v-if="crud.status.add > 0"
                    key="submit"
                    style="margin-left: 20px"
                    type="primary"
                    @click="crud.submitCU()"
                  >
                    提交
                  </a-button>
                  <a-button
                    v-if="crud.status.edit > 0"
                    key="submit"
                    style="margin-left: 20px"
                    type="primary"
                    @click="crud.submitCU()"
                  >
                    保存
                  </a-button>
                </div>
                <div v-if="updateOrDeleteAllow(form)">
                  <a-button
                    key="back"
                    :disabled="!crud.permission.edit"
                    @click="crud.toEdit()"
                  >
                    修改
                  </a-button>
                  <a-popconfirm
                    cancel-text="取消"
                    ok-text="删除"
                    placement="topRight"
                    style="margin-left: 20px"
                    @confirm="crud.doDelete(null, 'close')"
                  >
                    <template slot="title">
                      你确定要删除吗？
                    </template>
                    <a-button
                      key="submit"
                      :disabled="!crud.permission.del"
                      type="danger"
                    >
                      删除
                    </a-button>
                  </a-popconfirm>
                </div>
              </div>
            </a-form-model>
          </a-tab-pane>
        </a-tabs>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import SearchOptions from "@/components/Crud/SearchOptions"
import Operations from "@/components/Crud/Operations"
import Pagination from "@/components/Crud/Pagination"
import CRUD, { form, header, presenter } from "@crud/crud"
import { targetListColumns } from "./target-list"
import Api from "@/api/target/target"
import { ADMIN_ROLE_ID, TargetBelongTypeMap } from "@/config"
import { fullTimeFilter } from "@/utils/utils"
import { GetAllRoleList, GetAllUserList, GetDictionary } from "@/api/public-api"
import store from "@/store"

// 表格的填充字段
const defaultForm = {
  totalValue: 0,
  completeValue: 0,
  unit: "",
  deadlineTime: null,
  startTime: null,
  roleID: 0,
  belongType: 0,
  type: 0,
  name: ""
}
export default {
  name: "Goods",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "目标",
      roleIDs: [],
      columns: targetListColumns,
      crudMethod: { ...Api },
      idField: "targetID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    const checkTotalValue = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请填写目标总值！"))
      }

      if (value <= 0) {
        return callback(new Error("请填写正确的目标总值！"))
      }

      if (value <= form.completeValue) {
        return callback(new Error("目标总额不能小于完成总额！"))
      }

      callback()
    }
    const checkCompleteValue = (rule, value, callback) => {
      if (!value && value !== 0) {
        return callback(new Error("请填写目标完成额！"))
      }

      if (value > form.totalValue) {
        return callback(new Error("完成额不能大于目标总额！"))
      }
      callback()
    }
    return {
      targetTypeMap: [],
      allRoleListMap: [],
      allUserListMap: [],
      targetBelongTypeMap: TargetBelongTypeMap,

      roleIDs: [],

      activeKey: 1,
      rules: {
        name: [{ required: true, message: "请输入目标名称", trigger: "blur" }],
        type: [{ required: true, message: "请选择目标类型", trigger: "blur" }],
        belongType: [
          { required: true, message: "请选择目标所属", trigger: "change" }
        ],
        roleID: [
          { required: true, message: "请选择目标部门", trigger: "blur" }
        ],
        startTime: [
          { required: true, message: "请选择起始日期", trigger: "change" }
        ],
        deadlineTime: [
          { required: true, message: "请选择截止日期", trigger: "change" }
        ],
        totalValue: [
          { required: true, message: checkTotalValue, trigger: "blur" }
        ],
        completeValue: [
          { required: true, message: checkCompleteValue, trigger: "blur" }
        ],
        unit: [{ required: true, message: "请输入目标的单位", trigger: "blur" }]
      }
    }
  },
  components: { SearchOptions, Operations, Pagination },
  async created() {
    /**
     * 设置按钮权限
     */
    let { code } = this.$route.meta
    // 关闭下载功能
    // this.crud.optShow.download = false
    this.crud.optShow.edit = false
    // 显示以下按钮
    this.crud.permission = {
      add: code.includes("新增目标"),
      del: code.includes("删除目标"),
      edit: code.includes("修改目标")
      // download: true
    }
    const [targetTypeRes, allRoleListRes, allUserListRes] = await Promise.all([
      GetDictionary({ typeName: "targetType" }),
      GetAllRoleList(),
      GetAllUserList()
    ])
    if (targetTypeRes.code === 0) {
      this.targetTypeMap = targetTypeRes.result
    }

    if (allRoleListRes.code === 0) {
      this.allRoleListMap = [
        ...allRoleListRes.result,
        { roleID: 0, roleName: "公司" }
      ]
    }

    if (allUserListRes.code === 0) {
      this.allUserListMap = [
        ...allUserListRes.result,
        { userID: 0, displayName: "无" }
      ]
    }

    this.roleIDs = store.getters.roleIDs
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    startTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.startTimes = [time1, time1]
          return
        }
      }
      this.crud.query.startTimes = dateStrings
    },
    deadlineTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.deadlineTimes = [time1, time1]
          return
        }
      }
      this.crud.query.deadlineTimes = dateStrings
    },
    getCheckboxProps(record) {
      if (record.roleID === 0 && !this.roleIDs.includes(ADMIN_ROLE_ID)) {
        return { props: { disabled: true } }
      }
      return { props: { disabled: false } }
    },
    updateOrDeleteAllow(data) {
      console.log(`this.roleIDs : ${JSON.stringify(this.roleIDs)}`)
      console.log(`this.form.roleID : ${JSON.stringify(data.roleID)}`)
      return (
        this.crud.status.in > 0 &&
        this.crud.status.cu === 0 &&
        (this.roleIDs.includes(ADMIN_ROLE_ID) ||
          this.roleIDs.includes(data.roleID))
      )
    },
    toInfo(record) {
      this.crud.toInfo(record)
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    }
  }
}
</script>
