<template>
  <div class="app-container">
    <a-card
      :body-style="{ padding: '0px' }"
      :bordered="false"
      class="headCard1"
    >
      <div class="table-operations">
        <span style="font-size: 18px;margin-right: 20px;margin-left: 10px">
          客户来源平台列表
        </span>
        <a-button icon="redo" type="link" @click="refreshSourcePlatform" />
        <a-button
          :disabled="!permissionFunctions.includes('新增推广平台')"
          icon="plus-circle"
          style="width: 70px"
          type="primary"
          @click="toAddSourcePlatform"
        />
      </div>
      <a-table
        :columns="SourcePlatformListColumns"
        :dataSource="allSourcePlatformMap"
        :pagination="false"
        :scroll="{
          y: 340,
          scrollToFirstRowOnChange: true
        }"
        rowKey="sourcePlatformID"
        style="width:99%;height:99%;margin:0 auto"
      >
        <template slot="insertTime" slot-scope="record">
          {{ filterTime(record.insertTime) }}
        </template>

        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            :disabled="!$route.meta.code.includes('删除推广平台')"
            cancel-text="取消"
            ok-text="删除"
            placement="topRight"
            style="display: inline-block;width:60px"
            @confirm="deleteSourcePlatform(record)"
          >
            <template slot="title">
              你确定要删除吗？
            </template>
            <a-button
              :disabled="!permissionFunctions.includes('删除推广平台')"
              size="small"
              style="display: inline-block;width: 60px"
              type="link"
            >
              删除
            </a-button>
          </a-popconfirm>
          <a-button
            :disabled="
              crud.status.in > 0 &&
                crud.status.cu === 0 &&
                !permissionFunctions.includes('修改推广平台')
            "
            size="small"
            type="link"
            @click="toEditSourcePlatform(record)"
          >
            修改
          </a-button>
        </template>
      </a-table>
    </a-card>
    <a-card
      :body-style="{ padding: '0px' }"
      :bordered="false"
      class="headCard2"
    >
      <div class="table-operations">
        <span style="font-size: 18px;margin-right: 20px;margin-left: 10px">
          客户沟通方式列表
        </span>
        <a-button icon="redo" type="link" @click="refreshConversation" />
        <a-button
          :disabled="!permissionFunctions.includes('新增沟通方式')"
          icon="plus-circle"
          style="width: 70px"
          type="primary"
          @click="toAddConversation"
        />
      </div>
      <a-table
        :columns="AllConversationListColumns"
        :dataSource="allConversationMap"
        :pagination="false"
        :scroll="{
          y: 340
        }"
        rowKey="conversationID"
        style="width:99%;height:99%;margin:0 auto"
      >
        <template slot="insertTime" slot-scope="record">
          {{ filterTime(record.insertTime) }}
        </template>

        <template slot="operation" slot-scope="text, record">
          <a-popconfirm
            :disabled="!permissionFunctions.includes('删除沟通方式')"
            cancel-text="取消"
            ok-text="删除"
            placement="topRight"
            style="display: inline-block;width:60px"
            @confirm="deleteConversation(record)"
          >
            <template slot="title">
              你确定要删除吗？
            </template>
            <a-button
              size="small"
              style="display: inline-block;width: 60px"
              type="link"
            >
              删除
            </a-button>
          </a-popconfirm>
          <a-button
            :disabled="
              crud.status.in > 0 &&
                crud.status.cu === 0 &&
                !permissionFunctions.includes('修改沟通方式')
            "
            size="small"
            type="link"
            @click="toEditConversation(record)"
          >
            修改
          </a-button>
        </template>
      </a-table>
    </a-card>
    <el-collapse :value="showCollapse" class="my-collapse">
      <el-collapse-item
        :disabled="!permissionFunctions.includes('分析营销数据走势')"
        :name="1"
        title="营销数据走势"
      >
        <a-card
          :body-style="{ padding: '0px' }"
          :bordered="false"
          class="collapse-card"
          hoverable
        >
          <div>
            <div ref="chart3_1" style="width:100%;height:400px"></div>
          </div>
          <div>
            <div ref="chart3_2" style="width:100%;height:400px"></div>
          </div>
        </a-card>
      </el-collapse-item>
    </el-collapse>

    <a-card
      :body-style="{ padding: '0px' }"
      :bordered="false"
      style="width: 100%;margin-top:10px; margin-bottom: 20px;float:left;border: none;   border-radius: 10px;   box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);"
    >
      <div class="table-operations">
        <span style="font-size: 18px;margin-right: 20px;margin-left: 10px">
          每日营销汇总表
        </span>
        <a-range-picker
          v-model="rangeMarketingTimes"
          :allowClear="true"
          :disabled="!permissionFunctions.includes('查询营销记录列表')"
          format="YYYY-MM-DD"
          @change="changeMarketingRecordPage"
        />
        <a-button
          :disabled="!permissionFunctions.includes('查询营销记录列表')"
          icon="redo"
          style="margin-left: 20px"
          type="link"
          @click="changeMarketingRecordPage"
        />
        <a-button
          :disabled="!permissionFunctions.includes('导出每日营销汇总表')"
          icon="cloud-download"
          style="width: 150rpx"
          type="primary"
          @click="downloadMarketingRecords"
          >导出excel
        </a-button>
      </div>
      <a-table
        :bordered="true"
        :columns="MarketingListColumns"
        :dataSource="marketingRecordList"
        :pagination="false"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        :scroll="{ x: 1920 }"
        class="ant-table-striped"
        empty-text="-"
        rowKey="marketingRecordID"
        style="width:99%;height:99%;margin:0 auto"
      >
        <template slot="dateNo" slot-scope="record">
          <a
            @click="
              () => {
                showInfoMarketingRecord = true
                toInfoMarketingRecord(record)
              }
            "
            >{{ record.dateNo }}</a
          >
        </template>
        <template slot="totalConversationRate" slot-scope="record">
          {{
            record.clickNum == 0
              ? "-"
              : (record.conversationTotal / record.clickNum).toFixed(2) + "%"
          }}
        </template>
        <template slot="validConversationRate" slot-scope="record">
          {{
            record.conversationTotal == 0
              ? "-"
              : (record.conversationValid / record.conversationTotal).toFixed(
                  2
                ) + "%"
          }}
        </template>
        <template slot="arriveRate" slot-scope="record">
          {{
            record.clickNum == 0
              ? "-"
              : (record.arriveNum / record.clickNum).toFixed(2) + "%"
          }}
        </template>
        <template slot="clickRate" slot-scope="record">
          {{
            record.clickNum == 0
              ? "-"
              : (record.appearNum / record.clickNum).toFixed(2) + "%"
          }}
        </template>
        <template slot="clickCost" slot-scope="record">
          {{
            record.clickNum == 0
              ? "-"
              : (record.cost / record.clickNum).toFixed(2) + "%"
          }}
        </template>
        <template slot="totalConversationCost" slot-scope="record">
          {{
            record.conversationTotal == 0
              ? "-"
              : (record.cost / record.conversationTotal).toFixed(2) + "%"
          }}
        </template>
        <template slot="validConversationCost" slot-scope="record">
          {{
            record.conversationValid == 0
              ? "-"
              : (record.cost / record.conversationValid).toFixed(2) + "%"
          }}
        </template>
        <template slot="insertTime" slot-scope="record">
          {{ filterTime(record.insertTime) }}
        </template>
        <template slot="operation" slot-scope="record">
          <a-button
            :disabled="!permissionFunctions.includes('查看营销记录详情')"
            type="link"
            @click="
              () => {
                showInfoMarketingRecord = true
                toInfoMarketingRecord(record)
              }
            "
          >
            详情
          </a-button>
        </template>
      </a-table>
      <div class="own-part1-pagination">
        <a-pagination
          v-model="marketingRecordPage.page"
          :pageSize.sync="marketingRecordPage.size"
          :pageSizeOptions="['10', '20', '50', '100']"
          :show-total="total => `共有 ${total} 条记录`"
          :total="marketingRecordPage.total"
          show-quick-jumper
          show-size-changer
          @change="marketingRecordPagePageChangeHandler"
          @showSizeChange="marketingRecordPageSizeChangeHandler"
        />
      </div>
    </a-card>
    <a-card
      :body-style="{ padding: '0px' }"
      :bordered="false"
      style="width: 100%;margin-top:10px; margin-bottom: 20px;float:left;border: none;   border-radius: 10px;   box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);"
    >
      <div class="table-operations">
        <span style="font-size: 18px;margin-right: 20px;margin-left: 10px">
          各平台营销表
        </span>
        <a-button
          :disabled="!permissionFunctions.includes('新增平台营销记录')"
          icon="plus-circle"
          style="width: 100px"
          type="primary"
          @click="
            () => {
              addOrUpdateSourcePlatformMarketingRecord = 'add'
              sourcePlatformMarketingRecordConversationArr = JSON.parse(
                JSON.stringify(allConversationMap)
              )
              $refs.sourcePlatformMarketingRecordForm.clearValidate()
            }
          "
          >新增
        </a-button>
        <a-button
          :disabled="!permissionFunctions.includes('删除平台营销记录')"
          icon="close-circle"
          style="width: 100px;margin-left: 10px;margin-right: 10px"
          type="danger"
          @click="toDeleteSourcePlatformMarketingRecord"
          >删除
        </a-button>
        <a-button
          :disabled="!permissionFunctions.includes('导出每日各平台营销表')"
          ghost
          icon="cloud-download"
          style="width: 140px;margin-left: 10px;margin-right: 10px"
          type="primary"
          @click="downloadMarketingSourcePlatformRecords"
          >导出excel
        </a-button>
        <a-range-picker
          v-model="rangeSourcePlatformMarketingTimes"
          :allowClear="true"
          :disabled="!permissionFunctions.includes('查询平台营销记录列表')"
          format="YYYY-MM-DD"
          style="margin-left: 10px"
          @change="changeSourcePlatformMarketingRecordPage"
        />
        <a-select
          v-model="sourcePlatformKey"
          :allowClear="true"
          :disabled="!permissionFunctions.includes('查询平台营销记录列表')"
          placeholder="选择平台"
          style="width: 200px;margin-left: 30px"
          @change="changeSourcePlatformMarketingRecordPage"
        >
          <a-select-option
            v-for="item in [
              ...allSourcePlatformMap,
              { sourcePlatformID: 0, platformName: '不限' }
            ]"
            :key="item.sourcePlatformID"
            :value="item.sourcePlatformID"
            >{{ item.platformName }}
          </a-select-option>
        </a-select>
        <a-button
          :disabled="!permissionFunctions.includes('查询平台营销记录列表')"
          icon="redo"
          style="margin-left: 20px"
          type="link"
          @click="changeSourcePlatformMarketingRecordPage"
        />
      </div>
      <a-table
        :bordered="true"
        :columns="SourcePlatformMarketingListColumns"
        :dataSource="sourcePlatformMarketingRecordList"
        :pagination="false"
        :row-class-name="
          (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
        "
        :row-selection="{
          selectedRowKeys: selectedSourcePlatformMarketingRecordIDs,
          onChange: changeSourcePlatformMarketingRecordIDrHandler
        }"
        :scroll="{ x: 2200 }"
        class="ant-table-striped"
        empty-text="-"
        rowKey="marketingRecordID"
        style="width:99%;height:99%;margin:0 auto"
      >
        <template slot="sourcePlatformID" slot-scope="record">
          {{ tableSourcePlatformName(record) }}
        </template>
        <template slot="totalConversationRate" slot-scope="record">
          {{
            record.clickNum == 0
              ? "-"
              : ((record.conversationTotal / record.clickNum) * 100).toFixed(
                  2
                ) + "%"
          }}
        </template>
        <template slot="validConversationRate" slot-scope="record">
          {{
            record.conversationTotal == 0
              ? "-"
              : (
                  (record.conversationValid / record.conversationTotal) *
                  100
                ).toFixed(2) + "%"
          }}
        </template>
        <template slot="arriveRate" slot-scope="record">
          {{
            record.clickNum == 0
              ? "-"
              : ((record.arriveNum / record.clickNum) * 100).toFixed(2) + "%"
          }}
        </template>
        <template slot="clickRate" slot-scope="record">
          {{
            record.clickNum == 0
              ? "-"
              : ((record.appearNum / record.clickNum) * 100).toFixed(2) + "%"
          }}
        </template>
        <template slot="clickCost" slot-scope="record">
          {{
            record.clickNum == 0
              ? "-"
              : ((record.cost / record.clickNum) * 100).toFixed(2) + "%"
          }}
        </template>
        <template slot="totalConversationCost" slot-scope="record">
          {{
            record.conversationTotal == 0
              ? "-"
              : ((record.cost / record.conversationTotal) * 100).toFixed(2) +
                "%"
          }}
        </template>
        <template slot="validConversationCost" slot-scope="record">
          {{
            record.conversationValid == 0
              ? "-"
              : ((record.cost / record.conversationValid) * 100).toFixed(2) +
                "%"
          }}
        </template>
        <template slot="insertTime" slot-scope="record">
          {{ filterTime(record.insertTime) }}
        </template>
        <template slot="operation" slot-scope="record">
          <a-button
            :disabled="!permissionFunctions.includes('查看每日各平台营销详情')"
            type="link"
            @click="toInfoSourcePlatformMarketingRecord(record)"
          >
            详情
          </a-button>
        </template>
      </a-table>
      <div class="own-part1-pagination">
        <a-pagination
          v-model="sourcePlatformMarketingRecordPage.page"
          :pageSize.sync="sourcePlatformMarketingRecordPage.size"
          :pageSizeOptions="['10', '20', '50', '100']"
          :show-total="total => `共有 ${total} 条记录`"
          :total="sourcePlatformMarketingRecordPage.total"
          show-quick-jumper
          show-size-changer
          @change="sourcePlatformMarketingRecordPagePageChangeHandler"
          @showSizeChange="sourcePlatformMarketingRecordPageSizeChangeHandler"
        />
      </div>
    </a-card>
    <div class="form-container">
      <a-modal
        :afterClose="cancelSourcePlatform"
        :title="addOrUpdatePlatformFlag === 'add' ? '新增平台' : '修改平台名'"
        :visible="addOrUpdatePlatformFlag !== ''"
        wrapClassName="stationListDialog"
        @cancel="cancelSourcePlatform"
      >
        <a-form-model
          ref="form"
          :label-col="{ span: 6 }"
          :model="sourcePlatformForm"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-model-item
            v-if="addOrUpdatePlatformFlag === 'update'"
            label="原平台名"
            prop="oldSourcePlatformName"
          >
            <span>{{ sourcePlatformForm.oldSourcePlatformName }}</span>
          </a-form-model-item>
          <a-form-model-item label="平台名称" prop="sourcePlatformName">
            <a-input
              v-model="sourcePlatformForm.sourcePlatformName"
              placeholder="请输入新的平台名称"
            />
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <div>
            <a-button key="back" @click="cancelSourcePlatform">
              取消
            </a-button>
            <a-button
              v-if="addOrUpdatePlatformFlag === 'update'"
              key="submit"
              type="primary"
              @click="editSourcePlatform"
            >
              提交
            </a-button>
            <a-button
              v-if="addOrUpdatePlatformFlag === 'add'"
              key="submit"
              type="primary"
              @click="addSourcePlatform"
            >
              保存
            </a-button>
          </div>
        </template>
      </a-modal>
      <a-modal
        :afterClose="cancelConversation"
        :title="
          addOrUpdateConversationFlag === 'add'
            ? '新增沟通方式'
            : '修改沟通方式'
        "
        :visible="addOrUpdateConversationFlag !== ''"
        wrapClassName="stationListDialog"
        @cancel="cancelConversation"
      >
        <a-form-model
          ref="form"
          :label-col="{ span: 6 }"
          :model="conversationForm"
          :wrapper-col="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-model-item
            v-if="addOrUpdateConversationFlag === 'update'"
            label="原沟通方式"
            prop="oldName"
          >
            <span>{{ conversationForm.oldName }}</span>
          </a-form-model-item>
          <a-form-model-item label="沟通方式" prop="name">
            <a-input
              v-model="conversationForm.name"
              placeholder="请输入新的沟通方式名称"
            />
          </a-form-model-item>
        </a-form-model>
        <template slot="footer">
          <div>
            <a-button key="back" @click="cancelConversation">
              取消
            </a-button>
            <a-button
              v-if="addOrUpdateConversationFlag === 'update'"
              key="submit"
              type="primary"
              @click="editConversation"
            >
              提交
            </a-button>
            <a-button
              v-if="addOrUpdateConversationFlag === 'add'"
              key="submit"
              type="primary"
              @click="addConversation"
            >
              保存
            </a-button>
          </div>
        </template>
      </a-modal>
      <a-modal
        :afterClose="() => (showInfoMarketingRecord = false)"
        :visible="showInfoMarketingRecord == true"
        :width="1200"
        title="每日营销汇总详情"
        wrapClassName="stationListDialog"
        @cancel="showInfoMarketingRecord = false"
      >
        <div class="bottom-card">
          <a-card class="card11" hoverable title="各平台营销占比">
            <span slot="extra">
              <a-select
                v-model="marketingRecordDetailPieKey"
                placeholder="选择占比类型"
                style="width: 200px"
                @select="selectPieKey"
              >
                <a-select-option
                  v-for="item in allMarketingRecordDetailPieKeyMap"
                  :key="item.key"
                  :value="item.key"
                  >{{ item.value }}
                </a-select-option>
              </a-select>
            </span>
            <div class="_box">
              <div class="_t"></div>
              <div ref="chart1" style="width:96%;height:376px"></div>
            </div>
          </a-card>

          <a-card class="card22" hoverable title="各沟通方式占比">
            <span slot="extra"></span>
            <div class="_box">
              <div class="_t"></div>
              <div ref="chart2" style="width:96%;height:376px"></div>
            </div>
          </a-card>
        </div>
        <template slot="footer">
          <div>
            <a-button key="back" @click="showInfoMarketingRecord = false">
              取消
            </a-button>
          </div>
        </template>
      </a-modal>
      <a-modal
        :afterClose="cancelSourcePlatformMarketingRecord"
        :title="
          addOrUpdateSourcePlatformMarketingRecord === 'add'
            ? '新增平台营销记录'
            : '平台营销记录'
        "
        :visible="addOrUpdateSourcePlatformMarketingRecord !== ''"
        :width="1200"
        wrapClassName="stationListDialog"
        @cancel="cancelSourcePlatformMarketingRecord"
      >
        <a-form-model
          ref="sourcePlatformMarketingRecordForm"
          :class="
            addOrUpdateSourcePlatformMarketingRecord === 'info'
              ? 'disabled-form'
              : ''
          "
          :label-col="{ span: 8 }"
          :model="sourcePlatformMarketingRecordForm"
          :rules="sourcePlatformMarketingRecordFormRules"
          :wrapper-col="{ span: 16 }"
          labelAlign="left"
          style="height: 600px"
          @submit.native.prevent
        >
          <a-row>
            <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
              <div
                style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center"
              >
                <h3 style="color: #0b64f4;">营销记录基本信息</h3>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
              <a-form-model-item label="营销平台" prop="sourcePlatformID">
                <a-select
                  v-model="sourcePlatformMarketingRecordForm.sourcePlatformID"
                  :allowClear="true"
                  :disabled="
                    addOrUpdateSourcePlatformMarketingRecord === 'info'
                  "
                  placeholder="选择平台"
                  style="width:100%"
                >
                  <a-select-option
                    v-for="item in [
                      ...allSourcePlatformMap,
                      { sourcePlatformID: 0, platformName: '不限' }
                    ]"
                    :key="item.sourcePlatformID"
                    :value="item.sourcePlatformID"
                    >{{ item.platformName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
              <a-form-model-item label="日期" prop="dateNo">
                <a-date-picker
                  v-model="sourcePlatformMarketingRecordForm.dateNo"
                  :disabled="
                    addOrUpdateSourcePlatformMarketingRecord === 'info'
                  "
                  placeholder="请输入选择日期"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :lg="5" :md="24" :sm="24" :xl="5" :xs="24" :xxl="5">
              <a-form-model-item label="消费" prop="cost">
                <a-input
                  v-model="sourcePlatformMarketingRecordForm.cost"
                  :disabled="
                    addOrUpdateSourcePlatformMarketingRecord === 'info'
                  "
                  placeholder="请输入消费成本"
                  type="number"
                  @change="
                    () =>
                      sourcePlatformMarketingRecordForm.cost < 0
                        ? (sourcePlatformMarketingRecordForm.cost = 0)
                        : sourcePlatformMarketingRecordForm.cost
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
              <a-form-model-item label="展现次数" prop="appearNum">
                <a-input
                  v-model="sourcePlatformMarketingRecordForm.appearNum"
                  :disabled="
                    addOrUpdateSourcePlatformMarketingRecord === 'info'
                  "
                  placeholder="请输入展现次数"
                  type="number"
                  @change="
                    () =>
                      sourcePlatformMarketingRecordForm.appearNum < 0
                        ? (sourcePlatformMarketingRecordForm.appearNum = 0)
                        : sourcePlatformMarketingRecordForm.appearNum
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
              <a-form-model-item label="点击次数" prop="clickNum">
                <a-input
                  v-model="sourcePlatformMarketingRecordForm.clickNum"
                  :disabled="
                    addOrUpdateSourcePlatformMarketingRecord === 'info'
                  "
                  placeholder="请输入点击次数"
                  type="number"
                  @change="
                    () =>
                      sourcePlatformMarketingRecordForm.clickNum < 0
                        ? (sourcePlatformMarketingRecordForm.clickNum = 0)
                        : sourcePlatformMarketingRecordForm.clickNum
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
              <a-form-model-item label="抵达次数" prop="arriveNum">
                <a-input
                  v-model="sourcePlatformMarketingRecordForm.arriveNum"
                  :disabled="
                    addOrUpdateSourcePlatformMarketingRecord === 'info'
                  "
                  placeholder="请输入抵达次数"
                  type="number"
                  @change="
                    () =>
                      sourcePlatformMarketingRecordForm.arriveNum < 0
                        ? (sourcePlatformMarketingRecordForm.arriveNum = 0)
                        : sourcePlatformMarketingRecordForm.arriveNum
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
              <a-form-model-item label="总对话数" prop="conversationTotal">
                <a-input
                  v-model="sourcePlatformMarketingRecordForm.conversationTotal"
                  :disabled="
                    addOrUpdateSourcePlatformMarketingRecord === 'info'
                  "
                  placeholder="请输入总对话数"
                  type="number"
                  @change="
                    () =>
                      sourcePlatformMarketingRecordForm.conversationTotal < 0
                        ? (sourcePlatformMarketingRecordForm.conversationTotal = 0)
                        : sourcePlatformMarketingRecordForm.conversationTotal
                  "
                />
              </a-form-model-item>
            </a-col>
            <a-col :lg="6" :md="24" :sm="24" :xl="6" :xs="24" :xxl="6">
              <a-form-model-item label="有效对话数" prop="conversationValid">
                <a-input
                  v-model="sourcePlatformMarketingRecordForm.conversationValid"
                  :disabled="
                    addOrUpdateSourcePlatformMarketingRecord === 'info'
                  "
                  placeholder="请输入有效对话数"
                  type="number"
                  @change="
                    () =>
                      sourcePlatformMarketingRecordForm.conversationValid < 0
                        ? (sourcePlatformMarketingRecordForm.conversationValid = 0)
                        : sourcePlatformMarketingRecordForm.conversationValid
                  "
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
              <div
                style="display: flex;justify-content: flex-start;vertical-align: middle;align-items: center;margin-bottom: 10px"
              >
                <h3 style="color: #0b64f4;">客户沟通方式信息</h3>
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
              <a-table
                :bordered="true"
                :columns="SourcePlatformMarketingConversationListColumns"
                :dataSource="sourcePlatformMarketingRecordConversationArr"
                :scroll="{
                  y: 300,
                  scrollToFirstRowOnChange: true
                }"
                rowKey="conversationID"
              >
                <template
                  slot="conversationNum"
                  slot-scope="text, recordConversation"
                >
                  <a-input
                    v-model="recordConversation.conversationNum"
                    :disabled="
                      addOrUpdateSourcePlatformMarketingRecord === 'info'
                    "
                    placeholder="请输入沟通次数"
                    type="number"
                    @change="
                      () =>
                        recordConversation.conversationNum < 0
                          ? (recordConversation.conversationNum = 0)
                          : recordConversation.conversationNum
                    "
                  />
                </template>
              </a-table>
            </a-col>
          </a-row>
        </a-form-model>
        <template slot="footer">
          <div>
            <a-button
              v-if="
                addOrUpdateSourcePlatformMarketingRecord &&
                  addOrUpdateSourcePlatformMarketingRecord != 'edit'
              "
              key="back"
              @click="cancelSourcePlatformMarketingRecord"
            >
              取消
            </a-button>
            <a-button
              v-if="addOrUpdateSourcePlatformMarketingRecord === 'edit'"
              key="back"
              @click="
                toInfoSourcePlatformMarketingRecord(
                  sourcePlatformMarketingRecordDefaultForm
                )
              "
            >
              取消
            </a-button>
            <a-button
              v-if="addOrUpdateSourcePlatformMarketingRecord === 'edit'"
              key="submit"
              type="primary"
              @click="editSourcePlatformMarketingRecord"
            >
              提交
            </a-button>
            <a-button
              v-if="addOrUpdateSourcePlatformMarketingRecord === 'add'"
              key="submit"
              type="primary"
              @click="addSourcePlatformMarketingRecord"
            >
              保存
            </a-button>
            <a-button
              v-if="addOrUpdateSourcePlatformMarketingRecord === 'info'"
              key="submit"
              :disabled="!permissionFunctions.includes('更改平台营销记录')"
              type="primary"
              @click="
                () => {
                  addOrUpdateSourcePlatformMarketingRecord = 'edit'
                  sourcePlatformMarketingRecordDefaultForm = JSON.parse(
                    JSON.stringify(sourcePlatformMarketingRecordForm)
                  )
                }
              "
            >
              修改
            </a-button>
          </div>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import CRUD, { form, header, presenter } from "@crud/crud"
import {
  AllConversationListColumns,
  MarketingListColumns,
  SourcePlatformListColumns,
  SourcePlatformMarketingConversationListColumns,
  SourcePlatformMarketingListColumns
} from "./marketing-list"
import Api from "@/api/ecommerce/marketing"
import sourcePlatformApi from "@/api/ecommerce/source-platform"
import conversationApi from "@/api/ecommerce/conversation"
import { fullTimeFilter } from "@/utils/utils"
import {
  GetAllConversation,
  GetAllSourcePlatform,
  GetDictionary
} from "@/api/public-api"
import { targetListColumns } from "@/views/customer/target-list"
import {
  drawChartConversationRate,
  drawChartMarketingTrendPart1,
  drawChartMarketingTrendPart2,
  drawChartSourcePlatformRate
} from "@/views/ecommerce/char"
import { ANALYSIS_TIME_UNIT } from "@/config"
import store from "@/store"

// 表格的填充字段
const defaultForm = {}
export default {
  name: "Marketing",
  // presenter 生成crud对象
  mixins: [presenter(), header(), form(defaultForm)],
  cruds() {
    return CRUD({
      title: "目标",
      roleIDs: [],
      columns: targetListColumns,
      crudMethod: { ...Api },
      idField: "targetID" // 设置每一条记录的唯一标失
    })
  },
  data() {
    return {
      permissionFunctions: [],
      showCollapse: [],
      allSourcePlatformMap: [],
      allConversationMap: [],
      marketingRecordList: [],
      sourcePlatformMarketingRecordList: [],
      allMarketingRecordDetailPieKeyMap: [],
      sourcePlatformID: 1,

      SourcePlatformListColumns,
      AllConversationListColumns,
      MarketingListColumns,
      SourcePlatformMarketingListColumns,
      addOrUpdatePlatformFlag: "",
      sourcePlatformForm: {
        sourcePlatformID: 0,
        sourcePlatformName: "",
        oldSourcePlatformName: ""
      },
      addOrUpdateConversationFlag: "",
      conversationForm: {
        conversationID: 0,
        name: "",
        oldName: ""
      },

      marketingRecordPage: {
        total: 0,
        page: 1,
        size: 10
      },
      sourcePlatformMarketingRecordPage: {
        total: 0,
        page: 1,
        size: 10
      },
      showInfoMarketingRecord: false,
      detailSourcePlatformMarketingData: [],
      detailConversationMarketingData: [],
      marketingRecordDetailPieKey: "cost",

      selectedSourcePlatformMarketingRecordIDs: [],
      rangeMarketingTimes: [],
      rangeSourcePlatformMarketingTimes: [],
      sourcePlatformKey: 0,

      SourcePlatformMarketingConversationListColumns,
      addOrUpdateSourcePlatformMarketingRecord: "",
      sourcePlatformMarketingRecordDefaultForm: {},
      sourcePlatformMarketingRecordForm: {},
      sourcePlatformMarketingRecordConversationArr: [],
      sourcePlatformMarketingRecordFormRules: {
        sourcePlatformID: [
          { required: true, message: "请选择营销平台", trigger: "change" }
        ],
        dateNo: [{ required: true, message: "请选择时间", trigger: "change" }],
        cost: [{ required: true, message: "请输入消费金额", trigger: "blur" }],
        appearNum: [
          { required: true, message: "请输入展现次数", trigger: "blur" }
        ],
        clickNum: [
          { required: true, message: "请输入点击次数", trigger: "blur" }
        ],
        arriveNum: [
          { required: true, message: "请输入抵达次数", trigger: "blur" }
        ],
        conversationTotal: [
          { required: true, message: "请输入沟通总数", trigger: "blur" }
        ],
        conversationValid: [
          { required: true, message: "请输入有效沟通总数", trigger: "blur" }
        ]
      },
      analysisMap: {
        costMap: [], //消费
        appearNumMap: [], //展现数
        clickNumMap: [], //点击数
        arriveNumMap: [], //抵达数
        conversationTotalMap: [], //总对话数
        conversationValidMap: [], //有效对话数
        totalConversationRateMap: [], //总对话率
        validConversationRateMap: [], //有效对话率
        arriveRateMap: [], //抵达率
        clickRateMap: [], //点击率
        clickCostMap: [], //点击成本
        totalConversationCostMap: [], //总对话成本
        validConversationCostMap: [] //有效对话成本
      }
    }
  },
  components: {},
  async created() {
    this.permissionController()
    /**
     * 设置按钮权限
     */
    const that = this
    const promiseFuncArr = [
      GetAllSourcePlatform(),
      GetAllConversation(),
      GetDictionary({ typeName: "marketingRecordDetailPieKey" }),
      that.changeMarketingRecordPage(),
      that.changeSourcePlatformMarketingRecordPage()
    ]

    if (that.permissionFunctions.includes("分析营销数据走势")) {
      promiseFuncArr.push(that.analysisMarketingRecord())
    }

    const [
      allSourcePlatformRes,
      allConversationRes,
      getMarketingRecordDetailPieKeyRes
    ] = await Promise.all(promiseFuncArr)

    if (allSourcePlatformRes.code === 0) {
      that.allSourcePlatformMap = allSourcePlatformRes.result
    }
    if (allConversationRes.code === 0) {
      that.allConversationMap = allConversationRes.result
    }
    if (getMarketingRecordDetailPieKeyRes.code === 0) {
      that.allMarketingRecordDetailPieKeyMap =
        getMarketingRecordDetailPieKeyRes.result
    }
  },
  methods: {
    permissionController() {
      this.permissionFunctions = store.getters.userFunctions.map(
        item => item.functionName
      )
      this.permissionFunctions.includes("分析营销数据走势")
        ? (this.showCollapse = [1])
        : (this.showCollapse = [])
    },
    tableSourcePlatformName(record) {
      const sourcePlatform = this.allSourcePlatformMap.find(
        item => item.sourcePlatformID === record.sourcePlatformID
      )
      if (sourcePlatform) {
        return sourcePlatform.platformName
      } else {
        return "-"
      }
    },
    filterTime: timeStr => fullTimeFilter(timeStr),
    deadlineTimeChange(dates, dateStrings) {
      if (dateStrings[0] && dateStrings[1]) {
        let time1 = dateStrings[0]
        let time2 = dateStrings[1]
        if (
          new Date(time2.replace(/-/g, "/")).getTime() / 1000 -
            (new Date(time1.replace(/-/g, "/")).getTime() / 1000 +
              24 * 60 * 60) >
          365 * 24 * 60 * 60
        ) {
          this.$message.info("请选择一年内的时间！")
          this.crud.query.deadlineTimes = [time1, time1]
          return
        }
      }
      this.crud.query.deadlineTimes = dateStrings
    },
    toInfo(record) {
      this.crud.toInfo(record)
    },
    toInfoSourcePlatformMarketingRecord(record) {
      const that = this
      this.addOrUpdateSourcePlatformMarketingRecord = "info"
      this.sourcePlatformMarketingRecordConversationArr = JSON.parse(
        JSON.stringify(record.conversationRecords || [])
      ).map(item => ({
        name: that.allConversationMap.find(
          cItem => cItem.conversationID === item.conversationID
        ).name,
        conversationID: item.conversationID,
        conversationNum: item.conversationNum,
        mcID: item.mcID
      }))

      JSON.parse(JSON.stringify(that.allConversationMap)).forEach(item => {
        if (
          !that.sourcePlatformMarketingRecordConversationArr
            .map(item2 => item2.conversationID)
            .includes(item.conversationID)
        ) {
          that.sourcePlatformMarketingRecordConversationArr.push({
            name: item.name,
            conversationID: item.conversationID,
            conversationNum: item.conversationNum || 0,
            mcID: item.mcID || 0
          })
        }
      })

      this.sourcePlatformMarketingRecordForm = JSON.parse(
        JSON.stringify(record)
      )
      this.$forceUpdate()
    },
    filterCategory: function(value, option) {
      return option.componentOptions.children[0].text.indexOf(value) >= 0
    },
    async refreshSourcePlatform() {
      const [allSourcePlatformRes] = await Promise.all([GetAllSourcePlatform()])

      if (allSourcePlatformRes.code === 0) {
        this.allSourcePlatformMap = allSourcePlatformRes.result
      }
      this.$forceUpdate()
    },
    async refreshConversation() {
      const [allConversationRes] = await Promise.all([GetAllConversation()])

      if (allConversationRes.code === 0) {
        this.allConversationMap = allConversationRes.result
      }
      this.$forceUpdate()
    },
    cancelSourcePlatform() {
      this.addOrUpdatePlatformFlag = ""
      this.sourcePlatformForm = {
        sourcePlatformID: 0,
        sourcePlatformName: "",
        oldSourcePlatformName: ""
      }
    },
    cancelSourcePlatformMarketingRecord() {
      this.addOrUpdateSourcePlatformMarketingRecord = ""
      this.sourcePlatformMarketingRecordForm = {}
    },
    cancelConversation() {
      this.addOrUpdateConversationFlag = ""
      this.conversationForm = {
        conversationID: 0,
        name: "",
        oldName: ""
      }
    },
    toAddSourcePlatform() {
      this.addOrUpdatePlatformFlag = "add"
      this.sourcePlatformForm = {
        sourcePlatformID: 0,
        sourcePlatformName: "",
        oldSourcePlatformName: ""
      }
      this.$forceUpdate()
    },
    toAddConversation() {
      this.addOrUpdateConversationFlag = "add"
      this.conversationForm = {
        conversationID: 0,
        name: "",
        oldName: ""
      }
      this.$forceUpdate()
    },
    toEditSourcePlatform(record) {
      this.addOrUpdatePlatformFlag = "update"
      this.sourcePlatformForm = {
        sourcePlatformID: record.sourcePlatformID,
        oldSourcePlatformName: record.platformName,
        sourcePlatformName: ""
      }
    },
    toEditConversation(record) {
      this.addOrUpdateConversationFlag = "update"
      this.conversationForm = {
        conversationID: record.conversationID,
        oldName: record.name,
        name: ""
      }
    },
    async deleteSourcePlatform(record) {
      await sourcePlatformApi.del([record.sourcePlatformID])
      await this.refreshSourcePlatform()
    },
    async deleteConversation(record) {
      await conversationApi.del([record.conversationID])
      await this.refreshConversation()
    },
    async addSourcePlatform() {
      const that = this
      const addRes = await sourcePlatformApi.add({
        sourcePlatformName: that.sourcePlatformForm.sourcePlatformName
      })
      if (addRes.code === 12) {
        that.$message.success("新增平台数据成功")
        that.cancelSourcePlatform()
        that.refreshSourcePlatform()
      }
    },
    async addConversation() {
      const that = this
      const addRes = await conversationApi.add({
        conversationName: that.conversationForm.name
      })
      if (addRes.code === 12) {
        that.$message.success("新增客户沟通方式数据成功")
        that.cancelConversation()
        that.refreshConversation()
      }
    },
    async addSourcePlatformMarketingRecord() {
      const that = this
      this.$refs["sourcePlatformMarketingRecordForm"].validate(async valid => {
        if (!valid) {
          return
        }
        const addRes = await Api.addSourcePlatformMarketing(
          that.sourcePlatformMarketingRecordForm,
          that.sourcePlatformMarketingRecordConversationArr
        )
        if (addRes.code === 12) {
          that.$message.success("新增平台营销数据成功")
          that.cancelSourcePlatformMarketingRecord()
          that.changeSourcePlatformMarketingRecordPage()
        }
      })
    },
    async editSourcePlatform() {
      const that = this
      const editRes = await sourcePlatformApi.edit({
        sourcePlatformID: that.sourcePlatformForm.sourcePlatformID,
        sourcePlatformName: that.sourcePlatformForm.sourcePlatformName
      })
      if (editRes.code === 14) {
        that.$message.success("修改平台数据成功")
        that.cancelSourcePlatform()
        that.refreshSourcePlatform()
      }
    },
    async editConversation() {
      const that = this
      const editRes = await conversationApi.edit({
        conversationID: that.conversationForm.conversationID,
        conversationName: that.conversationForm.name
      })
      if (editRes.code === 14) {
        that.$message.success("修改客户沟通方式数据成功")
        that.cancelConversation()
        that.refreshConversation()
      }
    },
    async editSourcePlatformMarketingRecord() {
      const that = this
      const editRes = await Api.editSourcePlatformMarketing(
        that.sourcePlatformMarketingRecordForm,
        that.sourcePlatformMarketingRecordConversationArr
      )
      if (editRes.code === 14) {
        that.$message.success("修改客户沟通方式数据成功")
        that.cancelConversation()
        that.refreshConversation()
      }
    },
    async changeMarketingRecordPage() {
      const that = this
      const marketingRecordListRes = await Api.get({
        pageNum: that.marketingRecordPage.page,
        pageSize: that.marketingRecordPage.size,
        dateNoTimes: that.rangeMarketingTimes
      })
      if (marketingRecordListRes.code === 0) {
        that.marketingRecordList = marketingRecordListRes.result.list
        that.marketingRecordPage.total = marketingRecordListRes.result.count
        that.$forceUpdate()
      }
    },
    downloadMarketingRecords() {
      const that = this
      Api.download({
        dateNoTimes: that.rangeMarketingTimes
      })
    },
    downloadMarketingSourcePlatformRecords() {
      const that = this
      Api.downloadSourcePlatform({
        dateNoTimes: that.rangeMarketingTimes
      })
    },
    async changeSourcePlatformMarketingRecordPage() {
      const that = this
      const sourcePlatformMarketingRecordListRes = await Api.getSourcePlatformMarketing(
        {
          pageNum: that.sourcePlatformMarketingRecordPage.page,
          pageSize: that.sourcePlatformMarketingRecordPage.size,
          dateNoTimes: that.rangeSourcePlatformMarketingTimes,
          sourcePlatformID: that.sourcePlatformKey
        }
      )
      if (sourcePlatformMarketingRecordListRes.code === 0) {
        that.sourcePlatformMarketingRecordList =
          sourcePlatformMarketingRecordListRes.result.list
        that.sourcePlatformMarketingRecordPage.total =
          sourcePlatformMarketingRecordListRes.result.count
        that.selectedSourcePlatformMarketingRecordIDs = []
        that.$forceUpdate()
      }
    },
    marketingRecordPagePageChangeHandler(e) {
      this.marketingRecordPage.page = e
      this.changeMarketingRecordPage()
    },
    sourcePlatformMarketingRecordPagePageChangeHandler(e) {
      this.sourcePlatformMarketingRecordPage.page = e
      this.changeSourcePlatformMarketingRecordPage()
    },
    marketingRecordPageSizeChangeHandler(current, size) {
      this.marketingRecordPage.size = size
      this.marketingRecordPage.page = 1
      this.changeMarketingRecordPage()
    },
    sourcePlatformMarketingRecordPageSizeChangeHandler(current, size) {
      this.sourcePlatformMarketingRecordPage.size = size
      this.sourcePlatformMarketingRecordPage.page = 1
      this.changeSourcePlatformMarketingRecordPage()
    },
    async toInfoMarketingRecord(record) {
      const detailRes = await Api.detail({ dateNo: record.dateNo })
      if (detailRes.code === 0) {
        this.detailSourcePlatformMarketingData =
          detailRes.result.sourcePlatformList
        this.detailConversationMarketingData = detailRes.result.conversationList
      }
      this.drawChart1()
      this.drawChart2()
      this.$forceUpdate()
    },
    selectPieKey() {
      this.drawChart1()
      this.$forceUpdate()
    },
    changeSourcePlatformMarketingRecordIDrHandler(electedRowKeys) {
      this.selectedSourcePlatformMarketingRecordIDs = electedRowKeys
    },
    toDeleteSourcePlatformMarketingRecord() {
      const that = this
      if (that.selectedSourcePlatformMarketingRecordIDs.length === 0) {
        this.$message.error("请选择需要删除的平台营销记录")
        return
      }
      this.$confirm({
        title: "提示",
        content: `确认删除选中的${that.selectedSourcePlatformMarketingRecordIDs.length}条数据?`,
        onOk: async function() {
          const delRes = await Api.delSourcePlatformMarketing({
            SourcePlatformMarketingRecordIDs:
              that.selectedSourcePlatformMarketingRecordIDs
          })
          if (delRes.code === 16) {
            that.$message.success("删除平台营销数据成功")
            that.selectedSourcePlatformMarketingRecordIDs = []
            that.changeSourcePlatformMarketingRecordPage()
            that.changeMarketingRecordPage()
          }
        },
        onCancel() {}
      })
    },
    async analysisMarketingRecord() {
      const that = this
      let { result: analysisRes } = await Api.AnalysisMarketingRecordTrend({
        dateType: ANALYSIS_TIME_UNIT.DAY
      })

      that.analysisMap = {
        costMap: [], //消费
        appearNumMap: [], //展现数
        clickNumMap: [], //点击数
        arriveNumMap: [], //抵达数
        conversationTotalMap: [], //总对话数
        conversationValidMap: [], //有效对话数
        totalConversationRateMap: [], //总对话率
        validConversationRateMap: [], //有效对话率
        arriveRateMap: [], //抵达率
        clickRateMap: [], //点击率
        clickCostMap: [], //点击成本
        totalConversationCostMap: [], //总对话成本
        validConversationCostMap: [] //有效对话成本
      }

      analysisRes.reverse().forEach(item => {
        const dateIndex = item.dateNo.substring(5, 10)
        that.analysisMap.costMap.push({ [dateIndex]: item.cost })
        that.analysisMap.appearNumMap.push({ [dateIndex]: item.appearNum })
        that.analysisMap.clickNumMap.push({ [dateIndex]: item.clickNum })
        that.analysisMap.arriveNumMap.push({ [dateIndex]: item.arriveNum })
        that.analysisMap.conversationTotalMap.push({
          [dateIndex]: item.conversationTotal
        })
        that.analysisMap.conversationValidMap.push({
          [dateIndex]: item.conversationValid
        })
        const totalConversationRate = item.totalConversationRate.slice(
          0,
          item.totalConversationRate.length - 1
        )
        that.analysisMap.totalConversationRateMap.push({
          [dateIndex]: totalConversationRate
        })
        const validConversationRate = item.validConversationRate.slice(
          0,
          item.validConversationRate.length - 1
        )
        that.analysisMap.validConversationRateMap.push({
          [dateIndex]: validConversationRate
        })
        const arriveRate = item.arriveRate.slice(0, item.arriveRate.length - 1)
        that.analysisMap.arriveRateMap.push({ [dateIndex]: arriveRate })
        const clickRate = item.clickRate.slice(0, item.clickRate.length - 1)
        that.analysisMap.clickRateMap.push({ [dateIndex]: clickRate })
        const clickCost = item.clickCost.slice(0, item.clickCost.length - 1)
        that.analysisMap.clickCostMap.push({ [dateIndex]: clickCost })
        const totalConversationCost = item.totalConversationCost.slice(
          0,
          item.totalConversationCost.length - 1
        )
        that.analysisMap.totalConversationCostMap.push({
          [dateIndex]: totalConversationCost
        })
        const validConversationCost = item.validConversationCost.slice(
          0,
          item.validConversationCost.length - 1
        )
        that.analysisMap.validConversationCostMap.push({
          [dateIndex]: validConversationCost
        })
      })
      that.drawChart3_1()
      that.drawChart3_2()
    },
    drawChart1: drawChartSourcePlatformRate,
    drawChart2: drawChartConversationRate,
    drawChart3_1: drawChartMarketingTrendPart1,
    drawChart3_2: drawChartMarketingTrendPart2
  }
}
</script>
<style lang="scss" scoped>
.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}

.table-operations {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 50px;
}

.table-operations > button {
  margin-right: 8px;
}

.table-container {
  float: left;
  width: 99.9%;
  border: none;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
}

.own-part1-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 14px 24px;
}

.bottom-card {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 1180px;

  & > * {
    width: calc(50% - 9px);

    &:first-child {
      margin-right: 18px;
    }
  }
}

.headCard1 {
  display: inline-block;
  width: 49%;
  height: 480px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
}

.headCard2 {
  display: inline-block;
  width: 49%;
  height: 480px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 2%;
  border: none;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
}

.my-collapse {
  margin-bottom: 20px;

  .el-collapse-item {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;

    /deep/ .el-collapse-item__content {
      padding-bottom: 0;
    }

    /deep/ .el-collapse-item__header {
      padding-left: 20px;
    }

    .item-content {
      background-color: #f0f2f5;
      padding: 10px;
    }
  }

  .collapse-card {
    width: 100%;
    height: 850px;
    margin-top: 10px;
    margin-bottom: 20px;
    float: left;
    border: none;
    border-radius: 10px;
    box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
  }
}

.card11 {
  border: none;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
  margin-bottom: 10px;
  width: 47%;
  height: 450px;
}

.card22 {
  border: none;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);
  margin-bottom: 10px;
  margin-left: 1%;
  width: 47%;
  height: 450px;
}
</style>
