import { render, staticRenderFns } from "./RoleFunctions.vue?vue&type=template&id=1c1ccb99&scoped=true&"
import script from "./RoleFunctions.vue?vue&type=script&lang=js&"
export * from "./RoleFunctions.vue?vue&type=script&lang=js&"
import style0 from "./RoleFunctions.vue?vue&type=style&index=0&id=1c1ccb99&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c1ccb99",
  null
  
)

export default component.exports