<template>
  <a-modal
    v-model="showEditModal"
    title="客户跟进管理详情"
    width="60%"
    @cancel="closeModal"
  >
    <a-form-model
      ref="ruleForm"
      :label-col="{ span: 6 }"
      :model="currentInfo"
      :rules="rules"
      :wrapper-col="{ span: 18 }"
    >
      <a-row :gutter="40">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="公司名称">
            <a-input v-model="currentInfo.customerName" :disabled="true" />
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="联系人">
            <a-select
              v-model="currentInfo.contactID"
              :disabled="operateType === BASE_OPERATE_TYPE.INFO"
            >
              <a-select-option
                v-for="item in customerContactList"
                :key="item.contactID"
                :value="item.contactID"
              >
                {{ item.contactName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="跟进人">
            <a-select v-model="currentInfo.creatorId" :disabled="true">
              <a-select-option
                v-for="item in allUserListMap"
                :key="item.userID"
                :value="item.userID"
              >
                {{ item.displayName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="40">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="跟进日期">
            <a-date-picker
              v-model="currentInfo.insertTime"
              :disabled="true"
              placeholder="选择时间"
              show-time
              style="width: 100%;"
              type="date"
            />
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="回访日期">
            <a-date-picker
              v-model="currentInfo.returnVisitTime"
              :disabled="operateType === BASE_OPERATE_TYPE.INFO"
              placeholder="选择时间"
              show-time
              style="width: 100%;"
              type="date"
            />
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="跟进前状态">
            <a-input v-model="currentInfo.oldTagName" :disabled="true" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="40">
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label="跟进后状态">
            <a-select
              v-model="currentInfo.newTagName"
              :disabled="operateType === BASE_OPERATE_TYPE.INFO"
            >
              <a-select-option
                v-for="(item, idx) in allCustomerTagMap"
                :key="idx"
              >
                {{ item.value }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8"></a-col>
        <a-col :lg="8" :md="24" :sm="24" :xl="8" :xs="24" :xxl="8">
          <a-form-model-item label=""></a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="40">
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <a-form-model-item
            :labelCol="{ span: 2 }"
            :wrapperCol="{ span: 20 }"
            label="跟进内容"
          >
            <a-textarea
              v-model="currentInfo.note"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              :disabled="operateType === BASE_OPERATE_TYPE.INFO"
              placeholder="Controlled autosize"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="40">
        <a-col :lg="24" :md="24" :sm="24" :xl="24" :xs="24" :xxl="24">
          <a-form-model-item
            :labelCol="{ span: 2 }"
            :wrapperCol="{ span: 20 }"
            label="上传文件附件"
          >
            <UploadFileAfter
              ref="UploadFileAfter"
              :allFileCancelPath="followUpAllFileCancel"
              :customerID="currentInfo.customerID"
              :disabled="operateType === BASE_OPERATE_TYPE.INFO"
              :fileList="fileList"
              :fileRemovedPath="followUpFileRemoved"
              :fileUploadPath="followUpFileUpload"
              :originFileDeletePath="followUpOriginFileDeletePath"
              :originFileList="originFileList"
              @update-file-list="updateFileList"
            ></UploadFileAfter>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <template slot="footer">
      <div v-if="operateType === BASE_OPERATE_TYPE.INFO">
        <a-button key="submit" type="primary" @click="toEdit">
          编辑
        </a-button>
      </div>
      <div v-if="operateType === BASE_OPERATE_TYPE.EDIT">
        <a-button key="back" @click="cancelEdit">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="toSaveEdit">
          提交
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { GetCustomerContact } from "@/api/customer/customer"
import { BASE_OPERATE_TYPE } from "@/config"
import { GetAllUserList, GetDictionary } from "@/api/public-api"
import UploadFileAfter from "@/components/UploadFileAfter"
import { FileOperateAPI } from "@/api/upload-file-after"
import { v4 as uuidv4 } from "uuid"
import { edit, GetFollowUpDetailById } from "@/api/customer/follow-up"
import { getDifferencesList } from "@/utils/array_util"

export default {
  name: "UploadFile",
  props: {
    customerID: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      BASE_OPERATE_TYPE,
      operateType: "",
      fileList: [],
      originFileList: [],
      currentInfo: {},
      rules: {},
      customerContactList: [],
      allCustomerTagMap: [],
      allUserListMap: [],
      showEditModal: false,
      followUpFileUpload: FileOperateAPI.FollowUpFileUpload,
      followUpFileRemoved: FileOperateAPI.FollowUpFileRemoved,
      followUpAllFileCancel: FileOperateAPI.FollowUpAllFileCancel,
      followUpOriginFileDeletePath: FileOperateAPI.FollowUpOriginFileDelete
    }
  },
  components: { UploadFileAfter },
  async created() {
    const [getCustomerTagRes, getAllUserListRes] = await Promise.all([
      GetDictionary({ typeName: "customerTag" }),
      GetAllUserList()
    ])

    if (getCustomerTagRes.code === 0) {
      this.allCustomerTagMap = [...getCustomerTagRes.result]
    }
    if (getAllUserListRes.code === 0) {
      this.allUserListMap = [...getAllUserListRes.result]
    }
  },
  methods: {
    toEdit() {
      this.operateType = BASE_OPERATE_TYPE.EDIT
    },
    parseFileList(filePath) {
      if (typeof filePath === "object") {
        return filePath.map(item => {
          const data = { ...item }
          if (!item.uid) {
            data.uid = uuidv4()
          }
          return {
            ...data,
            oldFile: true,
            url: item.filePath,
            status: "done",
            name: item.fileName
          }
        })
      }
      return [
        ...(filePath
          ? JSON.parse(filePath).map(item => {
              const data = { ...item }
              if (!item.uid) {
                data.uid = uuidv4()
              }
              return {
                ...data,
                oldFile: true,
                url: item.filePath,
                status: "done",
                name: item.fileName
              }
            })
          : [])
      ]
    },
    async showData(followUpID, operateType) {
      this.showEditModal = true
      this.operateType = operateType
      const { result: record } = await GetFollowUpDetailById(followUpID)
      this.currentInfo = { ...record }
      console.log(record.uploadFilePath)
      this.fileList = this.parseFileList(record.uploadFilePath)
      this.originFileList = this.parseFileList(record.uploadFilePath)
      const getCustomerContactRefs = await GetCustomerContact({
        customerID: record.customerID
      })
      this.customerContactList = [...getCustomerContactRefs.result]
    },
    closeModal() {
      this.showEditModal = false
      this.operateType = ""
      this.currentInfo = {}
      this.fileList = []
      this.customerContactList = []
    },
    cancelEdit() {
      this.$refs.UploadFileAfter.AllFileCancel()
      this.fileList = [...this.originFileList]
      this.operateType = BASE_OPERATE_TYPE.INFO
    },
    updateFileList(fileList) {
      this.fileList = [...fileList]
    },
    async toSaveEdit() {
      const that = this
      const updateData = {
        ...that.currentInfo,
        files: JSON.stringify(
          that.fileList.map(item => ({
            uid: item.uid,
            fileName: item.name || item.fileName,
            filePath: item.url || item.filePath,
            attachmentID: item.attachmentID || 0
          }))
        ),
        changeTag: that.currentInfo.newTag == that.currentInfo.oldTag ? 1 : 2,
        hasUploadFile: (that.fileList || []).length > 0 ? 2 : 1,
        changeFile:
          (getDifferencesList(that.fileList, that.originFileList, "uid") || [])
            .length > 0
      }

      const [delResCode, updateFollowUpRes] = await Promise.all([
        that.$refs.UploadFileAfter.deleteOriginFile(), //删除原来的附件
        edit(updateData)
      ])

      if (delResCode === 0 && updateFollowUpRes.code === 14) {
        that.$message.success("更新成功")
        that.$emit("update-follow-up")
      }
      that.closeModal()
    }
  }
}
</script>

<style scoped></style>
