export const customerListColumns = [
  {
    title: "公司名称",
    width: 280,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "customerName",
    key: "customerName",
    scopedSlots: { customRender: "customerName" }
  },
  {
    title: "客户编号",
    width: 210,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "customerNo",
    key: "customerNo"
  },
  {
    title: "指派状态",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "assignType" },
    key: "assignType"
  },
  {
    title: "指派员工",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "displayNameList",
    // scopedSlots: { customRender: "users" },
    key: "displayNameList"
  },
  {
    title: "项目数",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "projectNum",
    // scopedSlots: { customRender: "projects" },
    key: "projectNum"
  },
  {
    title: "客户状态",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "tag" },
    key: "tag"
  },
  {
    title: "公司行业",
    width: 180,
    align: "business",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "business" },
    key: "business"
  },
  {
    title: "公司性质",
    width: 180,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "nature" },
    key: "nature"
  },
  {
    title: "访问方式",
    width: 100,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "accessType" },
    key: "accessType"
  },
  {
    title: "联系人姓名",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    // scopedSlots: { customRender: "userName" },
    dataIndex: "contactNameList",
    key: "contactNameList"
  },
  {
    title: "联系人电话",
    width: 250,
    align: "center",
    ellipsis: true,
    resizable: true,
    // scopedSlots: { customRender: "userTel" },
    dataIndex: "contactTelList",
    key: "contactTelList"
  },
  {
    title: "搜索关键字",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "keywordValue",
    key: "keywordValue"
  },
  {
    title: "来源平台",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "platformNameValue",
    key: "platformNameValue"
  },
  {
    title: "区",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "area",
    key: "area"
  },
  {
    title: "市",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "city",
    key: "city"
  },
  {
    title: "省",
    width: 120,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "province",
    key: "province"
  },
  {
    title: "详细地址",
    width: 320,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "address",
    key: "address"
  },
  {
    title: "创建者",
    width: 150,
    align: "center",
    ellipsis: true,
    resizable: true,
    dataIndex: "creatorDisplayName",
    key: "creatorDisplayName"
  },

  {
    title: "创建日期",
    width: 180,
    ellipsis: true,
    align: "center",
    resizable: true,
    scopedSlots: { customRender: "insertTime" },
    key: "insertTime"
  },
  {
    title: "近期修改日期",
    width: 180,
    ellipsis: true,
    resizable: true,
    align: "center",
    scopedSlots: { customRender: "updateTime" },
    key: "updateTime"
  },
  {
    title: "操作",
    fixed: "right",
    align: "center",
    scopedSlots: { customRender: "operation" }
  }
]

export const contactColumns = [
  {
    title: "联系人",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "contactName",
    scopedSlots: { customRender: "contactName" },
    key: "contactName"
  },
  {
    title: "联系方式",
    width: 300,
    align: "center",
    ellipsis: true,
    resizable: true,
    // dataIndex: "contactTel",
    scopedSlots: { customRender: "contactTel" },
    key: "contactTel"
  },
  {
    title: "联系人类型",
    width: 200,
    align: "center",
    ellipsis: true,
    resizable: true,
    scopedSlots: { customRender: "contactType" },
    key: "contactType"
  },
  {
    title: "操作",
    width: 400,
    align: "center",
    scopedSlots: { customRender: "operation" }
  }
]
