export const receiptColumns = [
  {
    title: "日期",
    // dataIndex: "date",
    key: "date",
    scopedSlots: { customRender: "date" }
  },
  {
    title: "金额",
    key: "amount",
    scopedSlots: { customRender: "amount" }
  },
  {
    title: "付款时间",
    key: "time",
    scopedSlots: { customRender: "time" }
  },
  {
    title: "收款方式",
    key: "receiptTypeName",
    scopedSlots: { customRender: "receiptTypeName" }
  },
  {
    title: "合同编号",
    key: "contractNo",
    scopedSlots: { customRender: "contractNo" }
  },
  {
    title: "操作",
    key: "operation",
    scopedSlots: { customRender: "operation" }
  },
]