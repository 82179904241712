import { PieChartColor } from "@/config"

export function drawChartSourcePlatformRate() {
  const that = this
  const option = {
    color: PieChartColor,
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      right: 10,
      top: 20,
      bottom: 20,
      data: [],
      selected: []
    },
    series: [
      {
        name: "平台占比",
        type: "pie",
        radius: ["40%", "70%"],
        center: ["40%", "50%"],
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  }

  let data = []
  let legendData = []

  legendData = that.allSourcePlatformMap
    .filter(item =>
      that.detailSourcePlatformMarketingData
        .map(sItem => sItem.sourcePlatformID)
        .includes(item.sourcePlatformID)
    )
    .map(item => item.platformName)

  that.detailSourcePlatformMarketingData.forEach(item => {
    data.push({
      name: that.allSourcePlatformMap.find(
        spItem => spItem.sourcePlatformID == item.sourcePlatformID
      ).platformName,
      value: item[that.marketingRecordDetailPieKey]
    })
  })

  option.legend.data = legendData
  option.series[0].data = data
  // 初始化echarts实例
  const myChart = this.$echarts.init(this.$refs["chart1"])
  // 绘制图表
  myChart.setOption(option)
}

export function drawChartConversationRate() {
  const that = this
  const option = {
    color: PieChartColor,
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    legend: {
      type: "scroll",
      orient: "vertical",
      right: 10,
      top: 20,
      bottom: 20,
      data: [],
      selected: []
    },
    series: [
      {
        name: "沟通数据占比",
        type: "pie",
        radius: ["40%", "70%"],
        center: ["40%", "50%"],
        data: [],
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)"
          }
        }
      }
    ]
  }

  let data = []
  let legendData = []

  legendData = that.allConversationMap
    .filter(item =>
      that.detailConversationMarketingData
        .map(sItem => sItem.conversationID)
        .includes(item.conversationID)
    )
    .map(item => item.name)

  that.detailConversationMarketingData.forEach(item => {
    data.push({
      name: that.allConversationMap.find(
        spItem => spItem.conversationID == item.conversationID
      ).name,
      value: item.conversationNum
    })
  })

  option.legend.data = legendData
  option.series[0].data = data
  // 初始化echarts实例
  let myChart = this.$echarts.init(this.$refs["chart2"])
  // 绘制图表
  myChart.setOption(option)
}

export function drawChartMarketingTrendPart1() {
  const that = this
  const option = {
    tooltip: {
      trigger: "axis"
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    legend: {
      data: ["消费数", "展现数", "点击数", "抵达数", "总对话数", "有效对话数"]
    },
    color: ["#ADB7FF", "#ec413a", "#f19117", "#55de26", "#8d26ef", "#12d98d"],
    backgroundColor: "#fff",
    grid: {
      top: "20%",
      right: "10%",
      left: "10%",
      bottom: "12%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        color: "#AFAFAF",
        data: [],
        axisLabel: {
          // formatter: "{value}月",
          margin: 20,
          color: "#DADADA",
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          lineStyle: {
            color: "rgba(173,183,255,1)"
          }
        },
        axisTick: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLabel: {
          color: "#7e7b7b",
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          lineStyle: {
            color: "rgba(107,107,107,0.37)"
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          lineStyle: {
            color: "rgba(131,101,101,0.2)",
            type: "dashed"
          }
        }
      }
    ],
    series: [
      {
        data: [],
        name: "消费数",
        smooth: true,
        type: "line",
        // symbol: "none",
        lineStyle: {
          color: "#ADB7FF",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "展现数",
        symbol: "none",
        lineStyle: {
          color: "#ec413a",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "点击数",
        symbol: "none",
        lineStyle: {
          color: "#f19117",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "抵达数",
        symbol: "none",
        lineStyle: {
          color: "#55de26",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "总对话数",
        symbol: "none",
        lineStyle: {
          color: "#8d26ef",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "有效对话数",
        symbol: "none",
        lineStyle: {
          color: "#12d98d",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      }
    ]
  }

  let legendData = that.analysisMap.costMap.map(item => Object.keys(item)[0])
  option.xAxis[0].data = legendData
  option.series[0].data = that.analysisMap.costMap.map(
    item => Object.values(item)[0]
  )
  option.series[1].data = that.analysisMap.appearNumMap.map(
    item => Object.values(item)[0]
  )
  option.series[2].data = that.analysisMap.clickNumMap.map(
    item => Object.values(item)[0]
  )
  option.series[3].data = that.analysisMap.arriveNumMap.map(
    item => Object.values(item)[0]
  )
  option.series[4].data = that.analysisMap.conversationTotalMap.map(
    item => Object.values(item)[0]
  )
  option.series[5].data = that.analysisMap.conversationValidMap.map(
    item => Object.values(item)[0]
  )

  const myChart = this.$echarts.init(this.$refs["chart3_1"])
  myChart.setOption(option)
}

export function drawChartMarketingTrendPart2() {
  const that = this
  const option = {
    tooltip: {
      trigger: "axis"
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    backgroundColor: "#fff",
    color: ["#ADB7FF", "#ec413a", "#f19117", "#55de26", "#8d26ef", "#12d98d"],
    legend: {
      data: [
        "总对话率",
        "有效对话率",
        "抵达率",
        "点击率",
        "点击成本",
        "总对话成本",
        "有效对话成本"
      ]
    },
    grid: {
      top: "20%",
      right: "10%",
      left: "10%",
      bottom: "12%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        color: "#AFAFAF",
        data: [],
        axisLabel: {
          // formatter: "{value}月",
          margin: 20,
          color: "#DADADA",
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          lineStyle: {
            color: "rgba(173,183,255,1)"
          }
        },
        axisTick: {
          show: false
        }
      }
    ],
    yAxis: [
      {
        axisLabel: {
          // formatter: "{value}",
          color: "#AFAFAF",
          textStyle: {
            fontSize: 12
          }
        },
        axisLine: {
          lineStyle: {
            color: "rgba(107,107,107,0.37)"
          }
        },
        axisTick: {
          show: false
        },
        splitLine: {
          lineStyle: {
            color: "rgba(131,101,101,0.2)",
            type: "dashed"
          }
        }
      }
    ],
    series: [
      {
        data: [],
        type: "line",
        smooth: true,
        name: "总对话率",
        symbol: "none",
        lineStyle: {
          color: "#ADB7FF",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "有效对话率",
        symbol: "none",
        lineStyle: {
          color: "#ec413a",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "抵达率",
        symbol: "none",
        lineStyle: {
          color: "#f19117",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "点击率",
        symbol: "none",
        lineStyle: {
          color: "#55de26",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "点击成本",
        symbol: "none",
        lineStyle: {
          color: "#8d26ef",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "总对话成本",
        symbol: "none",
        lineStyle: {
          color: "#12d98d",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      },
      {
        data: [],
        type: "line",
        smooth: true,
        name: "有效对话成本",
        symbol: "none",
        lineStyle: {
          color: "#de0b75",
          width: 4,
          shadowColor: "rgba(0, 0, 0, 0.3)", //设置折线阴影
          shadowBlur: 15,
          shadowOffsetY: 20
        }
      }
    ]
  }

  let legendData = that.analysisMap.costMap.map(item => Object.keys(item)[0])
  option.xAxis[0].data = legendData
  option.series[0].data = that.analysisMap.totalConversationRateMap.map(
    item => Object.values(item)[0]
  )
  option.series[1].data = that.analysisMap.validConversationRateMap.map(
    item => Object.values(item)[0]
  )
  option.series[2].data = that.analysisMap.arriveRateMap.map(
    item => Object.values(item)[0]
  )
  option.series[3].data = that.analysisMap.clickRateMap.map(
    item => Object.values(item)[0]
  )
  option.series[4].data = that.analysisMap.clickCostMap.map(
    item => Object.values(item)[0]
  )
  option.series[5].data = that.analysisMap.totalConversationCostMap.map(
    item => Object.values(item)[0]
  )
  option.series[6].data = that.analysisMap.validConversationCostMap.map(
    item => Object.values(item)[0]
  )

  const myChart = this.$echarts.init(this.$refs["chart3_2"])
  myChart.setOption(option)
}
