<template>
  <div class="card-line">
    <a-card
      :body-style="{ padding: '0px' }"
      :bordered="false"
      style="width: 49%;height: 480px;margin-bottom:10px;margin-right: 1%; float:left; border: none;   border-radius: 10px;   box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);"
    >
      <div class="table-operations">
        <span style="font-size: 18px;margin-right: 20px;margin-left: 10px">
          公海审批抄送
        </span>
        <a-button
          icon="redo"
          type="link"
          @click="getUserIntoOpenSeaCopyRecordList"
        />
      </div>
      <a-table
        :columns="IntoOpenSeaCopyInColumns"
        :dataSource="allIntoOpenSeaCopyInMap"
        :pagination="false"
        :scroll="{
          y: 340,
          x: 600,
          scrollToFirstRowOnChange: true
        }"
        rowKey="sourcePlatformID"
        style="width:99%;height:99%;margin:0 auto"
      >
        <template slot="customerName" slot-scope="record">
          <a v-if="record.customerID" @click="toShowCustomer(record)">{{
            record.customerName
          }}</a>
          <span v-else>-</span>
        </template>
        <template slot="insertTime" slot-scope="record">
          {{ filterTime(record.insertTime) }}
        </template>

        <template slot="status" slot-scope="text, record">
          {{
            approvalStatusMap.find(item => Number(item.key) === record.status)
              ? approvalStatusMap.find(
                  item => Number(item.key) === record.status
                ).value
              : "-"
          }}
        </template>

        <template slot="operation" slot-scope="text, record">
          <a-button size="small" type="link" @click="toInfo(record, false)">
            详情
          </a-button>
        </template>
      </a-table>
    </a-card>
    <a-card
      :body-style="{ padding: '0px' }"
      :bordered="false"
      style="width: 49%;height: 480px;margin-bottom:10px;float:left;border: none;   border-radius: 10px;   box-shadow: 4px 4px 8px 0px rgba(201, 201, 201, 0.5);"
    >
      <div class="table-operations">
        <span style="font-size: 18px;margin-right: 20px;margin-left: 10px">
          移入公海审批
        </span>
        <a-button icon="redo" type="link" @click="getUserIntoOpenSeaApproval" />
        <!--        <a-button-->
        <!--          icon="plus-circle"-->
        <!--          style="width: 70px"-->
        <!--          type="primary"-->
        <!--          @click="toAddConversation"-->
        <!--        />-->
      </div>
      <a-table
        :columns="IntoOpenSeaApprovalColumns"
        :dataSource="allIntoOpenSeaApprovalMap"
        :pagination="false"
        :scroll="{
          y: 340,
          x: 600
        }"
        rowKey="conversationID"
        style="width:99%;height:99%;margin:0 auto"
      >
        <template slot="customerName" slot-scope="record">
          <a @click="toShowCustomer(record)">{{ record.customerName }}</a>
        </template>

        <template slot="status" slot-scope="text, record">
          {{
            approvalStatusMap.find(item => Number(item.key) === record.status)
              ? approvalStatusMap.find(
                  item => Number(item.key) === record.status
                ).value
              : "-"
          }}
        </template>

        <template slot="insertTime" slot-scope="record">
          {{ filterTime(record.insertTime) }}
        </template>

        <template slot="operation" slot-scope="text, record">
          <a-button size="small" type="link" @click="toInfo(record, true)">
            详情
          </a-button>
        </template>
      </a-table>
    </a-card>
    <CustomerInfo ref="customerInfo" :addContract="false"></CustomerInfo>
    <IntoOpenSeaModal
      ref="intoOpenSeaModal"
      :addContract="false"
      @pass-approval-finish="passApprovalFinish"
    ></IntoOpenSeaModal>
  </div>
</template>

<script>
import {
  IntoOpenSeaApprovalColumns,
  IntoOpenSeaCopyInColumns
} from "@/components/TodoList/columns-list"
import { fullTimeFilter } from "@/utils/utils"
import {
  getApprovalCcRecordList,
  getApprovalRecordList
} from "@/api/todo-list/index"
import { GetDictionary } from "@/api/public-api"
import CustomerInfo from "@/components/customerInfo/index"
import IntoOpenSeaModal from "@/components/TodoList/intoOpenSeaModal"

export default {
  name: "IntoOpenSea",
  data() {
    return {
      IntoOpenSeaApprovalColumns,
      IntoOpenSeaCopyInColumns,
      allIntoOpenSeaApprovalMap: [],
      allIntoOpenSeaCopyInMap: [],
      approvalProcessTypeMap: [],
      approvalStatusMap: []
    }
  },
  components: { CustomerInfo, IntoOpenSeaModal },
  async created() {
    const that = this
    const [approvalProcessTypeRes, approvalStatusRes] = await Promise.all([
      GetDictionary({ typeName: "approvalProcessType" }),
      GetDictionary({ typeName: "approvalStatus" }),
      that.getUserIntoOpenSeaCopyRecordList(),
      that.getUserIntoOpenSeaApproval()
    ])
    if (approvalProcessTypeRes.code === 0) {
      this.approvalProcessTypeMap = [...approvalProcessTypeRes.result]
    }
    if (approvalStatusRes.code === 0) {
      this.approvalStatusMap = [...approvalStatusRes.result]
    }
  },
  methods: {
    filterTime: timeStr => fullTimeFilter(timeStr),
    showData() {},
    async toShowCustomer(record) {
      this.$refs.customerInfo.toCustomerInfo(record.customerID)
    },
    async getUserIntoOpenSeaCopyRecordList() {
      const that = this
      const getUserIntoOpenSeaCopyRes = await getApprovalCcRecordList({
        approvalStatus: [1, 2, 3]
      })
      if (getUserIntoOpenSeaCopyRes.code === 0) {
        that.allIntoOpenSeaCopyInMap = [
          ...getUserIntoOpenSeaCopyRes.result.list
        ]
      }
    },
    async getUserIntoOpenSeaApproval() {
      const that = this
      const getUserIntoOpenSeaRes = await getApprovalRecordList({
        approvalStatus: [1]
      })
      if (getUserIntoOpenSeaRes.code === 0) {
        that.allIntoOpenSeaApprovalMap = [...getUserIntoOpenSeaRes.result.list]
      }
    },
    toInfo(record, passApproval) {
      console.log(`record : ${JSON.stringify(record)}`)
      this.$refs.intoOpenSeaModal.showData(
        record.approvalRecordID,
        passApproval
      )
    },
    passApprovalFinish() {
      this.getUserIntoOpenSeaApproval()
    }
  }
}
</script>

<style scoped>
.card-line {
  padding: 20px;
}

.ant-table-striped /deep/ .table-striped td {
  background-color: #fcfcfc;
}

.table-operations {
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  height: 50px;
}

.table-operations > button {
  margin-right: 8px;
}
</style>
