<template>
  <div>
    <div class="draw_right">
      <RoleTree ref="roleTree"></RoleTree>
      <div class="_center">
        <div class="_label">功能权限：</div>
        <div class="_group function-check">
          <UserFunctions
            ref="userFunctions"
            :roleID="roleID"
            :userID="userID"
          ></UserFunctions>
        </div>
      </div>
    </div>
    <div v-if="userID != 1" class="footer">
      <div v-if="operateType === BASE_OPERATE_TYPE.INFO">
        <a-button type="primary" @click="toEdit()">
          编辑
        </a-button>
      </div>
      <div v-if="operateType === BASE_OPERATE_TYPE.EDIT">
        <a-button class="first_btn" @click="cancelEdit()">
          取消
        </a-button>
        <a-button type="primary" @click="submitEdit()">
          提交
        </a-button>
      </div>
      <div v-if="operateType === BASE_OPERATE_TYPE.ADD">
        <a-button type="primary" @click="submitEdit()">
          保存
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BASE_OPERATE_TYPE } from "@/config/index"
import RoleTree from "@/components/System/RoleTree"
import { find } from "@/api/system/role"
import UserFunctions from "@/components/System/UserFunctions"
import { UpdateUserFunctions } from "@/api/system/account"

export default {
  name: "role-pane",
  data() {
    return {
      BASE_OPERATE_TYPE,
      roleList: [],
      operateType: "",
      userID: 0,
      roleID: 0,
      showButton: false,
      treeData: [
        {
          label: "组织架构权限",
          id: 0,
          children: []
        }
      ]
    }
  },
  components: { UserFunctions, RoleTree },
  methods: {
    async showData(userID, roleID, operateType) {
      this.userID = userID
      this.roleID = roleID
      this.operateType = operateType
      const getAllRoleListRes = await find({})
      if (getAllRoleListRes.code === 0) {
        this.roleList = getAllRoleListRes.result
      }
      this.$refs.roleTree.initData(this.roleList)
      this.$refs.userFunctions.showData(userID, roleID, operateType)
    },
    toEdit() {
      this.operateType = BASE_OPERATE_TYPE.EDIT
      this.$refs.userFunctions.updatePermission()
    },
    cancelEdit() {
      this.operateType = BASE_OPERATE_TYPE.INFO
      this.$refs.userFunctions.cancelSavePermission()
    },
    async submitEdit() {
      const that = this
      const selectedFunctionIDs = [
        ...that.$refs.userFunctions.getSelectedFunctionIDs()
      ]
      if (that.userID && that.roleID) {
        const updateRes = await UpdateUserFunctions({
          userID: that.userID,
          functions: selectedFunctionIDs
        })
        if (updateRes.code === 0) {
          this.$message.success("更新用户权限成功")
          this.$emit("submit-role-success-after")
          that.operateType = BASE_OPERATE_TYPE.INFO
          this.$refs.userFunctions.showData(
            that.userID,
            that.roleID,
            BASE_OPERATE_TYPE.INFO
          )
        }
      } else {
        this.$message.error("请先创建用户或选择用户角色")
      }
      // this.operateType = BASE_OPERATE_TYPE.INFO
    },
    submitAdd() {
      this.operateType = BASE_OPERATE_TYPE.INFO
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  .send_but {
    margin-left: 50px;
  }

  .first_btn {
    margin-right: 20px;
  }
}

.draw_right {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  box-sizing: border-box;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;

  ._center_tree {
    width: 100%;
    min-height: 200px;
    max-height: 400px;
    overflow: auto;
  }

  ._center {
    flex: 1;
    display: flex;
    align-items: flex-start;
    padding: 12px 20px;
    margin-top: 20px;

    ._label {
      width: 100px;
      line-height: 30px;
    }

    .function-check {
      /deep/ .ant-tree > li:first-child {
        padding-top: 4px;
      }
    }
  }
}
</style>
